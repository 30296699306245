import { useTranslation } from 'react-i18next';

import { Download as Icon } from '@mui/icons-material';

import FloatIcon from 'components/icons/FloatIcon';

function DownloadPDFIcon({ onClick }: { onClick: () => any }) {
	const { t } = useTranslation();

	return <FloatIcon onClick={() => onClick()} title={t('system:downloadPDF')} icon={Icon} />;
}

export default DownloadPDFIcon;
