import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Uploader from 'components/Uploader';
import useUser from 'hooks/useUser';
import { changeSponsor } from 'lib/models/sponsor';
import { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setSponsor } from 'store/reducers/sponsor';
import useStoreDispatch from 'store/useStoreDispatch';

type Props = {
	plate: string;
	isAssigned: string;
	stay: string;
	from: Moment | null;
	to: Moment | null;
	onSearch: () => void;
	handleParams: (value: any, key: string) => void;
	refetch?: () => void;
};

function AccessFilter({ plate, isAssigned, stay, from, to, onSearch, handleParams, refetch }: Props) {
	const { t } = useTranslation();
	const dispatch = useStoreDispatch();
	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const [showUploader, setShowUploader] = useState(false);

	const onCloseUploader = () => {
		setShowUploader(false);
	};

	async function uploadImage(file: File): Promise<boolean> {
		const response = await changeSponsor(file);

		if (response) {
			dispatch(setSponsor(response));
			return true;
		}

		return false;
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '1rem' }}>
			{showUploader && (
				<Uploader id="sponsor-image" name="sponsor-file" upload={uploadImage} onClose={onCloseUploader} open={true} />
			)}
			<TextField
				value={plate}
				label={t('access:plate_number')}
				onChange={(ev) => handleParams(ev.target.value.toUpperCase(), 'plate')}
				inputProps={{
					style: { textTransform: 'uppercase' },
				}}
			/>
			<Select value={isAssigned} onChange={(ev) => handleParams(ev.target.value as string, 'isAssigned')}>
				<MenuItem value={'any'}>{t('common:any')}</MenuItem>
				<MenuItem value={'assigned'}>{t('access:assigned')}</MenuItem>
				<MenuItem value={'unassigned'}>{t('access:unassigned')}</MenuItem>
			</Select>
			<Select value={stay} onChange={(ev) => handleParams(ev.target.value as string, 'stay')}>
				<MenuItem value={'all'}>{t('access:all')}</MenuItem>
				<MenuItem value={'day'}>{t('access:day')}</MenuItem>
				<MenuItem value={'night'}>{t('access:night')}</MenuItem>
				<MenuItem value={'tent'}>{t('access:tent')}</MenuItem>
			</Select>
			<DatePicker value={from} label={t('common:from')} onChange={(v) => handleParams(v, 'from')} format="DD/MM/YYYY" />
			<DatePicker
				value={to}
				minDate={from || undefined}
				label={t('common:to')}
				onChange={(v) => handleParams(v, 'to')}
				format="DD/MM/YYYY"
			/>

			<Button onClick={onSearch} variant="contained">
				{t('common:search')}
			</Button>
			<Box sx={{ textAlign: 'center', position: 'fixed', top: 100, right: 12, zIndex: 9999 }}>
				{isAdmin && (
					<Button
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem', marginRight: '.2rem' }}
						variant="contained"
						component={Link}
						to="/scan/plate"
					>
						{t('access:assignPlate')}
					</Button>
				)}
				{isAdmin && (
					<Button
						onClick={() => {
							setShowUploader(true);
						}}
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem' }}
						variant="contained"
					>
						{t('access:changeSponsor')}
					</Button>
				)}
			</Box>
		</Box>
	);
}

export default AccessFilter;
