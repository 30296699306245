import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	handleClose: () => void;
	onSave: (plate: string) => void;
};

function ScanAndAssignPlate({ open, handleClose, onSave }: Props) {
	const { t } = useTranslation();

	const [plate, setPlate] = useState<string>('');

	const handlePlate = (plate: string) => {
		return setPlate(plate);
	};

	return (
		<Dialog open={!!open}>
			<DialogTitle sx={{ paddingY: '.5rem' }} align="center">
				{t('access:assignPlate')}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<TextField
					autoFocus
					autoComplete="off"
					value={plate}
					onChange={(e) => handlePlate(e.target.value.toUpperCase())}
					inputProps={{
						style: { textTransform: 'uppercase', textAlign: 'center' },
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						setPlate('');
					}}
					color="error"
				>
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						onSave(plate);
						handleClose();
						setPlate('');
					}}
					color="primary"
				>
					{t('common:confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ScanAndAssignPlate;
