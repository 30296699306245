import { SxProps } from '@mui/material';
import List, { ListProps } from './List';
import Table, { TableProps } from './Table';
import useIsMobile from 'hooks/useIsMobile';
import { Theme } from '@mui/system';

type Props<T> = {
	loading?: boolean;
	elements: T[];
	list: ListProps<T>;
	table: TableProps<T>;
	tableSx?: SxProps<Theme>;
	listSx?: SxProps<Theme>;
};

/**
 * Creates a material ui list or table depending on the media query
 *
 * @param elements {array} Array of elements to be listed
 * @param list {object} Configuration needed to render the data as List
 * @param table {object} Configuration needed to render the data as Table
 * @param loading {boolean} [Optional defaults to false] Boolean indicating that the data is loading
 */
function ResponsiveTable<T>({ loading = false, elements, list, table, listSx, tableSx }: Props<T>) {
	const isMobile = useIsMobile();

	if (isMobile) {
		return <List elements={elements} loading={loading} {...list} sx={listSx} />;
	}

	if (!isMobile) {
		return <Table elements={elements} {...table} sx={tableSx} />;
	}

	return null;
}

export default ResponsiveTable;
