import { MenuItem, Select, SxProps, Theme } from '@mui/material';
import usePlaces from 'hooks/usePlaces';
import { useTranslation } from 'react-i18next';

type Props = {
	selected?: string;
	onSelected: (place?: Place) => any;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	fullWidth?: boolean;
};

function PlaceSelector({ selected = '', sx, disabled, onSelected, fullWidth = true }: Props) {
	const { t } = useTranslation();
	const { loading, places } = usePlaces();

	function onChange(id: string) {
		const found = places.find((place) => place._id === id);
		onSelected(found);
	}

	return (
		<Select
			labelId="place-selector-label"
			id="place-selector"
			label={t('common:place')}
			fullWidth={fullWidth}
			value={selected}
			variant="outlined"
			sx={sx}
			onChange={(ev) => onChange(ev.target.value)}
			disabled={loading || disabled}
		>
			{places.map((place) => {
				return (
					<MenuItem key={`place_${place._id}`} value={place._id}>
						{place.name['es']}
					</MenuItem>
				);
			})}
		</Select>
	);
}

export default PlaceSelector;
