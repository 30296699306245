import { API_DOMAIN, FAQ } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { zInternationalization } from 'lib/zodHelpers';

import { z } from 'zod';

export const zFaq = z.object({
	_id: z.optional(z.string()),
	question: zInternationalization,
	response: zInternationalization,
});

const zFaqArray = z.custom<Faq[]>((data) => {
	return Array.isArray(data) && (!data.length || zFaq.safeParse(data[0]).success);
});

export async function fetchFaq(): Promise<Faq[]> {
	try {
		const response = await api.get(`${API_DOMAIN}${FAQ}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zFaqArray.safeParse(response.data);
		if (data.success) {
			return data.data;
		}
		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function updateFaq(faq: Faq): Promise<Faq> {
	const url = faq._id ? `${API_DOMAIN}${FAQ}/${faq._id}` : `${API_DOMAIN}${FAQ}`;

	if (!faq._id) {
		delete faq._id;
	}

	const response = await (faq._id ? api.patch(url, faq) : api.post(url, faq));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zFaq.safeParse(response.data);

	if (data.success) {
		return data.data;
	}

	throw new Error('invalid response');
}

export async function removeFaq(faq: Faq): Promise<boolean> {
	const url = `${API_DOMAIN}${FAQ}/${faq._id}`;

	const response = await api.delete(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (response.data) {
		return true;
	}

	throw new Error('invalid response');
}
