import { ACCESS_URL, API_DOMAIN } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import moment from 'moment';
import { z } from 'zod';

export const zAccess = z.object({
	_id: z.string(),
	code: z.string(),
	type: z.string(),
	stay: z.string(),
	tent: z.optional(z.number()),
	plate: z.array(z.optional(z.string())),
	email: z.optional(z.string()),
	startDate: z.optional(z.string()),
	endDate: z.optional(z.string()),
	movements: z.array(z.optional(z.object({}))),
	createdAt: z.optional(z.string()),
});

export type FetchAccessSearchProps = {
	plate?: string;
	isAssigned?: string;
	from?: string;
	to?: string;
	stay?: string;
};

export type FetchAccessProps = FetchAccessSearchProps;

export async function fetchAccess(props?: FetchAccessSearchProps): Promise<Access[]> {
	try {
		const response = await api.get<Access[]>(`${API_DOMAIN}${ACCESS_URL}`, {
			params: props,
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (!Array.isArray(response.data) || (response.data.length > 0 && !zAccess.safeParse(response.data[0]).success)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchAccessByCode(code: string): Promise<{ access: Access; outOfDate: boolean } | null> {
	try {
		const response = await api.get<{ access: Access; outOfDate: boolean }>(`${API_DOMAIN}${ACCESS_URL}/verify/${code}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function verifyCodeDateRange(code: string): Promise<{ access: Access; outOfDate: boolean } | string> {
	try {
		const response = await api.get<{ access: Access; outOfDate: boolean }>(`${API_DOMAIN}${ACCESS_URL}/verify/${code}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		return response.data;
	} catch (error: any) {
		if (error.request.status === 403) return 'system:titlesUnauthorized';

		return 'access:invalid_code_or_plate';
	}
}

export async function verifyCodeDateRangeOnExit(
	plate: string
): Promise<{ access: Access; outOfDate: boolean } | string> {
	try {
		const response = await api.get<{ access: Access; outOfDate: boolean }>(
			`${API_DOMAIN}${ACCESS_URL}/verifyExit/${plate}`
		);

		if (!checkStatus(response)) {
			throw new Error('invalid_code_or_plate');
		}

		return response.data;
	} catch (error: any) {
		if (error.request.status === 403) return 'system:titlesUnauthorized';

		return 'access:invalid_code_or_plate';
	}
}

export async function saveAccess(
	isToday: boolean,
	startDate: string,
	endDate: string,
	stay: string,
	quantity: number,
	type: string
): Promise<Access[]> {
	const url = `${API_DOMAIN}${ACCESS_URL}${isToday ? '' : '/withDate'}`;

	const response = await api.post<Access[]>(url, { startDate, endDate, stay, quantity, type });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (!Array.isArray(response.data) || (response.data.length > 0 && !zAccess.safeParse(response.data[0]).success)) {
		throw new Error('invalid response');
	}

	return response.data;
}

export async function saveAccessTent(
	startDate: string,
	endDate: string,
	stay: string,
	type: string,
	tent: string,
	email?: string
): Promise<Access> {
	const url = `${API_DOMAIN}${ACCESS_URL}/tent`;

	const response = await api.post<Access>(url, { startDate, endDate, stay, type, tent, email });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (!zAccess.safeParse(response.data).success) {
		throw new Error('invalid response');
	}

	return response.data;
}

export async function saveEntry({ code, plate }: { code: string; plate?: string }): Promise<Access> {
	const url = `${API_DOMAIN}${ACCESS_URL}/entry/${code}`;

	const response = await api.patch<Access>(url, { plate });

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data;
}

export async function saveExit({
	code,
	plate,
}: {
	code?: string;
	plate?: string;
}): Promise<{ access: Access; outOfDate: boolean }> {
	if (!code && !plate) {
		throw new Error('Missing code or plate');
	}

	const url = `${API_DOMAIN}${ACCESS_URL}/exit/${code || plate}`;

	const response = await api.patch<{ access: Access; outOfDate: boolean }>(url, { plate });

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data;
}

export async function changeStatus(id: string) {
	try {
		if (!id) throw new Error('Missing ID');
		const url = `${API_DOMAIN}${ACCESS_URL}/change-status/${id}`;

		const response = await api.patch<Access>(url);

		if (!checkStatus(response)) {
			throw new Error('Invalid credentials');
		}
		return response.data;
	} catch (error: any) {
		return null;
	}
}

export async function savePlate({ code, plate }: { code: string; plate: string }) {
	try {
		if (!code || !plate) throw new Error('Missing params');

		const url = `${API_DOMAIN}${ACCESS_URL}/save-plate/${code}`;

		const response = await api.patch<{ access: Access; error: string }>(url, { plate });

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function syncScans() {
	try {
		const scanQueue = JSON.parse(localStorage.getItem('scanQueue') || '[]');

		if (scanQueue.length === 0) return [];

		const url = `${API_DOMAIN}${ACCESS_URL}/sync`;

		const response = await api.post(url, { scanQueue });

		if (!checkStatus(response)) {
			throw new Error('Invalid credentials');
		}
		localStorage.setItem('scanQueue', '[]');
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function fetchDailyCodes(): Promise<Access[] | null> {
	try {
		const response = await api.get<Access[]>(`${API_DOMAIN}${ACCESS_URL}`, {
			params: { date: moment().startOf('day').toISOString(), isAssigned: 'any' },
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (!Array.isArray(response.data)) {
			throw new Error('invalid response');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}
