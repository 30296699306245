import { ChangeEvent } from 'react';
import { nanoid } from '@reduxjs/toolkit';

import { defaultLanguage } from 'lib/zodHelpers';

export type ImportError = { element: any; error: string };

export type StateType = Place & {
	loading: boolean;
};

const defaultState: StateType = {
	loading: false,
	address: '',
	code: '',
	mapsLink: '',
	name: {
		es: '',
	},
};

export function getDefaultState(selected?: Place) {
	const state: StateType = {
		...defaultState,
		code: selected?.code ?? nanoid(),
		...(selected || {}),
	};
	return state;
}

type Init = {
	type: 'Init';
	payload: StateType | null;
};

type Reset = {
	type: 'Reset';
};

type SetLoading = {
	type: 'SetLoading';
	payload: boolean;
};

type EventPayload = {
	ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
	language?: Language;
};

type SetName = {
	type: 'name';
	payload: EventPayload;
};

type SetAddress = {
	type: 'address';
	payload: EventPayload;
};

type SetMapsLink = {
	type: 'mapsLink';
	payload: {
		url: string;
		language?: Language;
	};
};

type Actions = Init | Reset | SetLoading | SetName | SetAddress | SetMapsLink;

export const reducer = (state: StateType, action: Actions): StateType => {
	switch (action.type) {
		case 'Init':
			return {
				...state,
				...(action.payload || {}),
				loading: false,
			};
		case 'SetLoading':
			return { ...state, loading: action.payload };
		case 'name':
			return {
				...state,
				name: {
					...state.name,
					[action.payload.language ?? defaultLanguage]: action.payload.ev.target.value,
				},
			};
		case 'address':
			return {
				...state,
				[action.type]: action.payload.ev.target.value,
			};
		case 'mapsLink':
			return {
				...state,
				[action.type]: action.payload.url,
			};
		case 'Reset': {
			return {
				...defaultState,
				loading: false,
			};
		}
	}
};
