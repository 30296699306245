import useServerDown from 'hooks/useServerDown';
import { fetchDailyCodes } from 'lib/models/access';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setDailyCodeList } from 'store/reducers/dailyCodes';
import useStoreDispatch from 'store/useStoreDispatch';

function DailyCodesLoader() {
	const location = useLocation();
	const dispatch = useStoreDispatch();
	const serverIsDown = useServerDown();
	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!serverIsDown) {
					const response = await fetchDailyCodes();

					if (!response) throw new Error('Network error');

					dispatch(setDailyCodeList(response));
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
	}, [dispatch, serverIsDown, location.pathname]);
	return <></>;
}

export default DailyCodesLoader;
