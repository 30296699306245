import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import NotificationsList from 'components/NotificationsList';
import CreateIcon from 'components/icons/CreateIcon';
import NotificationModal from 'components/NotificationModal';

import useNotifications from 'hooks/useNotifications';

import { FetchNotificationsProps, NotificationTopic, isTopic } from 'lib/models/notification';
import { useSearchParams } from 'react-router-dom';
import SearchToolbar from 'components/SearchToolbar';
import NotificationTopicSelector from 'components/NotificationTopicSelector';

const defaultNotification: AppNotification = {
	title: {
		es: '',
	},
	message: {
		es: '',
	},
	topic: NotificationTopic.custom,
};

function Notifications() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams({ search: '', page: '0', rowsPerPage: '25', topic: '' });
	const paramsTopic = searchParams.get('topic');
	const defaultTopic = isTopic(paramsTopic) ? paramsTopic : null;
	const [selectedTopic, setSelectedTopic] = useState<NotificationTopic | null>(defaultTopic);
	const [search, setSearch] = useState(searchParams.get('search') || '');
	const [page, setPage] = useState(Number(searchParams.get('page') || 0));
	const [rowsPerPage, setRowsPerPage] = useState(Number(searchParams.get('rowsPerPage') || 10));

	const searchProps = useMemo(() => {
		let searchParams: FetchNotificationsProps = { page, rowsPerPage, search, topic: selectedTopic };
		return searchParams;
	}, [page, rowsPerPage, search, selectedTopic]);

	const { notifications: data, loading, refetch } = useNotifications(searchProps);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function onSearch(value: string) {
		setSearch(value);
	}

	useEffect(() => {
		setSearchParams({ page: `${page}`, rowsPerPage: `${rowsPerPage}`, search: `${search}`, topic: `${selectedTopic}` });
	}, [setSearchParams, page, rowsPerPage, search, selectedTopic]);

	const [showModal, setShowModal] = useState<AppNotification | null>(null);

	const title = t('common:notifications');

	function onClose() {
		refetch();
		setShowModal(null);
	}

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultNotification)} disabled={loading} />

			{showModal && <NotificationModal defaultData={showModal} onClose={onClose} onRefetch={refetch} />}

			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<NotificationTopicSelector
					selected={selectedTopic || 'all'}
					onSelected={(topic) => setSelectedTopic(topic)}
					sx={{ minWidth: '30vw' }}
					fullWidth={false}
				/>

				<SearchToolbar onSearch={onSearch} initialValue={search} />
			</Box>

			<Box>
				<NotificationsList
					refetch={refetch}
					data={data}
					loading={loading}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					page={page}
					rowsPerPage={rowsPerPage}
				/>
			</Box>
		</ScreenContainer>
	);
}

export default Notifications;
