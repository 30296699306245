import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	access?: Access;
	onSave: (params: { code?: string; plate?: string }) => void;
	handleClose: () => void;
};

function OutOfDateExit({ open, access, onSave, handleClose }: Props) {
	const { t } = useTranslation();

	return (
		<Dialog open={!!open}>
			<DialogTitle align="center" color="red">
				{t('access:outOfDate')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('access:code')}
					{`: ${access?.code}`}
				</DialogContentText>
				<DialogContentText>
					{t('access:plate')}
					{`: ${access?.plate || '-'}`}
				</DialogContentText>
				<DialogContentText>
					{access?.stay !== 'tent' ? t('common:date') : t('access:start_date')}
					{`: ${dateParser(access?.validDate.from)}`}
				</DialogContentText>
				{access?.stay === 'tent' && access?.validDate.to && (
					<DialogContentText>
						{t('access:end_date')}: {dateParser(access?.validDate.to)}
					</DialogContentText>
				)}
				<DialogContentText>
					{t('access:stay')}: {t(`access:${access?.stay}`)}
				</DialogContentText>
				{access?.tent && (
					<DialogContentText>
						{t('access:tent')}: {access.tent}
					</DialogContentText>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="error">
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						if (access && access.plate) {
							onSave({ code: access.code });
						}
						handleClose();
					}}
					color="primary"
				>
					{t('common:accept')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default OutOfDateExit;

function dateParser(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	let day: string | number = date.getUTCDate();
	let month: string | number = date.getUTCMonth() + 1;
	const year = date.getUTCFullYear();

	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	const parsedDate = `${day}/${month}/${year}`;

	return parsedDate;
}
