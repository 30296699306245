import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	access: Access | undefined;
	onSave: (params: { code?: string; plate?: string }) => void;
	handleClose: () => void;
};

function SuccessfulExit({ open, access, handleClose, onSave }: Props) {
	const { t } = useTranslation();
	return (
		<Dialog open={!!open}>
			<DialogTitle align="center">{t('access:associated_code')}</DialogTitle>
			<DialogContent sx={{ flexDirection: 'column', display: 'flex', gap: '1rem' }}>
				{access &&
					access.plate?.map((p) => {
						return (
							<TextField
								disabled={true}
								autoFocus
								autoComplete="off"
								value={p}
								inputProps={{
									style: { textTransform: 'uppercase', textAlign: 'center' },
									maxLength: 7,
								}}
							/>
						);
					})}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="error">
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						if (access) {
							onSave({ code: access.code });
						}
						handleClose();
					}}
					disabled={!access?.code || access?.code.length !== 8}
					color="primary"
				>
					{t('common:confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SuccessfulExit;
