import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ArrowDownward, ArrowUpward, Visibility, VisibilityOff } from '@mui/icons-material';

type Props = {
	merch: AppMerchandising;
	onPublish: (ev: AppMerchandising) => any;
	onUnPublish: (ev: AppMerchandising) => any;
	onEdit: (ev: AppMerchandising) => any;
	onDelete: (ev: AppMerchandising) => any;
	handlePosition: (ev: AppMerchandising, mode: 'increase' | 'decrease') => any;
	handleSetFeatured: (ev: AppMerchandising) => any;
	handleUnsetFeatured: (ev: AppMerchandising) => any;
	total: number;
	firstFeaturedPosition: number;
};

export default function Actions({
	merch,
	onPublish,
	onUnPublish,
	onEdit,
	onDelete,
	handlePosition,
	handleSetFeatured,
	handleUnsetFeatured,
	total,
	firstFeaturedPosition,
}: Props) {
	const { t } = useTranslation();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
				{merch.status === 'draft' && (
					<Button onClick={() => onPublish(merch)} variant="outlined">
						{t(`common:publish`)}
					</Button>
				)}
				{merch.status === 'active' && !merch.featuredPosition && (
					<Button onClick={() => onUnPublish(merch)} variant="outlined" color="error">
						{t(`common:unpublish`)}
					</Button>
				)}
				<Button onClick={() => onEdit(merch)} variant="outlined">
					{t(`common:edit`)}
				</Button>
				{merch.status === 'draft' && (
					<Button onClick={() => onDelete(merch)} variant="outlined" color="error">
						{t(`common:delete`)}
					</Button>
				)}
			</Box>
			{merch.status === 'active' && (
				<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
					{merch.featuredPosition && (
						<>
							<Button
								disabled={merch.featuredPosition === firstFeaturedPosition}
								onClick={() => handlePosition(merch, 'decrease')}
								variant="text"
								endIcon={<ArrowUpward />}
							>
								{t(`common:up`)}
							</Button>
							<Button
								disabled={merch.featuredPosition === total}
								onClick={() => handlePosition(merch, 'increase')}
								variant="text"
								color="secondary"
								endIcon={<ArrowDownward />}
							>
								{t(`common:down`)}
							</Button>
						</>
					)}
					{!merch.featuredPosition ? (
						<Button onClick={() => handleSetFeatured(merch)} variant="text" color="secondary" endIcon={<Visibility />}>
							{t(`common:setFeatured`)}
						</Button>
					) : (
						<Button
							onClick={() => handleUnsetFeatured(merch)}
							variant="text"
							color="secondary"
							endIcon={<VisibilityOff />}
						>
							{t(`common:unsetFeatured`)}
						</Button>
					)}
				</Box>
			)}
		</Box>
	);
}
