import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import EventsTable from 'components/EventsTable';
import SearchToolbar from 'components/SearchToolbar';

import useEvents from 'hooks/useEvents';
import PlaceSelector from 'components/PlaceSelector';
import { FetchEventProps, isAppEventStatus } from 'lib/models/events';
import StatusSelector from 'components/StatusSelector';

function Events() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams({
		search: '',
		page: '0',
		rowsPerPage: '25',
		place: '',
		status: 'draft',
	});
	const statusFromParams = searchParams.get('status');
	const defaultStatus = statusFromParams && isAppEventStatus(statusFromParams) ? statusFromParams : 'draft';
	const [status, setStatus] = useState<AppEventStatus>(defaultStatus);
	const [selectedPlace, setSelectedPlace] = useState(searchParams.get('place') || '');
	const [search, setSearch] = useState(searchParams.get('search') || '');
	const [page, setPage] = useState(Number(searchParams.get('page') || 0));
	const [rowsPerPage, setRowsPerPage] = useState(Number(searchParams.get('rowsPerPage') || 25));

	const searchProps = useMemo(() => {
		let searchParams: FetchEventProps = { page, rowsPerPage, search, status };
		if (selectedPlace) {
			searchParams = { ...searchParams, place: selectedPlace };
		}
		return searchParams;
	}, [page, rowsPerPage, search, selectedPlace, status]);

	const featuredSearch = useMemo(() => {
		return { ...searchProps, orderBy: 'featuredPosition', order: 'asc' as 'asc', featuredPosition: { $gte: 1 } };
	}, [searchProps]);

	const eventsSearch = useMemo(() => {
		return { ...searchProps, orderBy: 'date', order: 'asc' as 'asc', featuredPosition: { $exists: false } };
	}, [searchProps]);

	const { events: featuredEvents, loading: featuredLoading, refetch: featuredRefetch } = useEvents(featuredSearch);

	const { events, loading: eventsLoading, refetch: eventsRefetch } = useEvents(eventsSearch);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function onSearch(value: string) {
		setSearch(value);
	}

	function refetch() {
		featuredRefetch();
		eventsRefetch();
	}

	useEffect(() => {
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			search: `${search}`,
			place: `${selectedPlace}`,
			status: `${status}`,
		});
	}, [setSearchParams, page, rowsPerPage, search, selectedPlace, status]);

	return (
		<ScreenContainer title={t('common:app_event_plural')}>
			{<CreateIcon onCreate={() => navigate('/events/new')} />}

			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
				<StatusSelector
					selected={status}
					onSelected={(status) => setStatus(status)}
					fullWidth={false}
					sx={{ minWidth: '15vw' }}
				/>
				<PlaceSelector
					selected={selectedPlace}
					onSelected={(place) => setSelectedPlace(place?._id ?? '')}
					fullWidth={false}
					sx={{ minWidth: '15vw' }}
				/>

				<SearchToolbar onSearch={onSearch} initialValue={search} />
			</Box>

			<Accordion defaultExpanded={true} sx={{ mt: 1 }}>
				<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography>{t('common:important')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography color="error">{t('common:msjNotification')}</Typography>
				</AccordionDetails>
			</Accordion>

			<Accordion defaultExpanded={true} sx={{ mt: 1 }}>
				<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography>{t('common:featured_plural')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{status !== 'active' && <Typography color="error">{t('common:featuredDraftError')}</Typography>}
					{status === 'active' && (
						<EventsTable
							sx={{ maxHeight: '60vh', borderColor: 'red', borderWidth: 2 }}
							events={featuredEvents}
							loading={featuredLoading}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							refetch={refetch}
							page={page}
							rowsPerPage={rowsPerPage}
						/>
					)}
				</AccordionDetails>
			</Accordion>

			<Accordion defaultExpanded={true}>
				<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
					<Typography>{t('common:all')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<EventsTable
						sx={{ maxHeight: '60vh' }}
						events={events}
						loading={eventsLoading}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						refetch={refetch}
						page={page}
						rowsPerPage={rowsPerPage}
					/>
				</AccordionDetails>
			</Accordion>
		</ScreenContainer>
	);
}

export default Events;
