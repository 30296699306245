import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, TextField, Box, Select, MenuItem, LinearProgress } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { enqueueSnackbarError } from 'lib/helpers';
import AccessTickets from 'components/AccessTickets';
import moment, { Moment } from 'moment';
import { saveAccess, saveAccessTent } from 'lib/models/access';
import useUser from 'hooks/useUser';

type Props = {
	vehicles: Vehicle[];
	loading: boolean;
};

function GenerateAccessForm({ vehicles }: Props) {
	const { t } = useTranslation();

	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const [isPrinting, setIsPrinting] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);

	const [quantity, setQuantity] = useState<number | ''>(1);

	const [accessCodeInfo, setAccessCodeInfo] = useState<Access>(getDefaultAccess());

	const [accessCodes, setAccessCodes] = useState<Access[]>([]);

	const [startDate, setStartDate] = useState<Moment | null>(moment());

	const [endDate, setEndDate] = useState<Moment | null>(moment());

	const [canSave, setCanSave] = useState<boolean>(false);

	useEffect(() => {
		const { stay, tent, type } = accessCodeInfo;
		const today = moment();
		const sameDay = startDate?.isSame(today, 'day') && endDate?.isSame(today, 'day');
		const tentConditions = stay === 'tent' && !!tent && !!startDate && !!endDate && type && isAdmin;
		const nonTentConditions = stay !== 'tent' && type && isAdmin;

		if (tentConditions || nonTentConditions || (stay !== 'tent' && type && sameDay)) {
			setCanSave(true);
		} else {
			setCanSave(false);
		}
	}, [accessCodeInfo, isAdmin, endDate, startDate]);

	const onUpdate = (key: keyof Access) => {
		return (event: any) => {
			const value = event.target.value;

			if (accessCodeInfo) {
				setAccessCodeInfo({ ...accessCodeInfo, [key]: value });
			}
		};
	};

	const onUpdateDate = (date: Moment | null, key: keyof ValidDate) => {
		if (accessCodeInfo && date) {
			const updated = { ...accessCodeInfo };

			updated.validDate[key] = new Date(date.format()).toString();

			setAccessCodeInfo(updated);
		}
	};

	const save = async () => {
		try {
			setLoading(true);
			if (!startDate || !endDate) {
				throw new Error('Invalid dates');
			}
			const start = startDate.format('YYYY-MM-DD');
			const end = endDate.format('YYYY-MM-DD');

			const { type, stay, tent, email } = accessCodeInfo;

			if (stay !== 'tent' && quantity) {
				const today = moment();
				const isToday = today.isSame(startDate, 'day') && today.isSame(endDate, 'day');

				const accessCodes = await saveAccess(isToday, start, end, stay, quantity, type);
				setAccessCodes(accessCodes);
				setIsPrinting(true);
			}

			if (stay === 'tent' && tent) {
				const accessCode = await saveAccessTent(start, end, stay, type, tent, email);
				setAccessCodes([accessCode]);
				setIsPrinting(true);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	};

	return (
		<Box>
			{loading && <LinearProgress />}
			{!isPrinting && (
				<FormGroup>
					<Select
						labelId="stay"
						id="stay"
						label={t('access:stay')}
						fullWidth={true}
						value={accessCodeInfo.stay || 'day'}
						variant="outlined"
						sx={{ mb: 3 }}
						onChange={(e) => {
							setAccessCodeInfo(getDefaultAccess(e.target.value, accessCodeInfo.type));
						}}
						autoComplete="off"
					>
						<MenuItem value={'day'}>{t('access:day')}</MenuItem>
						<MenuItem value={'night'}>{t('access:night')}</MenuItem>
						{isAdmin && <MenuItem value={'tent'}>{t('access:tent')}</MenuItem>}
					</Select>

					{accessCodeInfo.stay !== 'tent' && (
						<>
							<TextField
								type="number"
								inputProps={{ min: 1 }}
								autoComplete="off"
								id="quantity"
								label={t('access:quantityPerDay')}
								fullWidth={true}
								value={quantity}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={(e) => {
									const value = Number(e.target.value);
									setQuantity(value === 0 ? '' : value);
								}}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={startDate}
								format="DD/MM/YYYY"
								label={t('access:from')}
								onChange={(ev) => {
									setStartDate(ev);
								}}
								minDate={moment()}
								disabled={!isAdmin}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={endDate}
								format="DD/MM/YYYY"
								label={t('access:to')}
								onChange={(ev) => {
									setEndDate(ev);
								}}
								minDate={startDate}
								disabled={!isAdmin}
							/>
						</>
					)}
					{accessCodeInfo.stay === 'tent' && (
						<>
							<TextField
								autoComplete="off"
								id="quantity"
								label={t('access:tent_number')}
								fullWidth={true}
								value={accessCodeInfo.tent}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('tent')}
							/>
							<TextField
								autoComplete="off"
								id="tent"
								label={t('common:Email')}
								fullWidth={true}
								value={accessCodeInfo.email}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('email')}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={startDate}
								format="DD/MM/YYYY"
								label={t('access:start_date')}
								onChange={(ev) => {
									onUpdateDate(ev, 'from');
									setStartDate(ev);
								}}
								minDate={moment()}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={endDate}
								format="DD/MM/YYYY"
								label={t('access:end_date')}
								onChange={(ev) => {
									onUpdateDate(ev, 'to');
									setEndDate(ev);
								}}
								minDate={startDate ? moment(startDate) : moment()}
							/>
						</>
					)}

					<Select
						labelId="type"
						id="type"
						fullWidth={true}
						value={accessCodeInfo.type || vehicles[0]?.description}
						variant="outlined"
						sx={{ mb: 3 }}
						onChange={onUpdate('type')}
					>
						{vehicles?.map((vehicle) => {
							return (
								<MenuItem value={vehicle.description} selected={true}>
									{vehicle.description}
								</MenuItem>
							);
						})}
					</Select>

					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('access:generate')}
					</Button>
				</FormGroup>
			)}
			{isPrinting && (
				<AccessTickets
					date={{ start: startDate?.format('DD-MM-YYYY'), end: endDate?.format('DD-MM-YYYY') }}
					accessCodes={accessCodes}
					loading={loading}
					setLoading={setLoading}
				/>
			)}
		</Box>
	);
}

export default GenerateAccessForm;

function getDefaultAccess(stay?: string, type?: string): Access {
	return {
		code: '',
		plate: [],
		stay: stay || 'day',
		type: type || '',
		tent: '',
		email: '',
		validDate: {},
		movements: [],
		vehiclesInside: 0,
		isActive: true,
	};
}
