import { API_DOMAIN, NOTIFICATION } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';
import { zInternationalization } from 'lib/zodHelpers';

import { z } from 'zod';

export enum NotificationTopic {
	'artistNewDates' = 'artistNewDates',
	'cancelation' = 'cancelation',
	'custom' = 'custom',
	'eventChanges' = 'eventChanges',
}

// The 'artistNewDates' topic necessitates an artist's ID. Therefore, it is not included
// in this array since, during the user's initial creation, their favorites array is empty,
// and they haven't yet added any artists.
const topics: NotificationTopic[] = [
	NotificationTopic.cancelation,
	NotificationTopic.custom,
	NotificationTopic.eventChanges,
];

export const allTopics: NotificationTopic[] = [NotificationTopic.artistNewDates, ...topics];

export const zNotification = z.object({
	_id: z.optional(z.string()),
	title: zInternationalization,
	message: zInternationalization,
	createdAt: z.optional(z.string()),
	topic: z.custom<NotificationTopic>(
		(val: any) => typeof val === 'string' && allTopics.includes(val as NotificationTopic)
	),
});

const zNotificationsArrayResponse = z.object({
	total: z.number(),
	// We only need to validate the first element, if its ok, we assume that the rest are ok
	elements: z.custom<AppNotification[]>((data) => {
		if (Array.isArray(data) && data.length > 0) {
			const parsed = zNotification.safeParse(data[0]);
			if (!parsed.success) {
				console.error(`${parsed.error}`);
			}
			return parsed.success;
		}
		if (!Array.isArray(data)) {
			console.error(`response should be a valid array`);
			return false;
		}
		return true;
	}),
});

export type FetchNotificationsSearchProps = {
	search?: string | null;
	topic?: string | null;
};

export type FetchNotificationsProps = FetchNotificationsSearchProps & PaginationProps;

export async function fetchNotifications(props: FetchNotificationsProps): Promise<PaginateSource<AppNotification>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'title']: props.order && props.order === 'asc' ? 1 : -1,
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${NOTIFICATION}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zNotificationsArrayResponse.parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function updateNotification(notification: AppNotification): Promise<AppNotification> {
	const url = `${API_DOMAIN}${NOTIFICATION}`;

	const response = await api.post(url, notification);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zNotification.safeParse(response.data);

	if (data.success) {
		return data.data;
	}

	throw new Error('invalid response');
}

export async function removeNotification(notification: AppNotification): Promise<boolean> {
	const url = `${API_DOMAIN}${NOTIFICATION}/${notification._id}`;

	const response = await api.delete(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (response.data) {
		return true;
	}

	throw new Error('invalid response');
}

export function isTopic(topic: any): topic is NotificationTopic {
	return typeof topic === 'string' && allTopics.includes(topic as NotificationTopic);
}
