import { Box, TextField } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type TimeSelectorProps = {
	onUpdateTime: (hours: number, minutes: number) => any;
	disabled?: boolean;
	defaultValue?: string | { hours: number; minutes: number };
};

function TimeSelector({ onUpdateTime, disabled, defaultValue }: TimeSelectorProps) {
	const { t } = useTranslation();

	const defaultDate =
		defaultValue && typeof defaultValue === 'string'
			? moment(defaultValue)
			: moment()
					//@ts-ignore
					.set('hours', defaultValue?.hours ?? 0)
					//@ts-ignore
					.set('minutes', defaultValue?.minutes ?? 0);

	const [hours, setHours] = useState(defaultDate.get('hours'));
	const [minutes, setMinutes] = useState(defaultDate.get('minutes'));

	function update(type: 'hours' | 'minutes', value: string) {
		const newValue = Number(value);
		if (type === 'hours') {
			setHours(newValue);
			onUpdateTime(newValue, minutes);
		} else {
			setMinutes(newValue);
			onUpdateTime(hours, newValue);
		}
	}
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
			<Box>
				<TextField
					sx={{ minWidth: 180 }}
					autoFocus
					margin="dense"
					id="address"
					variant="outlined"
					label={t('common:hour')}
					type="number"
					inputProps={{ min: 0, max: 23 }}
					fullWidth
					value={hours}
					onChange={(ev) => update('hours', ev.currentTarget.value)}
					disabled={disabled}
				/>
			</Box>
			<Box>
				<TextField
					sx={{ minWidth: 180 }}
					autoFocus
					margin="dense"
					id="address"
					variant="outlined"
					label={t('common:minutes')}
					type="number"
					inputProps={{ min: 0, max: 59, step: 15 }}
					fullWidth
					value={minutes}
					onChange={(ev) => update('minutes', ev.currentTarget.value)}
					disabled={disabled}
				/>
			</Box>
		</Box>
	);
}

export default TimeSelector;
