import { z } from 'zod';

import { API_DOMAIN, TAG_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export const zTag = z.object({
	_id: z.optional(z.string()),
	text: z.string(),
});

export async function fetchTags(): Promise<Tag[]> {
	try {
		const response = await api.get<Tag[]>(`${API_DOMAIN}${TAG_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!Array.isArray(response.data) || (response.data.length > 0 && !zTag.safeParse(response.data[0]).success)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchTag(id: string): Promise<Tag | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${TAG_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zTag.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateTag(tag: Tag): Promise<Tag | null> {
	try {
		if (!tag._id) {
			delete tag._id;
		}

		const url = tag._id ? `${API_DOMAIN}${TAG_URL}/${tag._id}` : `${API_DOMAIN}${TAG_URL}`;

		const response = await (tag._id ? api.patch(url, tag) : api.post(url, tag));

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zTag.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error(data.error.message);
	} catch (error) {
		console.error(error);
	}

	return null;
}
