import { z } from 'zod';

import { API_DOMAIN, GENRE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export const zGenre = z.object({
	_id: z.optional(z.string()),
	text: z.string(),
});

export async function fetchGenres(): Promise<Genre[]> {
	try {
		const response = await api.get<Genre[]>(`${API_DOMAIN}${GENRE_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!Array.isArray(response.data) || (response.data.length > 0 && !zGenre.safeParse(response.data[0]).success)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchGenre(id: string): Promise<Genre | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${GENRE_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zGenre.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateGenre(Genre: Genre): Promise<Genre | null> {
	try {
		if (!Genre._id) {
			delete Genre._id;
		}

		const url = Genre._id ? `${API_DOMAIN}${GENRE_URL}/${Genre._id}` : `${API_DOMAIN}${GENRE_URL}`;

		const response = await (Genre._id ? api.patch(url, Genre) : api.post(url, Genre));

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zGenre.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error(data.error.message);
	} catch (error) {
		console.error(error);
	}

	return null;
}
