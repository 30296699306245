import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import { enqueueSnackbarSuccess } from 'lib/helpers';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	code: string;
	open: string | null;
	route?: RouteType;
	handleClose: () => void;
	result: { code: string; description: string; error: boolean }[];
	setResult: (result: []) => void;
};

function OfflineScanning({ code, route, open, handleClose, result, setResult }: Props) {
	const { t } = useTranslation();

	const [plate, setPlate] = useState<string>('');

	const errors = result?.filter((obj) => obj.error === true);

	function enqueueScan() {
		if (!route || typeof route !== 'string') return;

		let savedQueue: StoredScan[] = JSON.parse(localStorage.getItem('scanQueue') || '[]');
		if (!Array.isArray(savedQueue)) savedQueue = [];
		const item: StoredScan = { code, type: route, date: moment().toISOString(), plate };
		savedQueue.push(item);
		const stringQueue = JSON.stringify(savedQueue);
		localStorage.setItem('scanQueue', stringQueue);

		enqueueSnackbarSuccess(t('access:savedInLocalStorage'));

		return handleClose();
	}

	return (
		<Dialog open={!!open}>
			{!result || result?.length === 0 ? (
				<>
					<DialogTitle sx={{ paddingTop: '.5rem', paddingBottom: 0 }} textAlign="center">
						{t('access:offlineScanning')}
					</DialogTitle>
					<DialogContent>
						<DialogContentText textAlign="center" marginY=".5rem">
							{t('access:code')}: {code}
						</DialogContentText>
						<DialogContentText textAlign="center" marginBottom=".5rem">
							{`${t('access:assignPlate')} (${t('access:optional')})`}
						</DialogContentText>
						<TextField
							autoFocus
							autoComplete="off"
							value={plate}
							onChange={(e) => setPlate(e.target.value.toUpperCase())}
							inputProps={{
								style: { textTransform: 'uppercase', textAlign: 'center' },
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="error"
							onClick={() => {
								handleClose();
								setResult([]);
							}}
						>
							{t('common:cancel')}
						</Button>
						<Button color="primary" onClick={enqueueScan}>
							{t('common:save')}
						</Button>
					</DialogActions>
				</>
			) : (
				<>
					<DialogTitle textAlign="center">
						{t('access:synchronizedAccess')}: {result.length}
					</DialogTitle>
					<Typography variant="h6" textAlign="center">
						{t('access:errors')}: {errors?.length}
					</Typography>
					<DialogContent>
						{errors?.map((r, i) => (
							<DialogContentText textAlign={'center'} color={'red'} key={i}>
								{r.code} {r.description}
							</DialogContentText>
						))}
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleClose}>
							{t('common:ok')}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}

export default OfflineScanning;
