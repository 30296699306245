import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PaperContainer from 'components/containers/PaperContainer';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { enqueueSnackbarError } from 'lib/helpers';
import { deleteMerchCategory } from 'lib/models/merchCategories';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	loading: boolean;
	data: AppMerchCategory[];
	onEdit: (merchCategory: AppMerchCategory) => void;
	refetch: () => void;
};
export default function MerchCategoriesList({ loading, data, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<AppMerchCategory | null>(null);

	const onDelete = (merchCategory: AppMerchCategory) => {
		setShowDeleteConfirmation(merchCategory);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			if (showDeleteConfirmation.items.length > 0) {
				setShowDeleteConfirmation(null);

				return enqueueSnackbarError(t('merch:errorHasItems'));
			}
			const deleted = await deleteMerchCategory(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('merch:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};
	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			<Box>
				{data.length === 0 && <Typography>{t('common:withoutElements')}</Typography>}
				{data.map((el) => {
					return (
						<PaperContainer
							key={el._id}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								mb: 2,
							}}
						>
							<Box>
								<Typography sx={{ mb: 2 }}>
									<strong>{el.title}</strong>
								</Typography>
								<Typography sx={{ mb: 2 }}>{`${el.items.length} ${t('common:products')}`}</Typography>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'flex-end',
									gap: 1,
									paddingLeft: 2,
								}}
							>
								<Button
									variant="contained"
									fullWidth={true}
									color="primary"
									size="large"
									startIcon={<Edit />}
									onClick={() => onEdit(el)}
								>
									{t('common:edit')}
								</Button>
								<Button
									variant="contained"
									fullWidth={true}
									color="secondary"
									size="large"
									startIcon={<Delete />}
									onClick={() => onDelete(el)}
								>
									{t('common:delete')}
								</Button>
							</Box>
						</PaperContainer>
					);
				})}
			</Box>
		</Box>
	);
}
