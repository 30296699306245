import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
	ListItemText,
	TableHead,
	TableRow,
	TableCell,
	Button,
	Box,
	LinearProgress,
	Typography,
	SxProps,
	Theme,
} from '@mui/material';

import ResponsiveTable from 'components/ResponsiveTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import AsyncImage from 'components/AsyncImage';

import { rowsPerPageOptions } from 'config/constants';

import { enqueueSnackbarError } from 'lib/helpers';
import { FetchArtistProps, deleteArtist } from 'lib/models/artists';
import SearchToolbar from 'components/SearchToolbar';

import useArtists from 'hooks/useArtists';
import useArtist from 'hooks/useArtist';

type Props = {
	sx?: SxProps<Theme>;
	selected?: string[];
	onSelected?: (selected: Artist) => any;
};

export default function ArtistsTable({ onSelected, selected }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<null | Artist>(null);
	const [showDeleteWithLikesConfirmationModal, setShowDeleteWithLikesConfirmationModal] = useState<null | Artist>(null);

	const [search, setSearch] = useState('');
	const [page, setPage] = useState(Number(0));
	const [rowsPerPage, setRowsPerPage] = useState(Number(25));

	const searchProps = useMemo(() => {
		let searchParams: FetchArtistProps = { page, rowsPerPage, search };
		return searchParams;
	}, [page, rowsPerPage, search]);

	const { artist: selectedArtist, reset } = useArtist(selected ? selected[selected.length - 1] : '');

	const { artists, loading, refetch } = useArtists(searchProps);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function onSearch(value: string) {
		setSearch(value);
	}

	function onEdit(selected: Artist) {
		const { _id } = selected;
		navigate(`/artists/${_id}`);
	}

	function onDelete(artist: Artist) {
		setShowDeleteConfirmationModal(artist);
	}

	async function onDeleteHandler(mustDelete: boolean) {
		if ((showDeleteConfirmationModal?.likes ?? 0) > 0) {
			setShowDeleteWithLikesConfirmationModal(showDeleteConfirmationModal);
		} else {
			if (mustDelete && showDeleteConfirmationModal?._id) {
				const deleted = await deleteArtist(showDeleteConfirmationModal?._id);
				if (!deleted) {
					enqueueSnackbarError(t('common:deletionError'));
				}
				refetch();
			}
		}
		if (showDeleteConfirmationModal) {
			setShowDeleteConfirmationModal(null);
		} else {
			setShowDeleteWithLikesConfirmationModal(null);
		}
	}

	useEffect(() => {
		if (!loading && selectedArtist && !selected) {
			reset();
		}
	}, [loading, reset, selected, selectedArtist]);

	useEffect(() => {
		if (!loading && selectedArtist) {
			//setSearch(selectedArtist.title);
		}
	}, [selectedArtist, loading]);

	return (
		<Box
			sx={{
				padding: onSelected ? 1 : 'unset',
				borderRadius: onSelected ? 2 : 'unset',
				borderStyle: onSelected ? 'solid' : 'unset',
				borderWidth: onSelected ? '1px' : 'unset',
				borderColor: onSelected ? 'rgba(0, 0, 0, 0.23)' : 'unset',
				mt: onSelected ? 2 : 'unset',
			}}
		>
			{onSelected && (
				<Typography
					sx={{
						color: 'rgba(0, 0, 0, 0.6)',
						fontWeight: 400,
						fontSize: '1rem',
						lineHeight: '1.4375em',
						letterSpacing: '0.00938em',
						pl: 1,
					}}
				>
					{t('common:selectArtist')}
				</Typography>
			)}

			{/*!selectedArtist && <SearchToolbar onSearch={onSearch} initialValue={search} />*/}
			<SearchToolbar onSearch={onSearch} initialValue={search} />
			<Box sx={{ maxHeight: '50vh' }}>
				{!!loading && <LinearProgress />}

				{(showDeleteConfirmationModal || showDeleteWithLikesConfirmationModal) && (
					<ConfirmationDialog
						title={t('common:deleteTitle')}
						description={t('common:deleteText')}
						warningMessage={showDeleteWithLikesConfirmationModal ? t('common:deleteWithLikesWarning') : undefined}
						onClose={onDeleteHandler}
					/>
				)}

				{
					<ResponsiveTable
						elements={artists.elements}
						listSx={{ maxHeight: '50vh' }}
						tableSx={{ maxHeight: '50vh' }}
						list={{
							primaryKey: '_id',
							renderAvatar: (artist) => {
								return <AsyncImage id={artist.image} style={{ width: '80px', borderRadius: 8 }} />;
							},
							renderListItemText: (artist) => {
								return (
									<ListItemText
										primary={
											<Box>
												<Typography>{artist.title}</Typography>
											</Box>
										}
										secondary={
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
													{onSelected && (
														<Button onClick={() => onSelected(artist)} variant="outlined">
															{t(
																selected && artist._id && selected.includes(artist._id) ? 'common:remove' : 'common:add'
															)}
														</Button>
													)}
													{!onSelected && (
														<Button onClick={() => onEdit(artist)} variant="outlined">
															{t(`common:edit`)}
														</Button>
													)}
													{!onSelected && (
														<Button onClick={() => onDelete(artist)} variant="outlined" color="error">
															{t(`common:delete`)}
														</Button>
													)}
												</Box>
											</Box>
										}
									/>
								);
							},
						}}
						table={{
							renderHead: () => (
								<TableHead>
									<TableRow>
										<TableCell align="left" padding="normal">
											{t('common:image')}
										</TableCell>
										<TableCell align="left" padding="normal">
											{t('common:title')}
										</TableCell>
										<TableCell align="left" padding="normal">
											{t('common:actions')}
										</TableCell>
									</TableRow>
								</TableHead>
							),
							renderRow: (artist) => {
								return (
									<TableRow tabIndex={-1} key={artist._id}>
										<TableCell>
											<Box
												style={{
													width: '80px',
													height: artist.image ? 'unset' : '60px',
													borderRadius: 8,
													backgroundColor: artist.image ? 'unset' : 'lightgray',
												}}
											>
												<AsyncImage id={artist.image} style={{ width: '80px', borderRadius: 8 }} />
											</Box>
										</TableCell>
										<TableCell>{artist.title}</TableCell>
										<TableCell>
											<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
												{onSelected && (
													<Button onClick={() => onSelected(artist)} variant="outlined">
														{t(
															selected && artist._id && selected.includes(artist._id) ? 'common:remove' : 'common:add'
														)}
													</Button>
												)}
												{!onSelected && (
													<Button onClick={() => onEdit(artist)} variant="outlined">
														{t(`common:edit`)}
													</Button>
												)}
												{!onSelected && (
													<Button onClick={() => onDelete(artist)} variant="outlined" color="error">
														{t(`common:delete`)}
													</Button>
												)}
											</Box>
										</TableCell>
									</TableRow>
								);
							},
							pagination: {
								count: artists.total,
								page,
								rowsPerPage,
								rowsPerPageOptions,
								onPageChange: handleChangePage,
								onRowsPerPageChange: handleChangeRowsPerPage,
							},
						}}
					/>
				}
			</Box>
		</Box>
	);
}
