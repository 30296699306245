const vehicles = {
	deleteTitle: 'Eliminación de vehículo',
	deleteText: '¿Está seguro que desea eliminar el vehículo seleccionado?',
	deleteText_plural: '¿Está seguro que desea eliminar los vehículos seleccionados?',
	createVehicle: 'Crear vehículo',
	vehicleCreationSuccessTitle: 'Vehículo creado',
	vehicleCreationSuccessDescription: 'El vehículo "{{first_name}} {{last_name}}" fué creado con éxitoF.',
};

export default vehicles;
