import { ChangeEvent, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
	LinearProgress,
} from '@mui/material';
import Button from '@mui/material/Button';

import { updatePlace } from 'lib/models/places';
import { getDefaultState, reducer } from './reducer';
import { defaultLanguage } from 'lib/zodHelpers';

type Props = {
	open: boolean;
	onClose: (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => any;
	selected?: Place;
	language?: Language;
};

function PlaceModal({ open, onClose, selected, language = defaultLanguage }: Props) {
	const { t } = useTranslation();

	const defaultValues = getDefaultState(selected);

	const [{ loading, ...place }, dispatch] = useReducer(reducer, defaultValues);

	const name = place.name[language] || place.name[defaultLanguage];

	const valid = place.code.length > 0 && name.length > 0 && place.address.length > 0;

	async function save() {
		dispatch({ type: 'SetLoading', payload: true });
		await updatePlace({ _id: defaultValues._id, ...place });
		dispatch({ type: 'SetLoading', payload: false });
		onClose();
	}

	function getChangeHandler(type: 'name' | 'address') {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			dispatch({ type, payload: { ev, language } });
		};
	}

	function onMapChange(ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		const url = extractIframeUrl(ev.target.value);
		dispatch({ type: 'mapsLink', payload: { url, language } });
	}

	return (
		<Dialog open={open} onClose={onClose}>
			{loading && <LinearProgress />}

			<DialogTitle>{t('common:createPlace')}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					variant="outlined"
					label={t('common:name')}
					type="text"
					fullWidth
					value={name}
					onChange={getChangeHandler('name')}
					disabled={loading}
				/>

				<TextField
					autoFocus
					margin="dense"
					id="address"
					variant="outlined"
					label={t('common:address')}
					type="text"
					fullWidth
					value={place.address}
					onChange={getChangeHandler('address')}
					disabled={loading}
				/>

				<TextField
					autoFocus
					margin="dense"
					id="mapsLink"
					variant="outlined"
					label={t('common:mapsLink')}
					type="text"
					fullWidth
					value={place.mapsLink}
					onChange={onMapChange}
					disabled={loading}
				/>

				<Typography variant="body2">{t('common:mapsLinkLabel')}</Typography>

				<iframe src={place.mapsLink || ''} title="Google Maps" style={{ borderRadius: '8px' }} />
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="error">
					{t('common:cancel')}
				</Button>
				<Button onClick={() => save()} color="primary" disabled={!valid || loading}>
					{t('common:save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default PlaceModal;

function extractIframeUrl(iframeString: string): string {
	const iframeRegex = /<iframe[^>]+src=["']([^"']+)["']/;
	const match = iframeString.match(iframeRegex);

	if (match && match[1]) {
		return match[1];
	}

	return iframeString;
}
