import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { id: string } = {
	id: '',
};

const sponsorStateSlice = createSlice({
	name: 'dailyCodesState',
	initialState,
	reducers: {
		setSponsor: (state, action: PayloadAction<string>) => {
			state.id = action.payload;
		},
	},
});

export const { setSponsor } = sponsorStateSlice.actions;

export default sponsorStateSlice.reducer;
