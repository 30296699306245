import { FetchEventProps, fetchEvents } from 'lib/models/events';
import { useCallback, useEffect, useState } from 'react';

function useEvents(props: FetchEventProps) {
	const [loading, setLoading] = useState<boolean>(true);
	const [events, setEvents] = useState<PaginateSource<PopulatedAppEvent>>({ total: 0, elements: [] });

	const refetch = useCallback(async () => {
		const response = await fetchEvents(props);
		setEvents(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, events, refetch, setLoading };
}

export default useEvents;
