import { useCallback, useState } from 'react';

import {
	Box,
	DialogActions,
	FormHelperText,
	IconButton,
	LinearProgress,
	Typography,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Theme,
	SxProps,
} from '@mui/material';

import { Close, CloudUploadRounded as ImportIcon } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

import UploadInput from 'components/UploadInput';

type UploadProps = {
	open?: boolean;
	onClose(uploaded: boolean): void;
	upload: (file: File) => Promise<boolean>;
	id: string;
	name: string;
	accept?: string;
};

function Uploader({ open, onClose, id, name, accept = '.png,.jpg,.jpeg', upload }: UploadProps) {
	const { t } = useTranslation();

	const [file, setFile] = useState<File | null>(null);
	const [error, setError] = useState(false);
	const [uploading, setUploading] = useState(false);

	const reset = useCallback(() => {
		setFile(null);
		setError(false);
		setUploading(false);
	}, []);

	function close(uploaded: boolean = false) {
		reset();
		onClose(uploaded);
	}

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		const { files } = event.target;

		if (files && files[0] && files[0].name) {
			const validExtensions = accept.split(',');

			const fileExtension = files[0].name.split('.').pop();

			if (validExtensions.some((ext) => ext.trim() === `.${fileExtension}`)) {
				setFile(files[0]);
				setError(false);
				setUploading(false);
			} else {
				setError(true);
				setUploading(false);
			}
		}
	}

	async function onAccept() {
		if (file) {
			try {
				setUploading(true);
				const importRes = await upload(file);
				if (!importRes) {
					setError(true);
					setUploading(false);
				} else {
					close(true);
				}
			} catch (err) {
				const error = err as unknown as any;
				console.warn(error);
				setError(true);
				setUploading(false);
			}
		}
	}

	return (
		<Dialog open={open || false} onClose={() => close()} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t('common:upload')}</DialogTitle>

			<DialogContent>
				{uploading && <LinearProgress variant={'indeterminate'} />}

				<Box>
					<DialogContentText>{t('common:selectFile')}</DialogContentText>
					<UploadInput
						id={id}
						name={name}
						accept={accept}
						fileName={file?.name || ''}
						onChange={onChange}
						disabled={uploading || !!error}
						actionDisabled={uploading}
					/>

					{!!file && (
						<img
							src={URL.createObjectURL(file)}
							alt="Preview"
							style={{
								maxHeight: '250px',
								borderRadius: 5,
								borderWidth: 1,
								borderColor: 'lightgray',
								borderStyle: 'solid',
							}}
						/>
					)}
					{!error && uploading && <Typography variant="h6">{t('common:uploadingPleaseWait')}</Typography>}
					{!!error && <FormHelperText sx={{ ...sx, color: 'error.main' }}>{t('common:uploadingError')}</FormHelperText>}
				</Box>
			</DialogContent>

			<DialogActions>
				<IconButton onClick={() => close()} disabled={uploading}>
					<Close fontSize="large" />
				</IconButton>

				{!error && (
					<IconButton onClick={onAccept} disabled={!file || uploading}>
						<ImportIcon fontSize="large" />
					</IconButton>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default Uploader;

const sx: SxProps<Theme> = {
	alignItems: 'center',
	display: 'flex',
	color: 'error.main',
	fontFamily: (theme) => theme.typography.fontFamily,
	fontSize: (theme) => theme.typography.fontSize,
	marginBottom: 1,
};
