import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { enqueueSnackbarSuccess } from 'lib/helpers';

function useSuccessSnackbar() {
	const { t } = useTranslation();

	const toast = useCallback(() => {
		enqueueSnackbarSuccess(t('common:success'));
	}, [t]);

	return toast;
}

export default useSuccessSnackbar;
