import { fetchMerchCategories } from 'lib/models/merchCategories';
import { useCallback, useEffect, useState } from 'react';

function useMerchCategories() {
	const [loading, setLoading] = useState<boolean>(false);

	const [merchCategories, setMerchCategories] = useState<AppMerchCategory[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchMerchCategories();
		setMerchCategories(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, merchCategories, refetch };
}

export default useMerchCategories;
