import { configureStore } from '@reduxjs/toolkit';

import drawer from './reducers/drawer';
import session from './reducers/session';
import dailyCodes from './reducers/dailyCodes';
import sponsor from './reducers/sponsor';

const Store = configureStore({
	reducer: {
		drawer,
		session,
		dailyCodes,
		sponsor,
	},
});

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;

export default Store;
