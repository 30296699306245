const merch = {
	merchandising: 'Productos',
	errorHasItems: 'Error al eliminar: esta categoría tiene items cargados',
	deletionError: 'Error al eliminar',
	merchCategories: 'Categorías prod.',
	updateError: 'Error al actualizar producto',
	activeTitle: 'Publicar producto',
	activeDescription: '¿Está seguro que desea publicar el producto seleccionado?',
	activeError: 'El producto seleccionado no pudo ser publicado',
	draftTitle: 'Despublicar producto',
	draftDescription: '¿Está seguro que desea despublicar el producto seleccionado?',
	draftError: 'El producto seleccionado no pudo ser despublicar',
} as const;

export default merch;
