const access = {
	access: 'Access',
	tent: 'Tent',
	night: 'Night',
	day: 'Day',
	quantityPerDay: 'Quantity per day',
	scan_code: 'Scan code',
	generate_code: 'Generate code',
	print: 'Print',
	tent_number: 'Tent number',
	start_date: 'Start date',
	end_date: 'End date',
	generate: 'Generate',
	manually_enter: 'Manually enter',
	code: 'Code',
	type: 'Type',
	stay: 'Stay',
	plate: 'Plate',
	enter_code: 'Enter code',
	enter_plate: 'Enter plate',
	plate_number: 'Plate number',
	invalid_code_or_plate: 'Inexistent code or unknown plate',
	associated_code: 'Code associated with plate:',
	associated_code_with_plate: 'Code {{code}} associated with plate: {{plate}}',
	scan_entry: 'Scan entry',
	scan_exit: 'Scan exit',
	last_entry: 'Last entry',
	last_exit: 'Last exit',
	unregistered_plate: 'Unregistered License Plate',
	accessTitle: 'Access: {{code}}',
	accessDetailTitle: 'Plate {{plate}}',
	entry: 'Entry',
	entries: 'Entries',
	exit: 'Exit',
	exits: 'Exits',
	entrySuccess: 'Entry registered',
	exitSuccess: 'Exit registered',
	exitError: 'Error during exit registration',
	outOfDate: 'Out of date',
	assigned: 'Assigned',
	unassigned: 'Unassigned',
	accessWithoutPrevEntry: 'Vehicle without previous entry',
	enterPlateLater: 'You can continue and enter plate later',
	addPlate: 'Add plate',
	enable: 'Enable',
	disable: 'Disable',
	noEnqueuedScans: 'There are no scans in queue',
	savedInLocalStorage: 'Saved in local storage',
	offlineScanning: 'Offline scanning',
	assignPlate: 'Assign plate',
	optional: 'Optional',
	synchronizedAccess: 'Synchronized access',
	sync: 'Synchronize',
	errors: 'Errors',
	all: 'All',
	from: 'From',
	to: 'To',
	changeSponsor: 'Change sponsor',
	errorOnPDF: 'Error generating PDF',
	updatedAccess: 'Updated Access',
	errorOnUpdate: 'Error on update access',
};

export default access;
