import { API_DOMAIN, PLACE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { zInternationalization } from 'lib/zodHelpers';
import { z } from 'zod';

export const zLocation = z.object({
	longitude: z.number(),
	latitude: z.number(),
});

export const zPlace = z.object({
	_id: z.optional(z.string()),
	code: z.string(),
	name: zInternationalization,
	address: z.string(),
	mapsLink: z.optional(z.string()),
	location: z.optional(zLocation),
});

export async function fetchPlaces(): Promise<Place[]> {
	try {
		const response = await api.get(`${API_DOMAIN}${PLACE_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zPlace).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchPlace(id: string): Promise<Place | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${PLACE_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPlace.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updatePlace(place: Place): Promise<Place | null> {
	if (!place._id) {
		delete place._id;
	}

	const url = place._id ? `${API_DOMAIN}${PLACE_URL}/${place._id}` : `${API_DOMAIN}${PLACE_URL}`;

	const response = await (place._id ? api.patch(url, place) : api.post(url, place));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zPlace.safeParse(response.data);

	if (data.success) {
		return data.data;
	}

	console.error(data.error);

	return null;
}

export async function deletePlace(id: string): Promise<boolean> {
	try {
		const response = await api.delete(`${API_DOMAIN}${PLACE_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data.success;
	} catch (error) {
		console.error(error);
		return false;
	}
}
