import { Box } from '@mui/material';
import MerchCategoriesList from 'components/MerchCategoriesList';
import MerchCategoryModal from 'components/MerchCategoryModal.tsx';
import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useMerchCategories from 'hooks/useMerchCategories';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function MerchCategories() {
	const defaultCategory: AppMerchCategory = {
		title: '',
		items: [],
	};
	const { t } = useTranslation();

	const title = t('merch:merchCategories');

	const { loading, merchCategories, refetch } = useMerchCategories();

	const [showModal, setShowModal] = useState<AppMerchCategory | null>(null);

	const onEdit = (merchCategory: AppMerchCategory) => {
		setShowModal(merchCategory);
	};

	const onClose = () => {
		refetch();
		setShowModal(null);
	};

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultCategory)} disabled={loading} />
			{showModal && <MerchCategoryModal defaultValue={showModal} onClose={onClose} refetch={refetch} />}
			<Box>
				<MerchCategoriesList onEdit={onEdit} refetch={refetch} data={merchCategories} loading={loading} />
			</Box>
		</ScreenContainer>
	);
}
