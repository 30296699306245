import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, LinearProgress, FormGroup, TextField, Button, Box } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Close, Edit, Save } from '@mui/icons-material';
import { createMerch, editImage, updateImage, updateMerch } from 'lib/models/merch';
import { enqueueSnackbarError } from 'lib/helpers';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import Uploader from 'components/Uploader';
import AsyncImage from 'components/AsyncImage';
import useMerchCategories from 'hooks/useMerchCategories';
import ColorInput from './ColorInput';
import SizesInput from './SizesInput';

type Props = {
	defaultValue: AppMerchandising;
	onClose: () => void;
};

interface CategoryOptionType {
	inputValue?: string;
	title: string;
}

const filter = createFilterOptions<CategoryOptionType>();

export default function MerchandisingModal({ defaultValue, onClose }: Props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [merch, setMerch] = useState<AppMerchandising>(defaultValue);

	const [showUploader, setShowUploader] = useState<false | string>(false);

	const { merchCategories } = useMerchCategories();

	const options: CategoryOptionType[] = merchCategories.map((c) => {
		return { title: c.title, inputValue: c.title };
	});

	const [category, setCategory] = useState<CategoryOptionType | null>(null);

	const successSnackbar = useSuccessSnackbar();

	useEffect(() => {
		const options = merchCategories.map((c) => {
			return { title: c.title, inputValue: c.title };
		});

		const current = options.find((o) => o.title === defaultValue.category);

		if (current) setCategory(current);
	}, [merchCategories, defaultValue]);

	const canSave = !!merch.price && !!merch.title && !!category;

	const onUpdate = (key: keyof AppMerchandising) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setMerch({ ...merch, [key]: value });
		};
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps

	const save = async () => {
		if (!category || !category.inputValue) return;

		const merchData = { ...merch, category: category.inputValue };

		setLoading(true);
		try {
			if (merch._id) await updateMerch(merchData);
			else await createMerch(merchData);

			setLoading(false);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		onClose();
		setLoading(false);
	};

	async function uploadImage(file: File): Promise<boolean> {
		if (merch._id) {
			let updatedMerch;

			if (showUploader === 'upload') {
				updatedMerch = await updateImage(merch._id, file);
			} else if (typeof showUploader === 'string') {
				updatedMerch = await editImage(merch._id, file, showUploader);
			}
			if (updatedMerch) {
				setMerch(updatedMerch);
				return true;
			}
		}
		return false;
	}

	const onCloseUploader = () => {
		setShowUploader(false);
	};

	return (
		<Dialog open onClose={onClose} fullWidth>
			{loading && <LinearProgress />}
			<DialogTitle>{t('merch:merchandising')}</DialogTitle>
			<DialogContent>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{merch && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={merch.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
							required={true}
						/>
						<TextField
							id="url"
							label={t('common:url')}
							fullWidth={true}
							value={merch.url}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('url')}
							disabled={loading}
							required={true}
						/>
						<TextField
							id="description"
							label={t('common:description')}
							fullWidth={true}
							value={merch.description}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('description')}
							disabled={loading}
						/>
						<TextField
							id="price"
							type="number"
							label={t('common:price')}
							fullWidth={true}
							value={merch.price}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('price')}
							disabled={loading}
						/>
						<Autocomplete
							id="category"
							value={category}
							options={options}
							sx={{ mb: 3 }}
							renderInput={(params) => <TextField {...params} label={t('common:category')} />}
							renderOption={(props, option) => <li {...props}>{option.title}</li>}
							onChange={(_, newValue) => {
								setCategory(newValue);
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								const { inputValue } = params;

								const isExisting = options.some((option) => inputValue === option.title);
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										title: `${t('common:create')} "${inputValue}"`,
									});
								}
								return filtered;
							}}
							getOptionLabel={(option) => {
								if (typeof option === 'string') {
									return option;
								}
								if (option.inputValue) {
									return option.inputValue;
								}
								return option.title;
							}}
						/>
						<ColorInput merch={merch} setMerch={setMerch} />
						<SizesInput
							merch={merch}
							onSizeSelected={(size) => {
								setMerch((current) => {
									const sizes = current.sizes || [];
									if (!sizes.includes(size)) {
										return { ...merch, sizes: [...sizes, size] };
									}
									return current;
								});
							}}
							onSizeRemoved={(size) => {
								setMerch((current) => {
									const sizes = current.sizes || [];
									const updatedSizes = sizes.filter((s) => s !== size);
									return { ...current, sizes: updatedSizes };
								});
							}}
						/>
						{showUploader && (
							<Uploader id="merch-image" name="merch-file" upload={uploadImage} onClose={onCloseUploader} open={true} />
						)}
						{merch._id && (
							<>
								{[...merch.images, null].map((image) => {
									return (
										<Box
											onClick={() => setShowUploader(image ?? 'upload')}
											sx={{
												mt: 2,
												mb: 1,
												width: '350px',
												borderRadius: 1,
												borderWidth: 1,
												borderStyle: 'solid',
												borderColor: 'gray',
												padding: 1,
												cursor: 'pointer',
												':hover': {
													backgroundColor: 'lightgray',
												},
												position: 'relative',
											}}
										>
											{image ? (
												<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
											) : (
												<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
											)}

											{image ? (
												<AsyncImage id={image} style={{ width: '100%' }} />
											) : (
												<Box
													sx={{
														width: '100%',
														height: 100,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														backgroundColor: 'lightgray',
														marginTop: 2,
													}}
												/>
											)}
										</Box>
									);
								})}
							</>
						)}
						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!canSave}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}
