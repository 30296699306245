import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { fetchTags, updateTag } from 'lib/models/tags';
import { enqueueSnackbarError } from 'lib/helpers';

const filter = createFilterOptions<AutocompleteTag>();

type Props = {
	defaultValue?: string;
	onChange: (tag?: string) => any;
	disabled?: boolean;
};

function Tag({ onChange, disabled, defaultValue }: Props) {
	const { t } = useTranslation();

	const [value, setValue] = React.useState(defaultValue || '');
	const [isLoading, setIsLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState<readonly AutocompleteTag[]>([]);
	const loading = open && (options.length === 0 || isLoading);

	React.useEffect(() => {
		if (!loading && defaultValue) {
			setValue(defaultValue);
		}
	}, [defaultValue, loading]);

	React.useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			const tags = await fetchTags();
			if (active) {
				setOptions([...tags]);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<Autocomplete
			disabled={disabled}
			fullWidth={true}
			value={value}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			onChange={(_, newValue) => {
				if (typeof newValue === 'string') {
					return setValue(newValue);
				}
				if (newValue && newValue.inputValue) {
					// Create a new value from the user input
					return setValue(newValue.inputValue);
				}
				if (newValue?.text) {
					onChange(newValue.text);
					return setValue(newValue.text);
				}
				if (newValue === null) {
					onChange('');
				}
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				const { inputValue } = params;

				// Suggest the creation of a new value
				const isExisting = options.some((option) => {
					return inputValue === option.text;
				});
				if (inputValue !== '' && !isExisting) {
					filtered.push({
						inputValue,
						text: `${t('common:add')} ${inputValue}`,
					});
				}

				return filtered;
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			id="tags-autocomplete"
			options={options}
			getOptionLabel={(option) => {
				if (typeof option === 'string') {
					return option;
				}
				// Add "xxx" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
				// Regular option
				return option.text;
			}}
			renderOption={(props, option) => {
				return (
					<li
						{...props}
						onClick={async (ev) => {
							props.onClick?.(ev);
							// User wants to create a new tag
							if (option.text.includes(t('common:add')) && !!option.inputValue) {
								setIsLoading(true);
								const createdTag = await updateTag({ text: option.inputValue });
								if (!createdTag) {
									enqueueSnackbarError(t('common:tagCreationError'));
									setIsLoading(false);
									return;
								}
								onChange(option.inputValue);
							}
							setIsLoading(false);
						}}
					>
						{option.text}
					</li>
				);
			}}
			freeSolo
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('common:tag')}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
}

export default Tag;

type AutocompleteTag = Tag & { inputValue?: string };
