import { useEffect, useState } from 'react';

import { fetchAppConfig } from 'lib/models/appConfig';

function useAppConfig() {
	const [loading, setLoading] = useState<boolean>(true);

	const [appConfig, setAppConfig] = useState<AppConfig>({
		conditions: { es: '' },
		policy: { es: '' },
		privacy: { es: '' },
		deletion: { es: '' },
	});

	useEffect(() => {
		async function getAppConfig() {
			const response = await fetchAppConfig();
			setAppConfig(response);

			setLoading(false);
		}

		getAppConfig();
	}, []);

	return { loading, appConfig };
}

export default useAppConfig;
