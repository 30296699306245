import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import ArtistEditionForm from 'components/ArtistEditionForm';

import { enqueueSnackbarError } from 'lib/helpers';

import useArtist from 'hooks/useArtist';

function ArtistEdition() {
	const { t } = useTranslation();

	const { id } = useParams<{ id: string }>();

	const navigate = useNavigate();

	const newArtist = !!id && id === 'new';

	const artistData = useArtist();

	const title =
		artistData.loading || artistData.error
			? t('common:loading')
			: newArtist
			? t('common:createArtist')
			: t('common:artistTitle', { title: artistData.artist?.title });

	useEffect(() => {
		if (artistData.error) {
			enqueueSnackbarError(artistData.error);
			navigate('/artists');
		}
	}, [artistData.error, navigate]);

	return (
		<ScreenContainer title={title} goBack={true}>
			<PaperContainer>
				<ArtistEditionForm
					artistLoading={artistData.loading}
					id={id || (newArtist ? 'new' : '')}
					initialize={artistData.artist}
					isModal={false}
				/>
			</PaperContainer>
		</ScreenContainer>
	);
}

export default ArtistEdition;
