const vehicles = {
	deleteTitle: 'Vehicle deletion',
	deleteText: 'Delete selected vehicle?',
	deleteText_plural: 'Delete selected vehicles?',
	createVehicle: 'Create vehicle',
	vehicleCreationSuccessTitle: 'Vehicle created',
	vehicleCreationSuccessDescription: 'The "{{first_name}} {{last_name}}" vehicle was successfully created.',
};

export default vehicles;
