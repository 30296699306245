import { useTranslation } from 'react-i18next';

import { Button, Box } from '@mui/material';
import { ArrowDownward, ArrowUpward, Visibility, VisibilityOff } from '@mui/icons-material';

type Props = {
	event: PopulatedAppEvent;
	onPublish: (ev: PopulatedAppEvent) => any;
	onUnPublish: (ev: PopulatedAppEvent) => any;
	onCancelEvent: (ev: PopulatedAppEvent) => any;
	onEdit: (ev: PopulatedAppEvent) => any;
	onDelete: (ev: PopulatedAppEvent) => any;
	decreasePosition: (ev: PopulatedAppEvent) => any;
	increasePosition: (ev: PopulatedAppEvent) => any;
	setFeatured: (ev: PopulatedAppEvent) => any;
	unsetFeatured: (ev: PopulatedAppEvent) => any;
	total: number;
	firstFeaturedPosition: number;
};

export default function Actions({
	event,
	onPublish,
	onUnPublish,
	onCancelEvent,
	onEdit,
	onDelete,
	decreasePosition,
	increasePosition,
	setFeatured,
	unsetFeatured,
	total,
	firstFeaturedPosition,
}: Props) {
	const { t } = useTranslation();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
				{(event.status === 'draft' || event.status === 'canceled') && (
					<Button onClick={() => onPublish(event)} variant="outlined">
						{t(`common:publish`)}
					</Button>
				)}

				{(event.status === 'active' || event.status === 'canceled') && !event.featuredPosition && (
					<Button onClick={() => onUnPublish(event)} variant="outlined" color="error">
						{t(`common:unpublish`)}
					</Button>
				)}

				{event.status === 'active' && !event.featuredPosition && (
					<Button onClick={() => onCancelEvent(event)} variant="outlined" color="error">
						{t(`common:cancel`)}
					</Button>
				)}

				<Button onClick={() => onEdit(event)} variant="outlined">
					{t(event.status === 'draft' ? `common:edit` : `common:edit`)}
				</Button>

				{event.status === 'draft' && (
					<Button onClick={() => onDelete(event)} variant="outlined" color="error">
						{t(`common:delete`)}
					</Button>
				)}
			</Box>
			{event.status === 'active' && (
				<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
					{event.featuredPosition && (
						<Button
							disabled={event.featuredPosition === firstFeaturedPosition}
							onClick={() => decreasePosition(event)}
							variant="text"
							endIcon={<ArrowUpward />}
						>
							{t(`common:up`)}
						</Button>
					)}
					{event.featuredPosition && (
						<Button
							disabled={event.featuredPosition === total}
							onClick={() => increasePosition(event)}
							variant="text"
							color="secondary"
							endIcon={<ArrowDownward />}
						>
							{t(`common:down`)}
						</Button>
					)}

					{!event.featuredPosition && (
						<Button onClick={() => setFeatured(event)} variant="text" color="secondary" endIcon={<Visibility />}>
							{t(`common:setFeatured`)}
						</Button>
					)}
					{event.featuredPosition && (
						<Button onClick={() => unsetFeatured(event)} variant="text" color="secondary" endIcon={<VisibilityOff />}>
							{t(`common:unsetFeatured`)}
						</Button>
					)}
				</Box>
			)}
		</Box>
	);
}
