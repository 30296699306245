import { API_DOMAIN, FILE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

import { z } from 'zod';

export const zFile = z.object({
	_id: z.optional(z.string()),
	filename: z.string(),
	mimetype: z.string(),
	originalname: z.string(),
	size: z.number(),
	file: z.string(),
});

export async function fetchFile(id: string): Promise<AppFile | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${FILE_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zFile.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function fetchImage(id: string): Promise<string> {
	const file = await fetchFile(id);
	if (file) {
		return file.file;
	}
	return '';
}
