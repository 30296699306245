import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
	open?: boolean;
};

export default function ScanCode({ selected, onSelect, open }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/scan/entry"
			selected={selected === MenuItem.ScanCode}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.ScanCode)}
		>
			<ListItemIcon>
				<KeyboardDoubleArrowRightIcon />
			</ListItemIcon>
			{open && <ListItemText primary={t('access:scan_entry')} />}
		</ListItem>
	);
}
