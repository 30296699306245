import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: DailyCodes = {
	list: [],
};

const drawerStateSlice = createSlice({
	name: 'dailyCodesState',
	initialState,
	reducers: {
		setDailyCodeList: (state, action: PayloadAction<Access[]>) => {
			state.list = action.payload;
		},
	},
});

export const { setDailyCodeList } = drawerStateSlice.actions;

export default drawerStateSlice.reducer;
