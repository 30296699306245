import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, FormGroup, LinearProgress, TextField, Box } from '@mui/material';
import { Save as SaveIcon, Close as CloseIcon, Edit, Add } from '@mui/icons-material';

import Uploader from 'components/Uploader';
import AsyncImage from 'components/AsyncImage';

import { enqueueSnackbarError } from 'lib/helpers';
import { updateArtist, updateImage } from 'lib/models/artists';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

type Props = {
	id: string;
	isModal: boolean;

	initialize?: Artist | null;
	artistLoading: boolean;
};

function ArtistEditionForm({ id, isModal, initialize, artistLoading }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const newArtist = !!id && id === 'new';

	const successSnackbar = useSuccessSnackbar();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [artist, setArtist] = useState<Artist>(getDefaultArtist());

	const [showUploader, setShowUploader] = useState(false);

	const canSave = !!artist.title;

	const loading = isLoading || artistLoading;

	useEffect(() => {
		if (!artistLoading) {
			if (!newArtist && initialize) {
				setArtist(initialize);
			}
			setLoading(false);
		}
	}, [artistLoading, initialize, newArtist]);

	const onUpdate = (key: keyof PopulatedAppEvent) => {
		return (event: any) => {
			if (event) {
				setArtist((current) => ({ ...current, [key]: event.target.value }));
			}
		};
	};

	async function save() {
		try {
			setLoading(true);
			await updateArtist({ ...artist });

			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		navigate(-1);
	}

	async function uploadImage(file: File): Promise<boolean> {
		if (artist._id) {
			const updatedArtist = await updateImage(artist._id, file);
			if (updatedArtist) {
				setArtist(updatedArtist);
				return true;
			}
		}
		return false;
	}

	function onUploaderClose(_uploaded: boolean) {
		setShowUploader(false);
	}

	return (
		<Box>
			{showUploader && (
				<Uploader id="artist-image" name="artist-file" upload={uploadImage} onClose={onUploaderClose} open={true} />
			)}

			{isModal && (
				<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1, justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<CloseIcon />}
						onClick={() => {
							navigate(-1);
						}}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<TextField
					id="title"
					label={t('common:title')}
					fullWidth={true}
					value={artist.title ?? ''}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('title')}
					disabled={loading}
				/>

				{!!artist._id && (
					<Box
						onClick={() => setShowUploader(true)}
						sx={{
							mt: 2,
							mb: 1,
							width: '350px',
							borderRadius: 1,
							borderWidth: 1,
							borderStyle: 'solid',
							borderColor: 'gray',
							padding: 1,
							cursor: 'pointer',
							':hover': {
								backgroundColor: 'lightgray',
							},
							position: 'relative',
						}}
					>
						{artist.image ? (
							<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
						) : (
							<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
						)}

						{artist.image ? (
							<AsyncImage id={artist.image} style={{ width: '100%' }} />
						) : (
							<Box
								sx={{
									width: '100%',
									height: 150,
									borderRadius: 5,
									borderWidth: 1,
									borderStyle: 'solid',
									borderColor: 'gray',
									backgroundColor: 'lightgray',
									marginTop: 2,
								}}
							/>
						)}
					</Box>
				)}

				<Button
					variant="contained"
					color="primary"
					size="large"
					sx={{ m: 1 }}
					startIcon={<SaveIcon />}
					onClick={save}
					disabled={!canSave}
				>
					{t('common:save')}
				</Button>
			</FormGroup>
		</Box>
	);
}

export default ArtistEditionForm;

function getDefaultArtist(): Artist {
	return {
		_id: '',
		title: '',
	};
}
