import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CssBaseline, Box, PortalProps } from '@mui/material';

import Body from './Body';
import Navigator from './Navigator/Navigator';
import Toolbar from './Toolbar';

import useDrawer from 'components/Drawer/hooks/useDrawer';
import useDrawerActions from 'components/Drawer/hooks/useDrawerActions';

import { APPBAR_MAX_HEIGHT } from 'config/constants';
import spacing from 'lib/spacing';
import SyncButton from 'components/SyncButton/SyncButton';

type MiniDrawerProps = {
	children: React.ReactElement;
	container?: PortalProps['container'];
	title: string;
	goBack?: boolean;
};

function MiniDrawer({ container, children, title, goBack }: MiniDrawerProps) {
	const { classes } = useStyles();

	const { open, selectedItem } = useDrawer();
	const { handleDrawerClose, handleDrawerOpen } = useDrawerActions();

	return (
		<Box sx={{ display: 'flex', isolation: 'isolate' }}>
			<SyncButton />

			<CssBaseline />
			<Toolbar open={open} onOpen={handleDrawerOpen} title={title} goBack={goBack} />
			<Navigator open={open} selected={selectedItem} onClose={handleDrawerClose} container={container} />
			<Body>
				<Box className={classes.children}>{children}</Box>
			</Body>
		</Box>
	);
}

const useStyles = makeStyles()((theme) => ({
	children: {
		marginTop: `${spacing(theme, 2) + APPBAR_MAX_HEIGHT}px`,
	},
}));

export default MiniDrawer;
