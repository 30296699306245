import { MenuItem, Select, SxProps, Theme } from '@mui/material';
import { appEventStatus } from 'lib/models/events';

import { useTranslation } from 'react-i18next';

type Props = {
	selected?: string;
	onSelected: (status: AppEventStatus) => any;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	fullWidth?: boolean;
};

function StatusSelector({ selected = '', sx, disabled, onSelected, fullWidth = true }: Props) {
	const { t } = useTranslation();

	function onChange(el: AppEventStatus) {
		onSelected(el);
	}

	return (
		<Select
			labelId="place-selector-label"
			id="place-selector"
			label={t('common:status')}
			fullWidth={fullWidth}
			value={selected}
			variant="outlined"
			sx={sx}
			onChange={(ev) => onChange(ev.target.value as AppEventStatus)}
			disabled={disabled}
		>
			{appEventStatus.map((el) => {
				return (
					<MenuItem key={`status_${el}`} value={el}>
						{t(`common:${el === 'active' ? 'published' : el}`)}
					</MenuItem>
				);
			})}
		</Select>
	);
}

export default StatusSelector;
