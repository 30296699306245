import { useTranslation } from 'react-i18next';

import { LinearProgress } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import usePlaces from 'hooks/usePlaces';
import Places from 'components/Places';
import { useState } from 'react';
import PlaceModal from 'components/PlaceModal';

function PlacesScreen() {
	const { t } = useTranslation();

	const { loading, places, refetch, setLoading } = usePlaces();

	const [showModal, setShowModal] = useState<Place | true | null | undefined>(null);

	function onClose() {
		refetch();
		setShowModal(null);
	}

	return (
		<ScreenContainer title={t('common:place_plural')}>
			<CreateIcon onCreate={() => setShowModal(true)} disabled={loading} />

			{!!loading && <LinearProgress />}

			<Places
				places={places}
				loading={loading}
				refetch={refetch}
				setLoading={setLoading}
				onEdit={(place) => setShowModal(place)}
			/>

			{showModal && (
				<PlaceModal
					onClose={onClose}
					open={!!showModal}
					selected={showModal && typeof showModal !== 'boolean' ? showModal : undefined}
				/>
			)}
		</ScreenContainer>
	);
}

export default PlacesScreen;
