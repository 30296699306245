import {
	Box,
	List as MUIList,
	ListItem,
	ListItemSecondaryAction,
	Checkbox,
	ListItemAvatar,
	Avatar,
	Button,
	Divider,
	SxProps,
	Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Waypoint } from 'react-waypoint';

export type ListProps<T> = {
	primaryKey: keyof T;
	renderAvatar?: (element: T) => React.ReactElement;
	renderListItemText: (element: T) => React.ReactElement;
	avatar?: React.ReactChild | null;
	infiniteScroll?: {
		endOfList: boolean;
		onEndReached: () => any;
	} | null;
	selected?: {
		elements: T[];
		handleListClick: (element: T) => any;
	} | null;
};

type Props<T> = ListProps<T> & {
	loading?: boolean;
	elements: T[];
	sx?: SxProps<Theme>;
};

function List<T>({
	elements,
	primaryKey: key,
	loading = false,
	selected,
	infiniteScroll,
	avatar,
	renderAvatar,
	renderListItemText,
	sx,
}: Props<T>) {
	const { t } = useTranslation();

	const { elements: selectedElements, handleListClick: onClick } = selected || {
		elements: [],
		handleListClick: null,
		primaryKey: '' as keyof T,
	};
	const { endOfList, onEndReached } = infiniteScroll || { endOfList: true, onEndReached: null };

	return (
		<Box sx={{ overflow: 'auto', ...(sx || {}) }}>
			<MUIList>
				{elements.map((element) => {
					const itemKey = `table-${element[key]}`;
					return (
						<Box>
							<ListItem key={itemKey} button>
								{!!avatar && (
									<ListItemAvatar>
										<Avatar>{avatar}</Avatar>
									</ListItemAvatar>
								)}
								{renderAvatar && (
									<ListItemAvatar>
										<Avatar>{renderAvatar(element)}</Avatar>
									</ListItemAvatar>
								)}
								{renderListItemText(element)}
								<ListItemSecondaryAction>
									{!!onClick && (
										<Checkbox
											edge="end"
											onChange={() => onClick(element)}
											checked={selectedElements.find((el) => el[key] === element[key]) ? true : false}
										/>
									)}
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
						</Box>
					);
				})}
				{!!onEndReached && !endOfList && <Waypoint onEnter={onEndReached} />}
				{!!onEndReached && !loading && !endOfList && (
					<ListItem button>
						<ListItemSecondaryAction>
							<Button onClick={onEndReached}>{t('common:more')}</Button>
						</ListItemSecondaryAction>
					</ListItem>
				)}
			</MUIList>
		</Box>
	);
}

export default List;
