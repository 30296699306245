const merchandising = {
	merchandising: 'Merchandising',
	errorHasItems: 'Error on deletion: this category has charged items',
	deletionError: 'Error on deletion',
	updateError: 'Error on update product',
	merchCategories: 'Merch categories',
	activeTitle: 'Publish product',
	activeDescription: "Are you sure you wan't to publish the selected product?",
	activeError: 'The selected product could not be published',
	draftTitle: 'Unpublish product',
	draftDescription: "Are you sure you wan't to unpublish the selected product?",
	draftError: 'The selected product could not be unpublished',
} as const;

export default merchandising;
