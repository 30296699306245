import { makeStyles } from 'tss-react/mui';
import { Fab } from '@mui/material';

import { APPBAR_MAX_HEIGHT } from 'config/constants';
import spacing from 'lib/spacing';

type Props = {
	onClick: () => any;
	title: string;
	icon: React.ElementType;
	disabled?: boolean;
};

function Float({ onClick, title, icon: Icon, disabled }: Props) {
	const { classes } = useStyles();

	return (
		<Fab
			className={classes.fab}
			size="medium"
			variant="extended"
			color="primary"
			aria-label="add"
			onClick={() => !disabled && onClick()}
			disabled={disabled}
		>
			<Icon className={classes.extendedIcon} />
			{title}
		</Fab>
	);
}

export default Float;

const useStyles = makeStyles()((theme) => ({
	fab: {
		position: 'fixed',
		[theme.breakpoints.up('sm')]: {
			top: `${APPBAR_MAX_HEIGHT - spacing(theme, 2)}px`,
			marginLeft: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
		transition: theme.transitions.create(['left'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		zIndex: theme.zIndex.drawer + 2,
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));
