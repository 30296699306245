import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, LinearProgress, TextField, Box } from '@mui/material';
import { Save as SaveIcon, Edit, Add } from '@mui/icons-material';

import Uploader from 'components/Uploader';
import AsyncImage from 'components/AsyncImage';

import { enqueueSnackbarError } from 'lib/helpers';
import { updateAppConfig, updateImage } from 'lib/models/appConfig';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useAppConfig from 'hooks/useAppConfig';

function AppConfigForm() {
	const { t } = useTranslation();

	const { loading: appConfigLoading, appConfig: defaultData } = useAppConfig();

	const successSnackbar = useSuccessSnackbar();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [appConfig, setAppConfig] = useState<AppConfig>({
		conditions: { es: '' },
		policy: { es: '' },
		privacy: { es: '' },
		deletion: { es: '' },
	});

	const [showUploader, setShowUploader] = useState(false);

	const canSave =
		!!appConfig.conditions.es ||
		!!appConfig.policy.es ||
		!!appConfig.privacy.es ||
		!!appConfig.deletion.es ||
		!!appConfig.map;

	const loading = isLoading || appConfigLoading;

	useEffect(() => {
		if (!appConfigLoading) {
			setAppConfig(defaultData);
		}
		setLoading(false);
	}, [appConfigLoading, defaultData]);

	const onUpdate = (key: keyof AppConfig) => {
		return (event: any) => {
			if (event) {
				setAppConfig((current) => ({ ...current, [key]: { es: event.target.value } }));
			}
		};
	};

	async function save() {
		try {
			setLoading(true);
			const data = await updateAppConfig({ ...appConfig });
			setAppConfig(data);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	async function uploadImage(file: File): Promise<boolean> {
		const updatedAppConfig = await updateImage(file);
		if (updatedAppConfig) {
			setAppConfig(updatedAppConfig);
			return true;
		}
		return false;
	}

	function onUploaderClose(_uploaded: boolean) {
		setShowUploader(false);
	}

	return (
		<Box>
			{showUploader && (
				<Uploader id="artist-image" name="artist-file" upload={uploadImage} onClose={onUploaderClose} open={true} />
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<TextField
					id="conditions"
					label={t('common:conditionsUrl')}
					fullWidth={true}
					value={appConfig.conditions.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('conditions')}
					disabled={loading}
				/>

				<TextField
					id="policy"
					label={t('common:policyUrl')}
					fullWidth={true}
					value={appConfig.policy.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('policy')}
					disabled={loading}
				/>

				<TextField
					id="privacy"
					label={t('common:privacyUrl')}
					fullWidth={true}
					value={appConfig.privacy.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('privacy')}
					disabled={loading}
				/>

				<TextField
					id="deletion"
					label={t('common:deletionUrl')}
					fullWidth={true}
					value={appConfig.deletion.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('deletion')}
					disabled={loading}
				/>

				<Box
					onClick={() => setShowUploader(true)}
					sx={{
						mt: 2,
						mb: 1,
						width: '350px',
						borderRadius: 1,
						borderWidth: 1,
						borderStyle: 'solid',
						borderColor: 'gray',
						padding: 1,
						cursor: 'pointer',
						':hover': {
							backgroundColor: 'lightgray',
						},
						position: 'relative',
					}}
				>
					{appConfig.map ? (
						<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
					) : (
						<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
					)}

					{appConfig.map ? (
						<AsyncImage id={appConfig.map} style={{ width: '100%' }} />
					) : (
						<Box
							sx={{
								width: '100%',
								height: 150,
								borderRadius: 5,
								borderWidth: 1,
								borderStyle: 'solid',
								borderColor: 'gray',
								backgroundColor: 'lightgray',
								marginTop: 2,
							}}
						/>
					)}
				</Box>

				<Button
					variant="contained"
					color="primary"
					size="large"
					sx={{ m: 1 }}
					startIcon={<SaveIcon />}
					onClick={save}
					disabled={!canSave}
				>
					{t('common:save')}
				</Button>
			</FormGroup>
		</Box>
	);
}

export default AppConfigForm;
