import { useTranslation } from 'react-i18next';

import { LinearProgress } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import useVehicles from 'hooks/useVehicles';
import { useNavigate } from 'react-router-dom';
import VehicleTable from 'components/VehicleTable';

function VehiclesScreen() {
	const { t } = useTranslation();

	const { loading, vehicles } = useVehicles();

	const navigate = useNavigate();

	return (
		<ScreenContainer title={t('common:parking_vehicles')}>
			<CreateIcon onCreate={() => navigate('/parking/vehicles/new')} disabled={loading} />

			{!!loading && <LinearProgress />}

			<VehicleTable vehicles={vehicles} />
		</ScreenContainer>
	);
}

export default VehiclesScreen;
