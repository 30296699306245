import { useEffect, useMemo, useState } from 'react';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';

import useAccessCode from './useAccessCode';
import { fetchAccessByCode } from 'lib/models/access';

type UseAccessResponseType =
	| {
			loading: false;
			access: Omit<Access, '_id'> & { _id: string };
			fromParams: boolean;
			error: false;
	  }
	| {
			loading: true;
			access: null;
			fromParams: boolean;
			error: false;
	  }
	| {
			loading: false;
			access: null;
			fromParams: boolean;
			error: true;
	  };

/**
 * Returns the users associated with the id received in parameter or the logged user
 */
function useAccessDetail() {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [access, setAccess] = useState<Access | null>();
	const { code, fromParams } = useAccessCode();
	const loggedUser = useLoggedUserDocument();

	useEffect(() => {
		async function getAccess() {
			if (code && code !== 'new' && fromParams) {
				const response = await fetchAccessByCode(code);
				if (response) {
					setAccess(response.access);
				} else {
					setError(true);
				}
			} else {
				setAccess({
					code: '',
					stay: '',
					type: '',
					movements: [],
					validDate: {},
					plate: [],
					vehiclesInside: 0,
					isActive: true,
				});
			}
			setLoading(false);
		}

		getAccess();
	}, [code, loggedUser, fromParams]);

	const data: UseAccessResponseType = useMemo(() => {
		const { _id = '' } = access || {};
		return !loading && !!access
			? {
					loading: false,
					error: false,
					access: { ...access, _id },
					fromParams,
			  }
			: error
			? { loading: false, error: true, access: null, fromParams }
			: { loading: true, error: false, access: null, fromParams };
	}, [access, loading, fromParams, error]);

	return data;
}

export default useAccessDetail;
