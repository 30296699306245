import { FetchAccessSearchProps, fetchAccess } from 'lib/models/access';
import { useCallback, useEffect, useState } from 'react';

function useAccess(props: FetchAccessSearchProps) {
	const [loading, setLoading] = useState<boolean>(true);
	const [access, setAccess] = useState<Access[]>([]);

	const refetch = useCallback(async () => {
		const response = await fetchAccess(props);

		setAccess(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, access, refetch, setLoading };
}

export default useAccess;
