import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	access: Access | undefined;
	onSave: (params: { code?: string; plate?: string }) => void;
	handleClose: () => void;
};

function SuccessfulEntry({ open, access, handleClose, onSave }: Props) {
	const { t } = useTranslation();

	const [newPlate, setNewPlate] = useState('');

	const handlePlate = (plate: string) => {
		return setNewPlate(plate);
	};
	return (
		<Dialog open={!!open}>
			<DialogTitle align="center">{t('access:associated_code')}</DialogTitle>
			<DialogContent sx={{ flexDirection: 'column', display: 'flex', gap: '1rem' }}>
				{access?.plate.map((p) => {
					return (
						<TextField
							disabled={true}
							autoFocus
							autoComplete="off"
							value={p}
							inputProps={{
								style: { textTransform: 'uppercase', textAlign: 'center' },
								maxLength: 8,
							}}
						/>
					);
				})}
				{!!access?.tent && access.stay === 'tent' && access.plate.length < 2 && (
					<TextField
						label={`${t('access:addPlate')} (${t('access:optional')})`}
						autoFocus
						autoComplete="off"
						value={newPlate}
						onChange={(e) => handlePlate(e.target.value.toUpperCase())}
						inputProps={{
							style: { textTransform: 'uppercase', textAlign: 'center' },
						}}
					/>
				)}
				<DialogContentText sx={{ paddingTop: '.5rem', textAlign: 'center' }}>{`${access && access.vehiclesInside} ${t(
					'common:of'
				)} ${access?.stay === 'tent' ? 2 : 1} ${t('access:entries')}`}</DialogContentText>
				<DialogContentText sx={{ paddingTop: '.5rem', textAlign: 'center' }}>
					{`${t('access:last_entry')}: ${lastMovements('entry', access?.movements)}`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="error">
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						if (access) {
							onSave({ code: access.code, plate: newPlate });
							handleClose();
						}
					}}
					color="primary"
				>
					{t('common:confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SuccessfulEntry;

function parseDate(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const parsedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year
		.toString()
		.slice(-2)} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
	return parsedDate;
}

function lastMovements(type: string, movements?: Movement[]) {
	if (!movements) return '';

	const filteredMovements: Movement[] = movements.filter((movement) => movement.type === type);

	const lastMovement = filteredMovements.length > 0 ? filteredMovements.at(-1) : null;

	return lastMovement ? parseDate(lastMovement.date) : '-';
}
