import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import ArtistsTable from 'components/ArtistsTable';

function Artists() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<ScreenContainer title={t('common:artist_plural')}>
			{<CreateIcon onCreate={() => navigate('/artists/new')} />}

			<ArtistsTable />
		</ScreenContainer>
	);
}

export default Artists;
