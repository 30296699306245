const common = {
	// Common
	username: 'Username',
	password: 'Password',
	firstName: 'First name',
	lastName: 'Last name',
	email: 'Email',
	accept: 'Accept',
	cancel: 'Cancel',
	close: 'Close',
	create: 'Create',
	delete: 'Delete',
	edit: 'Edit',
	details: 'Details',
	filter: 'Filter',
	add: 'Add',
	general: 'General',
	go: 'Go',
	open: 'Open',
	reload: 'Reload',
	save: 'Save',
	search: 'Search',
	watch: 'Watch',
	selected: 'selected',
	upload: 'Upload',
	success: 'Saved',
	download: 'Download',
	densePadding: 'Dense row',
	rowsPerPage: 'Rows per page',
	findUser: 'Find user ...',
	import: 'Import',
	importingPleaseWait: 'Please wait, importing...',
	more: 'More',
	code: 'Code',
	loading: 'Loading, please wait...',
	role: 'Role',
	role_admin: 'Administrator',
	role_panel: 'Panel user',
	role_parkingAdmin: 'Parking Administrator',
	role_parkingCashier: 'Parking cashier',
	role_parkingGate: 'Parking "gate"',
	role_user: 'Client',
	published: 'Published',
	active: 'Active',
	inactive: 'Inactive',
	draft: 'Draft',
	canceled: 'Canceled',
	selectFile: 'Select file',
	fileError: 'File format not supported',
	importSuccess: 'File imported successfully',
	language: 'language',
	spanish: 'Spanish',
	english: 'English',
	valueTo: 'Up to',
	$lte: 'Less than or equal to',
	$gte: 'Greater than or equal to',
	backOfficeDocumentationTitle: 'Panel documentation',
	backOfficeDocumentationDescription: 'Panel usage documentation link',
	itDocumentationTitle: 'IT documentation',
	itDocumentationDescription: 'Server configuration documentation link',
	welcome:
		'Welcome {{name}}, if you need help with the panel or some server configuration you can use the links below.',
	welcomeClient: 'Welcome {{name}}',
	export: 'Export',
	tokenExpired: 'Your session has expired, please login again',
	serverIsDown: 'The API server is down, please contact IT team for more info',
	followingElementsWithError:
		'The following elements with column "{{key}}" ({{elements}}) could not be created or updated, error: {{error}}',
	status: 'Status',
	deleteTitle: 'Deletion',
	deleteText: 'Are you sure you want to delete the selected element?',
	cancelDescription: "Are you sure you wan't to return to login screen?",
	exampleFile: 'Download an example .csv file',
	account: 'account',
	account_plural: 'accounts',
	dashboard: 'dashboard',
	favorite: 'favorite',
	favorite_plural: 'favorites',
	inbox: 'inbox',
	personal_area: 'My section',
	profile: 'profile',
	user: 'user',
	user_plural: 'users',
	panel_user_plural: 'Panel users',
	app_user_plural: 'App users',
	user_me: 'my data',
	userTitle: '{{name}}',
	resetPassword: 'Clear password',
	resetPasswordTitle: 'Clear password',
	resetPasswordText: 'Clear password of selected user?',
	creationPasswordText:
		'The first time the user logs in it will create his password which will be used in subsequent accesses.',
	client: 'client',
	client_plural: 'clients',
	loginDate: 'Last login',
	loadError: 'There was a problem trying to load the data',
	clientTitle: 'Clients',
	identifier: 'Identifier',
	companyName: 'Company name',
	city: 'City',
	address: 'Address',
	phone: 'Phone',
	phoneNumer: 'Phone Number',
	alternativeEmail: 'Alternartive Email',
	associationLabel: 'Association Label',
	position: 'Position',
	area: 'Area',
	actions: 'Actions',
	data: 'Data',
	contact: 'Contact',
	mode: 'Mode',
	date: 'Date',
	range: 'Range',
	from: 'From',
	to: 'To',
	to2: 'to',
	website: 'Website',
	users: 'Users',
	crm: 'CRM',
	bill: 'bill',
	bills: 'bills',
	person: 'person',
	person_plural: 'people',
	document: 'document',
	document_plural: 'documents',
	main: 'main',
	documentation: 'documentation',
	dates: 'dates',
	contacts: 'contacts',
	remove: 'remove',
	total: 'Total',
	action: 'Action',
	transfer: 'Transfer',
	cash: 'Cash',
	pending: 'Pending',
	accepted: 'Accepted',
	rejected: 'Rejected',
	place: 'Place',
	place_plural: 'Places',
	createPlace: 'Create place',
	name: 'Name',
	opening: 'Opening',
	closes: 'Closes',
	barClosure: 'Bar Closure',
	doorClosure: 'Door Closure',
	mapsLink: 'Maps link',
	createEvent: 'Create event',
	createArtist: 'Create artist',
	eventTitle: 'Edit event ({{title}})',
	artistTitle: 'Edit artist ({{title}})',
	currentPlaceNeeded: 'You must first create a place',
	ticketsLink: 'Tickets link',
	tiktokEmbedded: 'Tiktok embedded',
	featured: 'Featured',
	isFeatured: 'Is featured',
	app_event_plural: 'Events',
	deletePlaceWithEvents: "Can't delete places with events",
	featuredPosition: 'Featured position',
	setFeatured: 'Feature',
	unsetFeatured: 'Unfeature',
	tag: 'Tag',
	title: 'Title',
	featured_plural: 'Featured',
	all: 'Events',
	up: 'Up',
	down: 'Down',
	image: 'Image',
	uploadingPleaseWait: 'Uploading, please wait.',
	uploadingError: 'The file could not be uploaded.',
	lineup: 'Lineup',
	deleteWithLikesWarning: "The selected element has likes, are you sure you wan't to continue",
	artist_plural: 'Artists',
	artist: 'Artist',
	genre: 'Genre',
	genre_plural: 'Genres',
	instagramEmbedded: 'Instagram Embedded',
	parking_vehicles: 'Vehicles',
	parking_gate: 'Parking',
	parking_cashier: 'Parking cashier',
	vehicle_code: 'Code',
	description: 'Description',
	priceHour: 'Price hour',
	priceHalfStay: 'Price half stay',
	priceStay: 'Price stay',
	print: 'Print',
	continue: 'continue',
	confirm: 'Confirm',
	any: 'Any',
	faq: 'FAQ',
	appConfig: 'Configuration',
	conditionsUrl: 'Conditions URL',
	policyUrl: 'Policy URL',
	privacyUrl: 'Privacy URL',
	deletionUrl: 'Deletion URL',
	mapsLinkLabel:
		'You can either copy the URL of the Google Maps iframe or copy the entire HTML code. The system will automatically retrieve the URL, and if the code is correct, the iframe will appear automatically.',
	instagramLinkLabel:
		'You can either copy the URL of the Instagram iframe or copy the entire HTML code. The system will automatically retrieve the URL. You can then validate the link clicking on the button "Check Link"',
	checkLink: 'Check Link',
	question: 'Question',
	response: 'Response',
	withoutElements: 'There no elements created, to create one please click the "Create" button.',
	createFaq: 'Create frequently asked question',
	editFaq: 'Edit frequently asked question',
	publishTitle: 'Publish event',
	publishDescription: "Are you sure you wan't to publish the selected event?",
	publishError: 'The selected event could not be published',
	unpublishTitle: 'Unpublish event',
	unpublishDescription: "Are you sure you wan't to unpublish the selected event?",
	unpublishError: 'The selected event could not be unpublished',
	publish: 'Publish',
	unpublish: 'Unpublish',
	view: 'View',
	featuredDraftError:
		'The featured events can only be published events. To add an event to the featured list you first have to publish it.',
	notifications: 'Notifications',
	createNotification: 'Create notification',
	artistNewDates: 'Artists',
	cancelation: 'Cancellations',
	custom: 'Custom',
	eventChanges: 'Events',
	allValues: 'All',
	topic: 'Topic',
	cancelEventError: 'Event could not be canceled',
	cancelEventTitle: 'Cancel event',
	cancelEventDescription: "Are you sure you wan't cancel the selected event?",
	selectArtist: 'Select artist',
	QRCodes: 'QR codes',
	category: 'Category',
	important: 'Important',
	sendNotification: 'Send notification',
	msjNotification: 'Notifications will only be sent when you cancel an event or publish a modified event.',
	msjNotificationConfirm: 'The notification will only be sent if there has been a change in the event.',
	msjNotificationEspecial:
		'Notification will only be sent if the title is modified, the date is rescheduled or the lineup is updated.',
	of: 'of',
	price: 'Price',
	products: 'Products',
} as const;

export default common;
