import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, LinearProgress, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Close, Save as SaveIcon } from '@mui/icons-material';

import { enqueueSnackbarError } from 'lib/helpers';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { updateFaq } from 'lib/models/faq';

const defaultFaq: Faq = {
	question: { es: '' },
	response: { es: '' },
};

type Props = {
	onRefetch: () => void;
	onClose: () => void;
	defaultData: Faq;
};

function FaqForm({ defaultData, onClose, onRefetch }: Props) {
	const { t } = useTranslation();

	const successSnackbar = useSuccessSnackbar();

	const [loading, setLoading] = useState<boolean>(false);
	const [faq, setFaq] = useState<Faq>(defaultData);

	const canSave = !!faq?.question.es && !!faq?.response.es;

	const onUpdate = (key: keyof Faq) => {
		return (event: any) => {
			if (event) {
				setFaq((current) => ({
					...(current || defaultFaq),
					[key]: { es: event.target.value },
				}));
			}
		};
	};

	async function save() {
		if (faq) {
			try {
				setLoading(true);
				await updateFaq({ ...faq });
				setFaq(defaultFaq);
				successSnackbar();
			} catch (error) {
				enqueueSnackbarError(error);
			}

			onRefetch();
			setLoading(false);
		}
	}

	return (
		<Dialog open={true} onClose={onClose}>
			{loading && <LinearProgress />}

			<DialogTitle>{t(defaultData._id ? 'common:editFaq' : 'common:createFaq')}</DialogTitle>

			<DialogContent sx={{ minWidth: '30vw' }}>
				{loading && <LinearProgress sx={{ mb: 3 }} />}

				{faq && (
					<FormGroup>
						<TextField
							id="conditions"
							label={t('common:question')}
							fullWidth={true}
							value={faq.question.es}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('question')}
							disabled={loading}
						/>

						<TextField
							id="policy"
							label={t('common:response')}
							fullWidth={true}
							value={faq.response.es}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('response')}
							disabled={loading}
							multiline
						/>

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<SaveIcon />}
							onClick={save}
							disabled={!canSave}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default FaqForm;
