import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FaqIcon from '@mui/icons-material/QuestionAnswer';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
	open?: boolean;
};

export default function AppConfig({ selected, onSelect, open }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/faq"
			selected={selected === MenuItem.Faq}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Faq)}
		>
			<ListItemIcon>
				<FaqIcon />
			</ListItemIcon>
			{open && <ListItemText primary={t('common:faq')} />}
		</ListItem>
	);
}
