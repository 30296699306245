import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import { useEffect } from 'react';

import AccessDetailTable from 'components/AccessDetailTable';

import useAccessDetail from 'hooks/useAccessDetail';

function AccessDetail() {
	const { t } = useTranslation();

	const { code } = useParams<{ code: string }>();

	const navigate = useNavigate();

	const accessData = useAccessDetail();

	const title =
		accessData.loading || accessData.error
			? t('common:loading')
			: t('access:accessDetailTitle', { plate: accessData.access.plate ?? '-' });

	useEffect(() => {
		if (accessData.error) {
		}
	}, [accessData.error, accessData, navigate]);

	return (
		<ScreenContainer title={title} goBack={true}>
			<PaperContainer>
				<AccessDetailTable code={code || ''} initialize={accessData.access} />
			</PaperContainer>
		</ScreenContainer>
	);
}

export default AccessDetail;
