import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import AppConfigForm from 'components/AppConfigForm';

function Configuration() {
	const { t } = useTranslation();

	const title = t('common:appConfig');

	return (
		<ScreenContainer title={title} goBack={true}>
			<PaperContainer>
				<AppConfigForm />
			</PaperContainer>
		</ScreenContainer>
	);
}

export default Configuration;
