import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	access?: Access;
	open: string | null;
	handleClose: () => void;
	onSave: (params: { code?: string; plate?: string }) => void;
	route: string;
};

function AssignPlate({ access, open, handleClose, onSave }: Props) {
	const { t } = useTranslation();

	const [plate, setPlate] = useState<string>('');

	const handlePlate = (plate: string) => {
		return setPlate(plate);
	};

	return (
		<Dialog open={!!open}>
			<DialogTitle sx={{ paddingY: '.5rem' }} align="center">
				{t('access:enter_plate')}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<DialogContentText sx={{ paddingBottom: '.5rem', textAlign: 'center' }}>
					{t('access:enterPlateLater')}
				</DialogContentText>
				<TextField
					autoFocus
					autoComplete="off"
					value={plate}
					onChange={(e) => handlePlate(e.target.value.toUpperCase())}
					inputProps={{
						style: { textTransform: 'uppercase', textAlign: 'center' },
					}}
				/>
				<DialogContentText sx={{ paddingTop: '.5rem', textAlign: 'center' }}>{`${access && access.vehiclesInside} ${t(
					'common:of'
				)} ${access?.stay === 'tent' ? 2 : 1} ${t('access:entries')}`}</DialogContentText>
				<DialogContentText sx={{ paddingTop: '.5rem', textAlign: 'center' }}>
					{`${t('access:last_entry')}: ${lastMovements('entry', access?.movements)}`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						setPlate('');
					}}
					color="error"
				>
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						if (access) {
							onSave({ code: access.code, plate });
							handleClose();
							setPlate('');
						}
					}}
					color="primary"
				>
					{t('common:confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AssignPlate;

function parseDate(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const parsedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year
		.toString()
		.slice(-2)} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
	return parsedDate;
}

function lastMovements(type: string, movements?: Movement[]) {
	if (!movements) return '';

	const filteredMovements: Movement[] = movements.filter((movement) => movement.type === type);

	const lastMovement = filteredMovements.length > 0 ? filteredMovements.at(-1) : null;

	return lastMovement ? parseDate(lastMovement.date) : '-';
}
