import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Select, Typography } from '@mui/material';
import MerchList from 'components/MerchList/MerchList';
import MerchandisingModal from 'components/MerchandisingModal';
import SearchToolbar from 'components/SearchToolbar';
import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useMerch from 'hooks/useMerch';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export default function Merchandising() {
	const defaultMerchandising: AppMerchandising = {
		title: '',
		description: '',
		category: '',
		price: null,
		status: 'draft',
		images: [],
		colors: [],
		sizes: [],
		url: '',
	};

	const { t } = useTranslation();

	const title = t('merch:merchandising');

	const [showModal, setShowModal] = useState<AppMerchandising | null>(null);

	const [searchParams, setSearchParams] = useSearchParams({ search: '', page: '0', rowsPerPage: '10', status: '' });

	const [status, setStatus] = useState<'draft' | 'active'>('draft');

	const [search, setSearch] = useState<string>('');

	const [page, setPage] = useState<number>(Number(searchParams.get('page')) || 0);

	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(searchParams.get('rowsPerPage')) || 10);

	const searchProps = useMemo(() => {
		let searchParams = { status, search, page, rowsPerPage };
		return searchParams;
	}, [status, search, page, rowsPerPage]);

	const featuredSearch = useMemo(() => {
		return { ...searchProps, orderBy: 'featuredPosition', order: 'asc' as 'asc', featuredPosition: { $gte: 1 } };
	}, [searchProps]);

	const merchSearch = useMemo(() => {
		return { ...searchProps, orderBy: 'price', order: 'asc' as 'asc', featuredPosition: { $exists: false } };
	}, [searchProps]);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	const onClose = () => {
		refetch();
		setShowModal(null);
	};

	const onEdit = (merch: AppMerchandising) => {
		setShowModal(merch);
	};

	const onChange = (status: AppMerchStatus) => {
		setStatus(status);
	};

	const onSearch = (e: string) => {
		setSearch(e);
	};

	useEffect(() => {
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			status: `${status}`,
		});
	}, [setSearchParams, page, rowsPerPage, status]);

	const { loading: featuredLoading, merch: featuredMerch, refetch: featuredRefetch } = useMerch(featuredSearch);

	const { loading, merch, refetch: merchRefetch } = useMerch(merchSearch);

	const refetch = () => {
		featuredRefetch();
		merchRefetch();
	};

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultMerchandising)} disabled={loading} />
			{showModal && <MerchandisingModal defaultValue={showModal} onClose={onClose} />}
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
				<Select
					sx={{ minWidth: '15vw' }}
					value={status}
					onChange={(ev) => {
						onChange(ev.target.value as AppMerchStatus);
					}}
				>
					<MenuItem value={'draft'}>{t('common:draft')}</MenuItem>
					<MenuItem value={'active'}>{t('common:active')}</MenuItem>
				</Select>

				<SearchToolbar onSearch={onSearch} initialValue={search} />
			</Box>

			<Box>
				{/* FEATURED */}
				<Accordion defaultExpanded={true}>
					<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panella-content" id="panella-header">
						<Typography>{t('common:featured_plural')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{status !== 'active' && <Typography color="error">{t('common:featuredDraftError')}</Typography>}
						{status === 'active' && (
							<MerchList
								onEdit={onEdit}
								refetch={refetch}
								data={featuredMerch}
								loading={featuredLoading}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						)}
					</AccordionDetails>
				</Accordion>
				{/* PUBLISHED */}
				<Accordion defaultExpanded={true}>
					<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panella-content" id="panella-header">
						<Typography>{t('common:products')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<MerchList
							onEdit={onEdit}
							refetch={refetch}
							data={merch}
							loading={loading}
							page={page}
							rowsPerPage={rowsPerPage}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</AccordionDetails>
				</Accordion>
			</Box>
		</ScreenContainer>
	);
}
