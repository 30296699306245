import { useCallback, useEffect, useState } from 'react';

import { FetchNotificationsProps, fetchNotifications } from 'lib/models/notification';

function useNotifications(props: FetchNotificationsProps) {
	const [loading, setLoading] = useState<boolean>(true);

	const [notifications, setNotifications] = useState<PaginateSource<AppNotification>>({ total: 0, elements: [] });

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchNotifications(props);
		setNotifications(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, notifications, refetch };
}

export default useNotifications;
