import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import { useEffect } from 'react';
import VehicleEditionForm from 'components/VehicleEditionForm';
import useVehicle from 'hooks/useVehicle';

function VehicleEdition() {
	const { t } = useTranslation();

	const { id } = useParams<{ id: string }>();
	const { pathname } = useLocation();

	const navigate = useNavigate();

	const newVehicle = !!id && id === 'new' && pathname !== '/me';

	const vehicleData = useVehicle();

	const title =
		vehicleData.loading || vehicleData.error
			? t('common:loading')
			: newVehicle
			? t('vehicles:createVehicle')
			: vehicleData.fromParams
			? t('common:userTitle', { name: vehicleData.vehicle.code })
			: t('common:profile');

	useEffect(() => {
		if (vehicleData.error) {
			navigate('/parking/vehicles');
		}
	}, [vehicleData.error, vehicleData, navigate]);

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				<VehicleEditionForm
					vehicleLoading={vehicleData.loading}
					isModal={false}
					withDelete={true}
					withPasswordReset={true}
					withStatus={true}
					id={id || (newVehicle ? 'new' : '')}
					initialize={vehicleData.vehicle}
				/>
			</PaperContainer>
		</ScreenContainer>
	);
}

export default VehicleEdition;
