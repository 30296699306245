/* eslint-disable no-mixed-operators */
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, FormControlLabel, FormGroup, SxProps, Theme } from '@mui/material';

import TimeSelector, { TimeSelectorProps } from 'components/TimeSelector/TimeSelector';

import { dateTime, midnight } from 'lib/helpers';
import moment from 'moment';

type Props = TimeSelectorProps & {
	label?: string;
	sx?: SxProps<Theme>;
	event?: Omit<AppEventCreation, 'date'> & {
		date?: string | undefined;
	};
	setEvent?: Dispatch<SetStateAction<Omit<AppEventCreation, 'date'> & { date?: string }>>;
	type?: 'barClosure' | 'closes' | 'doorClosure' | 'opening';
};

function OptionalTimeSelector({ onUpdateTime, defaultValue, disabled, label, sx, event, setEvent, type }: Props) {
	const { t } = useTranslation();
	const [checkboxValue, setCheckboxValue] = useState<boolean>(defaultValue !== undefined ? true : false);

	const [editing, setEditing] = useState<TimeSelectorProps['defaultValue'] | undefined>(
		event && type && event[type] ? defaultValue : undefined
	);

	function removeProperty() {
		setEvent &&
			type &&
			setEvent((current) => {
				const { [type]: deletedProperty, ...newState } = current;
				return newState;
			});
	}
	const defaultDate =
		defaultValue && typeof defaultValue === 'string'
			? moment(defaultValue)
			: moment()
					//@ts-ignore
					.set('hours', defaultValue?.hours ?? 0)
					//@ts-ignore
					.set('minutes', defaultValue?.minutes ?? 0);

	const valueResolver = checkboxValue || !!editing;

	return (
		<Box sx={sx}>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={disabled}
							checked={checkboxValue || !!editing}
							defaultChecked={!!editing}
							inputProps={{ 'aria-label': t('common:date') }}
							onChange={() => {
								if (!!editing) {
									setEditing(undefined);
									removeProperty();
								} else if (checkboxValue) {
									setCheckboxValue(false);
								} else {
									setEditing(midnight().toISOString());
									const hours = defaultDate.get('hours');
									const minutes = defaultDate.get('minutes');
									onUpdateTime(hours, minutes);
								}
							}}
						/>
					}
					label={label || t('common:date')}
				/>

				{valueResolver && (
					<TimeSelector
						defaultValue={defaultValue}
						onUpdateTime={(hours, minutes) => {
							const date = dateTime(hours, minutes);
							const parsedDate = hours < 6 ? date.add(1, 'day') : date;
							setEditing(parsedDate.toISOString());
							onUpdateTime(hours, minutes);
						}}
						disabled={disabled}
					/>
				)}
			</FormGroup>
		</Box>
	);
}

export default OptionalTimeSelector;
