import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchEvent } from 'lib/models/events';

function useEvent() {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [event, setEvent] = useState<PopulatedAppEvent | null>();

	const params = useParams<{ id?: string }>();
	const id = params.id ? params.id : null;

	useEffect(() => {
		async function getEvent() {
			if (id && id !== 'new') {
				const response = await fetchEvent(id);
				if (response) {
					setEvent(response);
				} else {
					setError(true);
				}
			}
			setLoading(false);
		}

		getEvent();
	}, [id]);

	return { loading, error, event };
}

export default useEvent;
