import { useTranslation } from 'react-i18next';

import { Add as Icon } from '@mui/icons-material';

import FloatIcon from 'components/icons/FloatIcon';

function CreateIcon({ onCreate, disabled }: { onCreate: () => any; disabled?: boolean }) {
	const { t } = useTranslation();

	return <FloatIcon onClick={() => onCreate()} title={t('common:create')} icon={Icon} disabled={disabled} />;
}

export default CreateIcon;
