import { Button } from '@mui/material';
import style from './MerchandisingModal.module.css';
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

type Props = {
	merch: AppMerchandising;
	setMerch: React.Dispatch<React.SetStateAction<AppMerchandising>>;
};
export default function ColorInput({ merch, setMerch }: Props) {
	const [currentColor, setCurrentColor] = useState<AppColor>({ value: '#000000' });

	const addColor = () => {
		if (!merch.colors.find((c) => c === currentColor.value)) {
			const updatedMerch = { ...merch, colors: [...merch.colors, currentColor.value] };

			setMerch(updatedMerch);
		}
	};

	const removeColor = (color: string) => {
		const newColors = merch.colors.filter((c) => c !== color);
		setMerch((prev) => {
			return { ...prev, colors: newColors };
		});
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleColorChange = useCallback(
		debounce((color) => {
			setCurrentColor({ value: color });
		}, 150),
		[]
	);

	return (
		<div className={style.colorInput}>
			<div className={style.colorInputHeader}>
				<span>Colores</span>
				<input
					type="color"
					value={currentColor.value}
					onChange={(e) => {
						handleColorChange(e.target.value);
					}}
				/>
			</div>
			{merch.colors.length > 0 && (
				<div className={style.colorsContainer}>
					{merch.colors.map((color, i) => {
						return (
							<div
								onClick={() => removeColor(color)}
								className={style.colorItem}
								style={{ backgroundColor: color }}
								key={i}
							></div>
						);
					})}
				</div>
			)}
			<Button onClick={addColor}>Agregar color</Button>
		</div>
	);
}
