import { Autocomplete, Button, TextField, createFilterOptions } from '@mui/material';
import style from './MerchandisingModal.module.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMerchSizes from 'hooks/useMerchSizes';

interface SizeOptionType {
	title: string;
	inputValue?: string;
	new?: boolean;
}

type Props = {
	onSizeSelected: (size: string) => void;
	onSizeRemoved: (size: string) => void;
	merch: AppMerchandising;
};

const filter = createFilterOptions<SizeOptionType>();

export default function SizesInput({ onSizeSelected, onSizeRemoved, merch }: Props) {
	const [currentSize, setCurrentSize] = useState<SizeOptionType>({ inputValue: '', title: '' });
	const { addSize, loading, sizes } = useMerchSizes();

	const { t } = useTranslation();

	const onSelected = (size: string) => {
		onSizeSelected(size);
		addSize(size);
	};

	const options = sizes.map((size) => {
		return {
			inputValue: size,
			title: size,
			new: false,
		};
	});

	return (
		<div className={style.colorInput}>
			<div className={style.colorInputHeader} style={{ alignItems: 'center' }}>
				<span>Talles</span>
				<Autocomplete
					loading={loading}
					id="sizes"
					value={currentSize}
					sx={{ flex: 1, ml: 2 }}
					options={options}
					renderInput={(params) => <TextField {...params} />}
					renderOption={(props, option) => <li {...props}>{option.title}</li>}
					onChange={(_, newValue) => {
						if (newValue?.inputValue && newValue.title) {
							setCurrentSize(newValue);
						}
					}}
					filterOptions={(options, params) => {
						const filtered = filter(options, params);

						const { inputValue } = params;

						const value = inputValue.toUpperCase();

						const isExisting = options.some((option) => value === option.inputValue);

						if (value !== '' && !isExisting) {
							filtered.push({
								inputValue: value,
								title: `${t('common:create')} "${value}"`,
							});
						}
						return filtered;
					}}
					getOptionLabel={(option) => {
						return option.inputValue || option.title;
					}}
				/>
			</div>

			{(merch.sizes?.length ?? 0) > 0 && (
				<div className={style.colorsContainer}>
					{merch.sizes!.map((size, i) => {
						return (
							<div
								onClick={() => onSizeRemoved(size)}
								className={style.sizeItem}
								style={{ border: '1px solid black' }}
								key={i}
							>
								{size}
							</div>
						);
					})}
				</div>
			)}

			<Button disabled={!currentSize.inputValue} onClick={() => onSelected(currentSize.inputValue!)}>
				Agregar talle
			</Button>
		</div>
	);
}
