import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchArtist } from 'lib/models/artists';

function useArtist(withId?: string) {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [artist, setArtist] = useState<Artist | null>(null);

	const params = useParams<{ id?: string }>();
	const id = withId || (params.id ? params.id : null);

	const reset = useCallback(() => {
		setArtist(null);
	}, []);

	useEffect(() => {
		async function getArtist() {
			if (id && id !== 'new') {
				const response = await fetchArtist(id);
				if (response) {
					setArtist(response);
				} else {
					setError(true);
				}
			}
			setLoading(false);
		}

		getArtist();
	}, [id]);

	return { loading, error, artist, reset };
}

export default useArtist;
