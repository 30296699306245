import { CSSProperties } from 'react';

import useImageURL from 'hooks/useImageURL';

type Props = {
	id?: string;
	style?: CSSProperties;
	className?: string;
	alt?: string;
};

function AsyncImage({ id, style, className, alt = '' }: Props) {
	const imageURL = useImageURL(id);

	return <img style={style} className={className} src={imageURL} alt={alt} />;
}

export default AsyncImage;
