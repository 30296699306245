import { serverStatus } from 'lib/models/users';
import { useEffect } from 'react';

import useIsLogged from 'hooks/useIsLogged';
import useServerDown from 'hooks/useServerDown';
import useTokenHasExpired from 'hooks/useTokenHasExpired';

import useSetServerDown from './useSetServerDown';
import useSetTokenExpired from './useSetTokenExpired';

let timer: NodeJS.Timer | null = null;

function useLogoutOnTokenExpired() {
	const logged = useIsLogged();
	const expired = useTokenHasExpired();
	const setExpired = useSetTokenExpired();
	const setServerDown = useSetServerDown();
	const serverIsDown = useServerDown();

	useEffect(() => {
		// Add interval
		function addInterval() {
			timer = setInterval(async () => {
				const user = await serverStatus();
				if (user === 'unauthorized' && logged && !expired) setExpired();
				const isDown = user === 'down';
				if (serverIsDown && !isDown) setServerDown(false);
				if (!serverIsDown && isDown) setServerDown(true);
			}, 1000);
		}
		// remove interval
		function removeInterval() {
			if (timer) {
				// @ts-ignore
				clearInterval(timer);
				timer = null;
			}
		}
		// Add or remove interval
		if (!timer && logged) addInterval();
		if ((!logged || expired) && timer) removeInterval();
		// Remove interval on unmount
		return () => removeInterval();
	}, [setExpired, expired, logged, setServerDown, serverIsDown]);
}

export default useLogoutOnTokenExpired;
