import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, TableHead, TableRow, TableCell, Button, Box, LinearProgress, Typography } from '@mui/material';

import ResponsiveTable from 'components/ResponsiveTable';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { rowsPerPageOptions } from 'config/constants';

import { enqueueSnackbarError } from 'lib/helpers';
import { removeNotification } from 'lib/models/notification';
import moment from 'moment';

type Props = {
	loading?: boolean;
	refetch: () => any;
	data: PaginateSource<AppNotification>;
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (notification: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};

export default function NotificationsList({
	refetch,
	data,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
	loading,
}: Props) {
	const { t } = useTranslation();

	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<null | AppNotification>(null);

	function onDelete(notification: AppNotification) {
		setShowDeleteConfirmationModal(notification);
	}

	async function onDeleteHandler(mustDelete: boolean) {
		if (mustDelete && showDeleteConfirmationModal?._id) {
			const deleted = await removeNotification(showDeleteConfirmationModal);
			if (!deleted) {
				enqueueSnackbarError(t('common:deletionError'));
			}
			refetch();
		}

		setShowDeleteConfirmationModal(null);
	}

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!loading && <LinearProgress />}

			{showDeleteConfirmationModal && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}

			<ResponsiveTable
				elements={data.elements}
				listSx={{ maxHeight: '70vh' }}
				tableSx={{ maxHeight: '65vh' }}
				list={{
					primaryKey: '_id',
					renderListItemText: (notification) => {
						return (
							<ListItemText
								primary={
									<Box>
										<Typography>{notification.title.es}</Typography>
										<Typography>{notification.message.es}</Typography>
										<Typography>{moment(notification.createdAt).format('DD/MM/YYYY')}</Typography>
									</Box>
								}
								secondary={
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
											<Button onClick={() => onDelete(notification)} variant="outlined" color="error">
												{t(`common:delete`)}
											</Button>
										</Box>
									</Box>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:title')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:description')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:date')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (notification) => {
						return (
							<TableRow tabIndex={-1} key={notification._id}>
								<TableCell>{notification.title.es}</TableCell>
								<TableCell>{notification.message.es}</TableCell>
								<TableCell>{moment(notification.createdAt).format('DD/MM/YYYY')}</TableCell>
								<TableCell>
									<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
										<Button onClick={() => onDelete(notification)} variant="outlined" color="error">
											{t(`common:delete`)}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: data.total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}
