import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'types/global';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import React from 'react';

type Props = {
	open?: boolean;
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

function QRCodes({ open, selected, onSelect }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/qr-codes"
			selected={selected === MenuItem.QRCodes}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.QRCodes)}
		>
			<ListItemIcon>
				<QrCode2Icon />
			</ListItemIcon>
			{open && <ListItemText primary={t('common:QRCodes')} />}
		</ListItem>
	);
}

export default QRCodes;
