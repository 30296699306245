import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, FormGroup, LinearProgress, TextField, Box } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';
import ConfirmationDialog from 'components/ConfirmationDialog';

import useSelectedLanguage from 'hooks/useSelectedLanguage';

import { UsersTypes } from 'types/global';
import { enqueueSnackbarError } from 'lib/helpers';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useUpdateLanguage from 'components/UserEditionForm/hooks/useUpdateLanguage';
import { deleteVehicle, updateVehicle } from 'lib/models/vehicles';

type Props = {
	id: string;
	withPasswordReset: boolean;
	isModal: boolean;
	withDelete: boolean;
	type?: UserType[];
	withStatus: boolean;
	initialize?: Vehicle | null;
	vehicleLoading: boolean;
};

function VehicleEditionForm({ id, withDelete, isModal, initialize, vehicleLoading }: Props) {
	const { t } = useTranslation();

	const updateLanguage = useUpdateLanguage();

	const navigate = useNavigate();

	const newVehicle = !!id && id === 'new';

	const selectedLanguage = useSelectedLanguage();

	const [isLoading, setLoading] = useState<boolean>(true);

	const [success, setSuccess] = useState<boolean>(false);

	const userSession = useLoggedUserDocument();
	const loggedUserType: UserType | '' = userSession?.type || '';

	const [vehicle, setVehicle] = useState<Vehicle>(getDefaultVehicle());

	const successSnackbar = useSuccessSnackbar();

	const [confirmDeleteUser, setDeleteUser] = useState(false);
	const [language, setLanguage] = useState(selectedLanguage);

	const canSave = vehicle.code;

	const loading = isLoading || vehicleLoading;
	const vehicleTypeCreated = !loading && success && newVehicle;

	const navigateTo = loggedUserType === UsersTypes.user ? '/' : '/parking/vehicles';

	useEffect(() => {
		if (!vehicleLoading) {
			if (!vehicleTypeCreated && initialize) {
				setVehicle(initialize);
			}
			setLoading(false);
		}
	}, [vehicleLoading, initialize, vehicleTypeCreated]);

	useEffect(() => {
		setLanguage(selectedLanguage);
	}, [selectedLanguage]);

	const onUpdate = (key: keyof Vehicle) => {
		return (event: any) => {
			const value = event.target.value;

			if (vehicle) {
				setVehicle({ ...vehicle, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			await updateVehicle(vehicle);
			setSuccess(true);
			successSnackbar();
			if (language) {
				updateLanguage(language);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		navigate(navigateTo);
	};

	function toggleDeleteUser() {
		setDeleteUser(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteUser(false);
			if (confirmed) {
				setLoading(true);
				await deleteVehicle(vehicle);

				navigate(navigateTo);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	return (
		<Box>
			{isModal && (
				<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1, justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<CloseIcon />}
						onClick={() => navigate(navigateTo)}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<TextField
					id="code"
					label={t('common:vehicle_code')}
					fullWidth={true}
					value={vehicle.code}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('code')}
					disabled={id !== 'new'}
				/>

				<TextField
					id="description"
					label={t('common:description')}
					fullWidth={true}
					value={vehicle.description}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('description')}
					disabled={loading}
				/>

				<TextField
					id="priceHour"
					label={t('common:priceHour')}
					fullWidth={true}
					value={vehicle.priceHour}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('priceHour')}
					disabled={loading}
				/>

				<TextField
					id="priceStay"
					label={t('common:priceHalfStay')}
					fullWidth={true}
					value={vehicle.priceHalfStay}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('priceHalfStay')}
					disabled={loading}
				/>

				<TextField
					id="priceHalfStay"
					label={t('common:priceStay')}
					fullWidth={true}
					value={vehicle.priceStay}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('priceStay')}
					disabled={loading}
				/>

				{
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('common:save')}
					</Button>
				}
				{withDelete && !newVehicle && (
					<Button
						variant="contained"
						color="secondary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<DeleteIcon />}
						onClick={toggleDeleteUser}
					>
						{t('common:delete')}
					</Button>
				)}
			</FormGroup>

			{!vehicleTypeCreated && confirmDeleteUser && (
				<ConfirmationDialog
					title={t('vehicles:deleteTitle')}
					description={t('common:deleteText') || ''}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}
		</Box>
	);
}

export default VehicleEditionForm;

function getDefaultVehicle(): Vehicle {
	return {
		_id: '',
		code: '',
		description: '',
		priceHour: 0,
		priceHalfStay: 0,
		priceStay: 0,
	};
}
