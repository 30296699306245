import { ScanAndAssignPlate } from 'components/Modals';
import ManuallyEnter from 'components/Modals/ManuallyEntry';
import QRReader from 'components/QRReader/QRReader';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { savePlate } from 'lib/models/access';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ScanPlate() {
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState<string | null>(null);
	const [foundCode, setFoundCode] = useState<string>('');

	const closeAll = () => {
		setOpenModal(null);
		setFoundCode('');
	};

	const handleScan = (code: string) => {
		if (!!code && !foundCode) {
			setFoundCode(code);
			setOpenModal('ScanAndAssignPlate');
		}
	};

	const onVerify = async (code: string) => {
		setFoundCode(code);
		setOpenModal('ScanAndAssignPlate');
		return;
	};

	const onSavePlate = async (plate: string) => {
		const result = await savePlate({ code: foundCode, plate });

		if (!result) {
			return enqueueSnackbarError('Error on save plate');
		}

		return enqueueSnackbarSuccess(t('common:success'));
	};

	const modals: Record<string, React.ReactNode> = {
		ManuallyEntry: <ManuallyEnter open={openModal} onVerify={onVerify} handleClose={closeAll} />,
		ScanAndAssignPlate: <ScanAndAssignPlate open={openModal} handleClose={closeAll} onSave={onSavePlate} />,
	};

	return (
		<>
			<QRReader onScan={handleScan} setOpenModal={setOpenModal} modalName={'ManuallyEntry'} routeName={'plate'} />
			{openModal && modals[openModal]}
		</>
	);
}

export default ScanPlate;
