import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import moment from 'moment';

import {
	ListItemText,
	TableHead,
	TableRow,
	TableCell,
	Box,
	LinearProgress,
	SxProps,
	Theme,
	Typography,
	Checkbox,
} from '@mui/material';
import { pink } from '@mui/material/colors';

import ResponsiveTable from 'components/ResponsiveTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import AsyncImage from 'components/AsyncImage';

import { rowsPerPageOptions } from 'config/constants';

import { addOrRemovePosition, cancelEvent, deleteEvent, publishOrUnPublish, updatePosition } from 'lib/models/events';
import { enqueueSnackbarError } from 'lib/helpers';
import Actions from './Actions';

type Props = {
	loading?: boolean;
	refetch: () => any;
	sx?: SxProps<Theme>;
	events: PaginateSource<PopulatedAppEvent>;
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function EventsTable({
	events,
	handleChangePage,
	handleChangeRowsPerPage,
	refetch,
	loading,
	page,
	rowsPerPage,
}: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [showPublishOrUnPublishType, setShowPublishOrUnPublishType] = useState<'publish' | 'unpublish'>('publish');
	const [showPublishOrUnPublish, setShowPublishOrUnPublish] = useState<null | PopulatedAppEvent>(null);
	const [showCancelEvent, setCancelEvent] = useState<null | PopulatedAppEvent>(null);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<null | PopulatedAppEvent>(null);
	const [showDeleteWithLikesConfirmationModal, setShowDeleteWithLikesConfirmationModal] =
		useState<null | PopulatedAppEvent>(null);
	const [sendNotification, setSendNotification] = useState<boolean>(true);
	function onEdit(selected: PopulatedAppEvent) {
		const { _id } = selected;
		navigate(`/events/${_id}?status=${showPublishOrUnPublishType}`);
	}

	function onPublish(event: PopulatedAppEvent) {
		setShowPublishOrUnPublishType('publish');
		setShowPublishOrUnPublish(event);
	}

	function onUnPublish(event: PopulatedAppEvent) {
		setShowPublishOrUnPublishType('unpublish');
		setShowPublishOrUnPublish(event);
	}

	function onCancelEvent(event: PopulatedAppEvent) {
		setCancelEvent(event);
	}

	async function onPublishOrUnPublishHandler(confirmed: boolean) {
		if (confirmed && showPublishOrUnPublish?._id) {
			const success = await publishOrUnPublish(
				showPublishOrUnPublish?._id,
				showPublishOrUnPublishType,
				sendNotification
			);
			if (!success) {
				enqueueSnackbarError(t(`common:${showPublishOrUnPublishType}Error`));
			}
			refetch();
		}

		setShowPublishOrUnPublish(null);
	}

	async function onCancelEventHandler(confirmed: boolean) {
		if (confirmed && showCancelEvent?._id) {
			const success = await cancelEvent(showCancelEvent?._id, sendNotification);
			if (!success) {
				enqueueSnackbarError(t(`common:cancelEventError`));
			}
			refetch();
		}
		setCancelEvent(null);
	}

	function onDelete(event: PopulatedAppEvent) {
		setShowDeleteConfirmationModal(event);
	}

	async function onDeleteHandler(mustDelete: boolean) {
		if ((showDeleteConfirmationModal?.likes ?? 0) > 0) {
			setShowDeleteWithLikesConfirmationModal(showDeleteConfirmationModal);
		} else {
			if (mustDelete && showDeleteConfirmationModal?._id) {
				const deleted = await deleteEvent(showDeleteConfirmationModal?._id);
				if (!deleted) {
					enqueueSnackbarError(t('common:deletionError'));
				}
				refetch();
			}
		}
		if (showDeleteConfirmationModal) {
			setShowDeleteConfirmationModal(null);
		} else {
			setShowDeleteWithLikesConfirmationModal(null);
		}
	}

	async function setFeatured(event: PopulatedAppEvent) {
		await addOrRemovePosition(event, 'add');
		refetch();
	}

	async function unsetFeatured(event: PopulatedAppEvent) {
		await addOrRemovePosition(event, 'remove');
		refetch();
	}

	async function decreasePosition(event: PopulatedAppEvent) {
		await updatePosition(event, 'decrease');
		refetch();
	}
	async function increasePosition(event: PopulatedAppEvent) {
		await updatePosition(event, 'increase');
		refetch();
	}

	const featuredElements = events.elements.filter((el) => !!el.featuredPosition);
	const firstFeaturedPosition = featuredElements[0]?.featuredPosition ?? -1;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSendNotification(event.target.checked);
	};

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!loading && <LinearProgress />}

			{(showDeleteConfirmationModal || showDeleteWithLikesConfirmationModal) && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					warningMessage={showDeleteWithLikesConfirmationModal ? t('common:deleteWithLikesWarning') : undefined}
					onClose={onDeleteHandler}
				/>
			)}

			{showPublishOrUnPublish && (
				<ConfirmationDialog
					title={t(`common:${showPublishOrUnPublishType}Title`)}
					description={t(`common:${showPublishOrUnPublishType}Description`)}
					onClose={onPublishOrUnPublishHandler}
					warningMessage={sendNotification ? t(`common:msjNotificationConfirm`) : ''}
				>
					{t(`common:sendNotification`)}
					<Checkbox
						{...label}
						defaultChecked
						checked={sendNotification}
						onChange={handleChange}
						sx={{
							color: pink[800],
							'&.Mui-checked': {
								color: pink[600],
							},
						}}
					/>
				</ConfirmationDialog>
			)}

			{showCancelEvent && (
				<ConfirmationDialog
					title={t(`common:cancelEventTitle`)}
					description={t(`common:cancelEventDescription`)}
					onClose={onCancelEventHandler}
				>
					{t(`common:sendNotification`)}
					<Checkbox
						{...label}
						defaultChecked
						checked={sendNotification}
						onChange={handleChange}
						sx={{
							color: pink[800],
							'&.Mui-checked': {
								color: pink[600],
							},
						}}
					/>
				</ConfirmationDialog>
			)}

			<ResponsiveTable
				elements={events.elements}
				listSx={{ maxHeight: '45vh' }}
				tableSx={{ maxHeight: '45vh' }}
				list={{
					primaryKey: '_id',
					renderAvatar: (event) => {
						return (
							<AsyncImage
								id={event.featuredPosition ? event.imageVertical || event.imageSquare || event.image : event.image}
								style={{ width: '80px', borderRadius: 8 }}
							/>
						);
					},
					renderListItemText: (event) => {
						return (
							<ListItemText
								primary={
									<Box>
										<Typography>
											{event.title.es} - {moment(event.date).format('DD/MM/YYYY')}{' '}
										</Typography>
										<Typography>{event.place.name.es}</Typography>
										<Typography>{event.tag}</Typography>
									</Box>
								}
								secondary={
									<Actions
										decreasePosition={decreasePosition}
										event={event}
										firstFeaturedPosition={firstFeaturedPosition}
										increasePosition={increasePosition}
										onDelete={onDelete}
										onEdit={onEdit}
										onPublish={onPublish}
										onUnPublish={onUnPublish}
										onCancelEvent={onCancelEvent}
										setFeatured={setFeatured}
										total={events.total}
										unsetFeatured={unsetFeatured}
									/>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:image')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:title')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:place')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:date')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:tag')}
								</TableCell>

								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (event) => {
						return (
							<TableRow tabIndex={-1} key={event._id}>
								<TableCell>
									<Box
										style={{
											width: '80px',
											height: event.image ? 'unset' : '60px',
											borderRadius: 8,
											backgroundColor: event.image ? 'unset' : 'lightgray',
										}}
									>
										<AsyncImage
											id={
												event.featuredPosition ? event.imageVertical || event.imageSquare || event.image : event.image
											}
											style={{ width: '80px', borderRadius: 8 }}
										/>
									</Box>
								</TableCell>
								<TableCell>{event.title.es}</TableCell>
								<TableCell>{event.place.name.es}</TableCell>
								<TableCell>{moment(event.date).format('DD/MM/YYYY')}</TableCell>
								<TableCell>{event.tag || '-'}</TableCell>

								<TableCell>
									<Actions
										decreasePosition={decreasePosition}
										event={event}
										firstFeaturedPosition={firstFeaturedPosition}
										increasePosition={increasePosition}
										onDelete={onDelete}
										onEdit={onEdit}
										onPublish={onPublish}
										onUnPublish={onUnPublish}
										onCancelEvent={onCancelEvent}
										setFeatured={setFeatured}
										total={events.total}
										unsetFeatured={unsetFeatured}
									/>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: events.total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}
