import { API_DOMAIN, MERCHANDISING_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';
import { z } from 'zod';

export const appMerchStatus: AppMerchStatus[] = ['active', 'draft'];

export type FetchMerchSearchProps = {
	search?: string | null;
	status: AppMerchStatus;
};

export type FetchMerchProps = FetchMerchSearchProps & PaginationProps;
export const zColor = z.object({
	value: z.string(),
});
export const zMerchandising = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	description: z.optional(z.string()),
	price: z.number().nullable(),
	category: z.string(),
	images: z.array(z.string()),
	featuredPosition: z.optional(z.number().min(1)),
	status: z.custom<'draft' | 'active'>(),
	colors: z.array(z.string()).default([]),
	sizes: z.array(z.string()).default([]),
	url: z.optional(z.string()),
});

export async function fetchMerch(props: FetchMerchProps): Promise<AppMerchandising[]> {
	try {
		const sort: Sort = {
			[props.orderBy || 'price']: props.order && props.order === 'asc' ? 1 : -1,
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${MERCHANDISING_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zMerchandising).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchMerchSizes(): Promise<string[]> {
	let sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'];
	try {
		const response = await api.get(`${API_DOMAIN}${MERCHANDISING_URL}/sizes`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(z.string()).parse(response.data);
		data.forEach((size) => {
			if (!sizes.includes(size)) {
				sizes.push(size);
			}
		});
	} catch (error) {
		console.error(error);
	}
	return sizes;
}

export async function createMerch(product: AppMerchandising): Promise<AppMerchandising | null> {
	const response = await api.post(`${API_DOMAIN}${MERCHANDISING_URL}`, { product });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zMerchandising.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function updateMerch(product: AppMerchandising): Promise<AppMerchandising | null> {
	const response = await api.patch(`${API_DOMAIN}${MERCHANDISING_URL}/${product._id}`, product);

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	const data = zMerchandising.safeParse(response.data);

	if (data.success) {
		return data.data;
	}
	return null;
}

export async function deleteMerch(product: AppMerchandising): Promise<boolean | null> {
	const response = await api.delete(`${API_DOMAIN}${MERCHANDISING_URL}/${product._id}`);

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	if (response.data.success) return true;

	return null;
}

export async function updateImage(id: string, image: File): Promise<AppMerchandising | null> {
	try {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API_DOMAIN}${MERCHANDISING_URL}/${id}/image`;
		const response = await api.post<AppMerchandising>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zMerchandising.safeParse(response.data);

		if (data.success) {
			return data.data;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
}

export async function editImage(id: string, image: File, imageId: string): Promise<AppMerchandising | null> {
	try {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API_DOMAIN}${MERCHANDISING_URL}/${id}/image/${imageId}`;
		const response = await api.patch<AppMerchandising>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zMerchandising.safeParse(response.data);

		if (data.success) {
			return data.data;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
}

export async function updateMerchStatus(productId: string, status: 'active' | 'draft'): Promise<boolean> {
	try {
		const url = `${API_DOMAIN}${MERCHANDISING_URL}/update-status/${productId}/${status}`;

		const response = await api.patch(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zMerchandising.safeParse(response.data);

		if (data.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
}

export async function setMerchFeatured(productId: string) {
	try {
		const url = `${API_DOMAIN}${MERCHANDISING_URL}/set-featured/${productId}`;

		const response = await api.patch(url);

		if (!checkStatus(response)) {
			throw new Error('Invalid params');
		}
		const data = zMerchandising.safeParse(response.data);
		if (data.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
}

export async function unsetMerchFeatured(productId: string) {
	try {
		const url = `${API_DOMAIN}${MERCHANDISING_URL}/unset-featured/${productId}`;

		const response = await api.patch(url);

		if (!checkStatus(response)) {
			throw new Error('Invalid params	');
		}
		const data = zMerchandising.safeParse(response.data);

		if (data.success) return true;
	} catch (error) {
		console.log(error);
	}
	return false;
}

export async function updateMerchPosition(productId: string, mode: 'increase' | 'decrease') {
	try {
		const url = `${API_DOMAIN}${MERCHANDISING_URL}/update-position/${mode}/${productId}`;

		const response = await api.patch(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zMerchandising.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return null;
}
