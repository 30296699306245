import {
	Box,
	LinearProgress,
	ListItemText,
	TableCell,
	TableHead,
	TableRow,
	useTheme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import AsyncImage from 'components/AsyncImage';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ResponsiveTable from 'components/ResponsiveTable';
import { rowsPerPageOptions } from 'config/constants';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { enqueueSnackbarError } from 'lib/helpers';
import {
	deleteMerch,
	setMerchFeatured,
	unsetMerchFeatured,
	updateMerchPosition,
	updateMerchStatus,
} from 'lib/models/merch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';

type Props = {
	data: AppMerchandising[];
	refetch: () => void;
	loading: boolean;
	page: number;
	rowsPerPage: number;
	handleChangePage: (_: unknown, newPage: number) => void;
	handleChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onEdit: (merch: AppMerchandising) => void;
};

export default function MerchList({
	data,
	refetch,
	loading,
	page,
	rowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
	onEdit,
}: Props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<AppMerchandising | null>(null);
	const [showPublishOrUnPublish, setShowPublishOrUnPublish] = useState<AppMerchandising | null>(null);
	const [showPublishOrUnPublishType, setShowPublishOrUnPublishType] = useState<'active' | 'draft'>('active');

	const onDelete = (merch: AppMerchandising) => {
		setShowDeleteConfirmation(merch);
	};

	const onPublish = (merch: AppMerchandising) => {
		setShowPublishOrUnPublish(merch);
		setShowPublishOrUnPublishType('active');
	};

	const onUnPublish = (merch: AppMerchandising) => {
		setShowPublishOrUnPublish(merch);
		setShowPublishOrUnPublishType('draft');
	};

	const handlePublishOrUnPublish = async (isConfirmed: boolean) => {
		if (isConfirmed && showPublishOrUnPublish?._id) {
			const success = await updateMerchStatus(showPublishOrUnPublish._id, showPublishOrUnPublishType);
			if (!success) {
				enqueueSnackbarError(t(`merch:${showPublishOrUnPublishType}Error`));
			}
			refetch();
		}
		setShowPublishOrUnPublish(null);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			const deleted = await deleteMerch(showDeleteConfirmation);
			if (!deleted) {
				enqueueSnackbarError(t('merch:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	const setFeatured = async (merch: AppMerchandising) => {
		if (merch._id) {
			const success = await setMerchFeatured(merch._id);
			if (!success) {
				enqueueSnackbarError(t('merch:updateError'));
			}
		}
		refetch();
	};

	const unsetFeatured = async (merch: AppMerchandising) => {
		if (merch._id) {
			const success = await unsetMerchFeatured(merch._id);
			if (!success) {
				enqueueSnackbarError(t('merch:updateError'));
			}
		}
		refetch();
	};

	const handlePosition = async (merch: AppMerchandising, mode: 'increase' | 'decrease') => {
		if (merch && merch._id) {
			await updateMerchPosition(merch._id, mode);
			refetch();
		}
	};

	const firstFeaturedPosition = data[0]?.featuredPosition ?? -1;
	const total = data.length;

	return (
		<Box>
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			{showPublishOrUnPublish && (
				<ConfirmationDialog
					title={t(`merch:${showPublishOrUnPublishType}Title`)}
					description={t(`merch:${showPublishOrUnPublishType}Description`)}
					onClose={handlePublishOrUnPublish}
				/>
			)}

			{!!loading && <LinearProgress />}

			<ResponsiveTable
				elements={isMobile ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
				listSx={{ maxHeight: '70vh' }}
				tableSx={{ maxHeight: '65vh' }}
				list={{
					primaryKey: '_id',
					renderListItemText: (merch) => {
						return (
							<ListItemText
								primary={
									<Box>
										<Typography>{merch.title}</Typography>
										<Typography>{merch.price}</Typography>
										<Typography>{merch.category}</Typography>
									</Box>
								}
								secondary={
									<Actions
										merch={merch}
										handleSetFeatured={setFeatured}
										handleUnsetFeatured={unsetFeatured}
										handlePosition={handlePosition}
										onEdit={onEdit}
										onDelete={onDelete}
										onPublish={onPublish}
										onUnPublish={onUnPublish}
										firstFeaturedPosition={firstFeaturedPosition}
										total={total}
									/>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:image')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:title')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:price')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:category')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (merch) => {
						return (
							<TableRow tabIndex={-1} key={merch._id}>
								<TableCell>
									<Box
										style={{
											width: '80px',
											height: merch.images[0] ? 'unset' : '60px',
											borderRadius: 8,
											backgroundColor: merch.images[0] ? 'unset' : 'lightgray',
										}}
									>
										<AsyncImage id={merch.images[0]} style={{ width: '80px', borderRadius: 8 }} />
									</Box>
								</TableCell>
								<TableCell>{merch.title}</TableCell>
								<TableCell>{merch.price}</TableCell>
								<TableCell>{merch.category}</TableCell>
								<TableCell>
									<Actions
										merch={merch}
										handleSetFeatured={setFeatured}
										handleUnsetFeatured={unsetFeatured}
										handlePosition={handlePosition}
										onEdit={onEdit}
										onDelete={onDelete}
										onPublish={onPublish}
										onUnPublish={onUnPublish}
										firstFeaturedPosition={firstFeaturedPosition}
										total={total}
									/>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: data.length,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}
