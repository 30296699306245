import { Delete, Edit } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { enqueueSnackbarError } from 'lib/helpers';
import { deletePlace } from 'lib/models/places';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	place: Place;
	onEdit: (place: Place) => any;
	refetch: () => any;
};

function PlaceElement({ place, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [showDelete, setShowDelete] = useState(false);

	async function onDelete(confirmed: boolean) {
		setLoading(true);
		if (confirmed) {
			const deleted = await deletePlace(place._id!);
			if (deleted) {
				refetch();
			} else {
				enqueueSnackbarError(t('common:deletePlaceWithEvents'));
			}
		}
		setLoading(false);
		setShowDelete(false);
	}

	return (
		<Card sx={{ display: 'inline-block', p: 2, m: 2 }}>
			<CardContent>
				<Typography variant="h4" sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
					{place.name.es} <Edit onClick={() => onEdit(place)} sx={{ cursor: 'pointer' }} />{' '}
					<Delete onClick={() => setShowDelete(true)} sx={{ cursor: 'pointer' }} />
				</Typography>
				<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
					{place.address}
				</Typography>
				{place.mapsLink && <iframe src={place.mapsLink} title="Google Maps" style={{ borderRadius: '8px' }} />}

				{showDelete && (
					<ConfirmationDialog
						onClose={onDelete}
						loading={loading}
						title={t('common:deleteTitle')}
						description={t('common:deleteText')}
					/>
				)}
			</CardContent>
		</Card>
	);
}

export default PlaceElement;
