import { useCallback } from 'react';

import useStoreDispatch from 'store/useStoreDispatch';

import { login as loginDispatch } from 'store/reducers/session';

function useLogin() {
	const dispatch = useStoreDispatch();

	const login = useCallback(
		(response: Session) => {
			dispatch(loginDispatch(response));
		},
		[dispatch]
	);

	return login;
}

export default useLogin;
