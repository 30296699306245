import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useEvent from 'hooks/useEvent';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import EventEditionForm from 'components/EventEditionForm';
import { enqueueSnackbarError } from 'lib/helpers';

function EventEdition() {
	const { t } = useTranslation();

	const { id } = useParams<{ id: string }>();

	const navigate = useNavigate();

	const newEvent = !!id && id === 'new';

	const eventData = useEvent();

	const title =
		eventData.loading || eventData.error
			? t('common:loading')
			: newEvent
			? t('common:createEvent')
			: t('common:eventTitle', { title: eventData.event?.title.es });

	useEffect(() => {
		if (eventData.error) {
			enqueueSnackbarError(eventData.error);
			navigate('/events');
		}
	}, [eventData.error, navigate]);

	return (
		<ScreenContainer title={title} goBack={true}>
			<PaperContainer>
				<EventEditionForm
					eventLoading={eventData.loading}
					id={id || (newEvent ? 'new' : '')}
					initialize={eventData.event}
					isModal={false}
				/>
			</PaperContainer>
		</ScreenContainer>
	);
}

export default EventEdition;
