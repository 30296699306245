import { useParams } from 'react-router-dom';

function useAccessCode() {
	const params = useParams<{ code?: string }>();

	const fromParams = !!params && !!params.code;
	const code = fromParams ? params.code! : null;

	return { code, fromParams };
}

export default useAccessCode;
