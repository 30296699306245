import { z } from 'zod';

export const languages = ['es', 'en', 'pt'] as const;

export const defaultLanguage = 'es';

export const zTime = z.object({
	hours: z.number(),
	minutes: z.number(),
});

export const zInternationalization = z.object({
	es: z.string(),
	en: z.optional(z.string()),
	pt: z.optional(z.string()),
});
