import { useParams } from 'react-router-dom';

function useVehicleId() {
	const params = useParams<{ id?: string }>();

	const fromParams = !!params && !!params.id;
	const id = fromParams ? params.id! : null;

	return { id, fromParams };
}

export default useVehicleId;
