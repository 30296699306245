import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import AccessTable from 'components/AccessTable/AccessTable';
import useAccess from 'hooks/useAccess';

import moment from 'moment';

import AccessFilter from 'components/AccessFilter';
import { FetchAccessProps } from 'lib/models/access';

function Access() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams({
		page: '0',
		rowsPerPage: '25',
		plate: '',
		isAssigned: '',
		from: '',
		to: '',
	});

	const [params, setParams] = useState({
		page: Number(searchParams.get('page') || 0),
		rowsPerPage: Number(searchParams.get('rowsPerPage') || 25),
		plate: searchParams.get('plate') || '',
		isAssigned: searchParams.get('isAssigned') || 'any',
		stay: searchParams.get('stay') || 'all',
		from: moment(searchParams.get('from') || moment()),
		to: moment(searchParams.get('to') || moment()),
	});

	const handleParams = (value: any, key: string) => {
		setParams({ ...params, [key]: value });
	};

	const searchProps: FetchAccessProps = useMemo(() => {
		const { plate, isAssigned, stay, from, to } = params;
		let searchParams = { plate, isAssigned, stay, from: from.toISOString(), to: to.toISOString() };
		return searchParams;
	}, [params]);

	const { access, refetch, loading } = useAccess(searchProps);

	const handleChangePage = (_: unknown, newPage: number) => {
		setParams({ ...params, page: newPage });
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setParams({ ...params, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
	};

	const onSearch = async () => {
		refetch();
	};

	useEffect(() => {
		const { page, rowsPerPage, plate, isAssigned, from, to, stay } = params;
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			plate: `${plate}`,
			isAssigned: `${isAssigned}`,
			stay: `${stay}`,
			from: `${from.format('YYYY-MM-DD')}`,
			to: `${to.format('YYYY-MM-DD')}`,
		});
	}, [setSearchParams, params]);

	return (
		<ScreenContainer title={t('access:access')}>
			<AccessFilter
				handleParams={handleParams}
				plate={params.plate}
				isAssigned={params.isAssigned}
				stay={params.stay}
				from={params.from}
				to={params.to}
				onSearch={onSearch}
			/>
			<AccessTable
				access={access}
				loading={loading}
				page={params.page}
				rowsPerPage={params.rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</ScreenContainer>
	);
}

export default Access;
