import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ScreenContainer from 'components/containers/ScreenContainer';
import FaqList from 'components/FaqList';
import CreateIcon from 'components/icons/CreateIcon';
import FaqModal from 'components/FaqModal';

import useFaq from 'hooks/useFaq';
import { Box } from '@mui/material';

const defaultFaq: Faq = {
	question: { es: '' },
	response: { es: '' },
};

function Faq() {
	const { t } = useTranslation();

	const { loading: faqLoading, faq: elements, refetch } = useFaq();
	const [showModal, setShowModal] = useState<Faq | null>(null);

	const title = t('common:appConfig');

	function onClose() {
		refetch();
		setShowModal(null);
	}

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultFaq)} disabled={faqLoading} />

			{showModal && <FaqModal defaultData={showModal} onClose={onClose} onRefetch={refetch} />}

			<Box>
				<FaqList onRefetch={refetch} onEdit={(faq) => setShowModal(faq)} elements={elements} faqLoading={faqLoading} />
			</Box>
		</ScreenContainer>
	);
}

export default Faq;
