import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	Button,
	FormGroup,
	LinearProgress,
	TextField,
	Box,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import {
	Save as SaveIcon,
	Close as CloseIcon,
	Edit,
	Add,
	ExpandMore,
	ArrowUpward,
	ArrowDownward,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

import Uploader from 'components/Uploader';
import AsyncImage from 'components/AsyncImage';

import { dateTime, enqueueSnackbarError } from 'lib/helpers';
import { updateEvent, updateImage } from 'lib/models/events';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import Tag from './Tag';
import { nanoid } from '@reduxjs/toolkit';
import PlaceSelector from 'components/PlaceSelector';
import Genre from './Genre';
import ArtistsTable from 'components/ArtistsTable';
import OptionalTimeSelector from 'components/OptionalTimeSelector';
import TimeSelector from 'components/TimeSelector';
import ConfirmationDialog from 'components/ConfirmationDialog';

type Props = {
	id: string;
	isModal: boolean;
	initialize?: PopulatedAppEvent | null;
	eventLoading: boolean;
};

const colors = ['#7FD2FA', '#EF7D35', '#EE7DB5', '#FBDF4C'];
const defaultLineup: Lineup = { title: '', position: 1 };

function EventEditionForm({ id, isModal, initialize, eventLoading }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	function getStatusFromURL(item: string) {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get(item);
	}

	const status = getStatusFromURL('status');

	const newEvent = !!id && id === 'new';

	const successSnackbar = useSuccessSnackbar();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [event, setEvent] = useState<Omit<AppEventCreation, 'date'> & { date?: string }>(getDefaultEvent());
	const [showUploader, setShowUploader] = useState<'square' | 'wide' | 'vertical' | null>(null);
	const [editingLineup, setEditingLineup] = useState<Lineup | null>(null);
	const [sendNotification, setSendNotification] = useState<boolean>(true);
	const [disabled, setDisabled] = useState(false);
	const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

	const canSave = !!event.date && !!event.title.es;

	const loading = isLoading || eventLoading;

	const defaultOpening = event.date ? getDefaultOpening(event.date) : undefined;
	const defaultCloses = event.date ? getDefaultCloses(event.date) : undefined;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSendNotification(event.target.checked);

	useEffect(() => {
		if (!loading && event.status !== 'draft') {
			setDisabled(true);
		}
	}, [loading, event.status]);

	useEffect(() => {
		if (!eventLoading) {
			if (!newEvent && initialize) {
				setEvent(initialize);
			}
			setLoading(false);
		}
	}, [eventLoading, initialize, newEvent]);

	const onUpdate = (key: keyof PopulatedAppEvent) => {
		return (event: any) => {
			const internalizationKeys = ['title', 'description'];

			const value = internalizationKeys.includes(key) ? { es: event.target.value } : event.target.value;

			if (event) {
				setEvent((current) => ({ ...current, [key]: value }));
			}
		};
	};

	async function save() {
		try {
			setLoading(true);
			if (!event.place) {
				enqueueSnackbarError(t('common:selectedPlaceError'));
				return;
			}
			const savedEvent: PopulatedAppEvent = {
				...(event as unknown as PopulatedAppEvent),
				opening: event.opening || defaultOpening,
				lineup: (event.lineup || []).map((el) => {
					delete el.code;
					return el;
				}),
			};
			if (status === 'publish' && event.status !== 'draft' && event.status !== 'canceled') {
				await updateEvent(savedEvent, sendNotification);
			} else {
				await updateEvent(savedEvent);
			}
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		navigate(-1);
	}

	function changeDate(date: Date) {
		setEvent((current) => ({ ...current, date: date.toISOString() }));
	}

	async function uploadImage(file: File): Promise<boolean> {
		if (event._id && showUploader) {
			const updatedEvent = await updateImage(event._id, file, showUploader);

			if (!updatedEvent) return false;

			const imageProperties = {
				square: 'imageSquare',
				vertical: 'imageVertical',
				wide: 'image',
			};

			const imagePropertyToUpdate = imageProperties[showUploader];

			if (imagePropertyToUpdate) {
				setEvent((prevState) => ({
					...prevState,
					[imagePropertyToUpdate]:
						showUploader === 'square'
							? updatedEvent.imageSquare
							: showUploader === 'vertical'
							? updatedEvent.imageVertical
							: updatedEvent.image,
				}));

				return true;
			}
		}

		return false;
	}

	function onUploaderClose(_uploaded: boolean) {
		setShowUploader(null);
	}

	function closeLineup() {
		setEditingLineup(null);
	}

	function saveLineup(element: Lineup) {
		let lineup = event.lineup ?? [];
		if (!element._id && !element.code) {
			lineup.push({ ...element, code: nanoid(), position: lineup.length + 1 });
		} else {
			lineup = lineup.map((el, index) => {
				if (differentLineup(el, element)) {
					return el;
				}
				return {
					...element,
					position:
						// @ts-ignore
						!isNaN(element.position) ? element.position : index + 1,
				};
			});
		}
		setEvent({ ...event, lineup });
		if (!element._id && !element.code) {
			setEditingLineup(defaultLineup);
		}
		if (!!element._id) {
			setEditingLineup(null);
		}
	}

	function deleteLineup(element: Lineup) {
		setEvent({
			...event,
			lineup: (event.lineup || []).filter((el) => differentLineup(el, element)),
		});
	}

	function onInstagramChange(ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		const instagramEmbedded = extractInstagramPostUrl(ev.target.value);
		setEvent({ ...event, instagramEmbedded });
	}

	function decreasePosition(lineUp: Lineup) {
		const index = (event.lineup || []).findIndex((el) => el._id === lineUp._id);
		// Current element
		const currentIndex = index;
		const currentPosition = index + 1;
		// Previous element
		const previousIndex = index - 1;
		const previousPosition = index;
		if (index > 0) {
			setEvent((currentEvent) => {
				let lineup = currentEvent.lineup || [];
				lineup[previousIndex].position = currentPosition;
				lineup[currentIndex].position = previousPosition;
				return { ...currentEvent, lineup };
			});
		}
	}

	function increasePosition(lineUp: Lineup) {
		const index = (event.lineup || []).findIndex((el) => el._id === lineUp._id);
		// Current element
		const currentIndex = index;
		const currentPosition = index + 1;
		// Next element
		const nextIndex = index + 1;
		const nextPosition = index + 2;
		if (index >= 0 && !!event.lineup![nextIndex]) {
			setEvent((currentEvent) => {
				let lineup = currentEvent.lineup || [];
				lineup[nextIndex].position = currentPosition;
				lineup[currentIndex].position = nextPosition;
				return { ...currentEvent, lineup };
			});
		}
	}

	function getUpdateTime(type: 'barClosure' | 'closes' | 'doorClosure' | 'opening') {
		return (hours: number, minutes: number) => {
			setEvent((current) => {
				return {
					...current,
					[type]: moment(current.date).set('hours', hours).set('minutes', minutes),
				};
			});
		};
	}

	const confirmSave = (confirm: boolean) => {
		if (confirm) {
			save();
			setOpenModalConfirm(false);
		} else {
			setOpenModalConfirm(false);
		}
	};

	return (
		<Box>
			{status === 'publish' && event.status !== 'draft' && event.status !== 'canceled' && openModalConfirm && (
				<ConfirmationDialog
					title={t(`common:publishTitle`)}
					description={t(`common:publishDescription`)}
					warningMessage={sendNotification ? t(`common:msjNotificationEspecial`) : ''}
					onClose={confirmSave}
				>
					<span className="mx-1">{t(`common:sendNotification`)} </span>
					<FormControlLabel
						control={<Checkbox defaultChecked checked={sendNotification} onChange={handleChange} />}
						label={''}
					/>
				</ConfirmationDialog>
			)}

			{!!disabled && (
				<Box
					sx={{
						borderRadius: 2,
						borderWidth: 2,
						borderStyle: 'solid',
						padding: 2,
						marginBottom: 2,
					}}
				>
					<Typography variant="body2">
						Advertencia: si desbloqueas el formulario estarás editando un evento que se encuentra publicado.
					</Typography>
					<Typography variant="body2" sx={{ mt: 1 }}>
						{/*<strong>SI SE EDITA EL EVENTO SIN DESPUBLICARLO NO SE ENVIARÁN LAS NOTIFICACIONES AL USUARIO.</strong>*/}
						{status === 'publish' && event.status !== 'draft' && event.status !== 'canceled' && (
							<strong>
								Por favor, ten en cuenta que si decides editar el evento sin despublicarlo y deseas enviar
								notificaciones a los usuarios, este proceso solo ocurrirá bajo ciertas condiciones. Dichas condiciones
								incluyen la modificación del título del evento, la reprogramación del mismo o la actualización del
								lineup. En caso contrario, las notificaciones no serán enviadas a los usuarios. Es importante considerar
								estos criterios antes de realizar cualquier modificación en el evento.
							</strong>
						)}
					</Typography>
					<Button
						onClick={() => setDisabled(false)}
						variant="contained"
						sx={{ marginTop: 2, backgroundColor: 'red', color: 'white' }}
					>
						Desbloquear
					</Button>
				</Box>
			)}
			{showUploader && (
				<Uploader id="event-image" name="event-file" upload={uploadImage} onClose={onUploaderClose} open={true} />
			)}

			{isModal && (
				<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1, justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<CloseIcon />}
						onClick={() => {
							navigate(-1);
						}}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<PlaceSelector
					selected={event.place?._id}
					onSelected={(place) => {
						setEvent((current) => ({ ...current, place: place }));
					}}
					sx={{ mb: 4 }}
					disabled={disabled}
				/>
				{event.place && (
					<Box>
						<DatePicker
							label={t('common:date')}
							onChange={(date: any) => changeDate(date)}
							sx={{ mb: 2 }}
							value={event.date ? moment(event.date) : undefined}
							format="DD/MM/YYYY"
							disabled={disabled}
						/>

						{event.date && (
							<Box>
								<TextField
									id="title"
									label={t('common:title')}
									fullWidth={true}
									value={event.title?.es ?? ''}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={onUpdate('title')}
									disabled={loading || disabled}
								/>
								<TextField
									id="description"
									label={t('common:description')}
									fullWidth={true}
									value={event.description?.es ?? ''}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={onUpdate('description')}
									disabled={loading || disabled}
									multiline
								/>
								<TextField
									id="ticketsLink"
									label={t('common:ticketsLink')}
									fullWidth={true}
									value={event.ticketsLink || ''}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={onUpdate('ticketsLink')}
									disabled={loading || disabled}
								/>
								{!!isLink(event.ticketsLink) && (
									<Box sx={{ mb: 2 }}>
										<a href={event.ticketsLink} target="_blank" rel="noreferrer">
											{t('common:checkLink')}
										</a>
									</Box>
								)}

								{/* <TextField
									id="tiktokEmbedded"
									label={t('common:tiktokEmbedded')}
									fullWidth={true}
									value={event.tiktokEmbedded}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={onUpdate('tiktokEmbedded')}
									disabled={loading}
								/> */}
								<TextField
									id="instagramEmbedded"
									label={t('common:instagramEmbedded')}
									fullWidth={true}
									value={event.instagramEmbedded}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={onInstagramChange}
									disabled={loading || disabled}
								/>
								<Typography variant="body2" sx={{ mb: 2 }}>
									{t('common:instagramLinkLabel')}
								</Typography>

								{isLink(event.instagramEmbedded) && (
									<Box sx={{ mb: 2 }}>
										<a href={event.instagramEmbedded} target="_blank" rel="noreferrer">
											{t('common:checkLink')}
										</a>
									</Box>
								)}

								<Tag
									disabled={disabled}
									onChange={(tag) => setEvent((current) => ({ ...current, tag }))}
									defaultValue={event.tag}
								/>
								<Genre
									disabled={disabled}
									onChange={(genre) => setEvent((current) => ({ ...current, genre }))}
									defaultValue={event.genre}
								/>

								{!!event.date && (
									<Box sx={{ mt: 1 }}>
										<Typography variant="body2">{t('common:opening')}</Typography>
										<TimeSelector
											disabled={loading || disabled}
											onUpdateTime={getUpdateTime('opening')}
											defaultValue={event.opening || defaultOpening}
										/>
									</Box>
								)}

								{!!event.date && (
									<OptionalTimeSelector
										sx={{ mt: 1 }}
										label={t('common:closes')}
										disabled={loading || disabled}
										onUpdateTime={getUpdateTime('closes')}
										defaultValue={event.closes || defaultCloses}
										event={event}
										type={'closes'}
										setEvent={setEvent}
									/>
								)}

								{!!event.date && (
									<OptionalTimeSelector
										sx={{ mt: 1 }}
										label={t('common:barClosure')}
										disabled={loading || disabled}
										onUpdateTime={getUpdateTime('barClosure')}
										defaultValue={event.barClosure}
										event={event}
										type={'barClosure'}
										setEvent={setEvent}
									/>
								)}

								{!!event.date && (
									<OptionalTimeSelector
										sx={{ mt: 1 }}
										label={t('common:doorClosure')}
										disabled={loading || disabled}
										onUpdateTime={getUpdateTime('doorClosure')}
										defaultValue={event.doorClosure}
										event={event}
										type={'doorClosure'}
										setEvent={setEvent}
									/>
								)}

								{/*status === 'publish' && event.status !== 'draft' && event.status !== 'canceled' && (
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												inputProps={{ 'aria-label': t(`common:sendNotification`) }}
												checked={sendNotification}
												onChange={handleChange}
												disabled={loading || disabled}
											/>
										}
										label={t(`common:sendNotification`)}
									/>
									)*/}

								<Accordion sx={{ mt: 2 }}>
									<AccordionSummary expandIcon={<ExpandMore />} aria-controls="lineup" id="lineup">
										<Typography>{t('common:lineup')}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Box
											sx={{
												backgroundColor: '#282547',
												minHeight: '150px',
												borderRadius: 2,
												position: 'relative',
												padding: 2,
												paddingTop: 4,
											}}
										>
											<Add
												sx={{
													position: 'absolute',
													top: 4,
													right: 8,
													color: 'white',
													cursor: disabled ? 'default' : 'pointer',
												}}
												onClick={() => !disabled && setEditingLineup(defaultLineup)}
											/>

											{editingLineup && (
												<Box sx={{ m: 2, p: 2, backgroundColor: 'white' }}>
													<TextField
														fullWidth
														sx={{ mr: 2, mb: 2 }}
														variant="outlined"
														label={t('common:name')}
														value={editingLineup.title}
														onChange={(ev) =>
															setEditingLineup({ ...editingLineup, title: `${ev.currentTarget.value}` })
														}
														disabled={disabled}
													/>

													<OptionalTimeSelector
														defaultValue={editingLineup.time}
														onUpdateTime={(hours, minutes) => {
															const date = dateTime(hours, minutes);
															const parsedDate = hours < 6 ? date.add(1, 'day') : date;
															setEditingLineup({ ...editingLineup, time: parsedDate.toISOString() });
														}}
														disabled={disabled}
													/>

													{/*<ArtistsTable
														selected={editingLineup.artist}
														onSelected={(artist) => {
															if (artist._id === editingLineup.artist) {
																let edited = { ...editingLineup };
																delete edited.artist;
																setEditingLineup(edited);
															} else {
																setEditingLineup({ ...editingLineup, artist: artist?._id });
															}
														}}
													/>*/}
													<ArtistsTable
														selected={editingLineup.artist}
														onSelected={(artist) => {
															if (!artist._id) return;
															const artistIndex = editingLineup?.artist?.indexOf(artist._id);
															if (artistIndex === undefined || artistIndex === -1) {
																setEditingLineup({
																	...editingLineup,
																	artist: [...(editingLineup?.artist || []), artist._id],
																});
															} else {
																const updatedArtists = editingLineup?.artist?.filter(
																	(id, index) => index !== artistIndex
																);
																setEditingLineup({ ...editingLineup, artist: updatedArtists });
															}
														}}
													/>
													<Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: 2 }}>
														<Button onClick={() => saveLineup(editingLineup)} variant="contained" disabled={disabled}>
															{t('common:save')}
														</Button>
														<Button onClick={() => closeLineup()} variant="contained" color="error">
															{t('common:cancel')}
														</Button>
													</Box>
												</Box>
											)}

											{(event.lineup || [])
												.sort((a, b) => (a.position || 0) - (b.position || 0))
												.map((el, index) => {
													const colorIndex = index % colors.length;

													return (
														<Box sx={{ pt: 2, pb: 2 }} key={index}>
															<Box sx={{ display: 'inline-block' }}>
																<Typography sx={{ fontSize: 20, color: colors[colorIndex], display: 'inline' }}>
																	{`${el.title} `}
																</Typography>
																{!!el.time && (
																	<Typography sx={{ fontSize: 16, color: '#FBF7E9', display: 'inline' }}>
																		{moment(el.time).format('HH:mm \\h')}
																	</Typography>
																)}
																<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
																	<Button
																		disabled={el.position === 1}
																		onClick={() => decreasePosition(el)}
																		variant="text"
																		endIcon={<ArrowUpward />}
																	>
																		{t(`common:up`)}
																	</Button>
																	<Button
																		disabled={
																			(event.lineup || []).length === 0 || el.position === (event.lineup || []).length
																		}
																		onClick={() => increasePosition(el)}
																		variant="text"
																		color="secondary"
																		endIcon={<ArrowDownward />}
																	>
																		{t(`common:down`)}
																	</Button>
																</Box>
																<Box
																	sx={{
																		display: 'flex',
																		flexDirection: 'row',
																		justifyContent: 'flex-end',
																		gap: 2,
																	}}
																>
																	<Button onClick={() => setEditingLineup(el)} variant="contained" disabled={disabled}>
																		{t('common:edit')}
																	</Button>
																	<Button
																		onClick={() => deleteLineup(el)}
																		variant="contained"
																		color="error"
																		disabled={disabled}
																	>
																		{t('common:delete')}
																	</Button>
																</Box>
															</Box>
														</Box>
													);
												})}
										</Box>
									</AccordionDetails>
								</Accordion>

								{!!event._id && (
									<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
										{/* Wide image */}
										<Box
											onClick={() => !disabled && setShowUploader('wide')}
											sx={{
												width: 350,
												mt: 2,
												mb: 1,
												borderRadius: 1,
												borderWidth: 1,
												borderStyle: 'solid',
												borderColor: 'gray',
												padding: 1,
												cursor: disabled ? 'default' : 'pointer',
												':hover': {
													backgroundColor: 'lightgray',
												},
												position: 'relative',
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												alignItems: 'centers',
											}}
										>
											{event.image ? (
												<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
											) : (
												<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
											)}

											{event.image ? (
												<AsyncImage
													id={event.image}
													style={{
														width: 328,
														height: 214,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														marginTop: 24,
													}}
												/>
											) : (
												<Box
													sx={{
														width: 328,
														height: 214,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														backgroundColor: 'lightgray',
														marginTop: 24,
													}}
												/>
											)}
										</Box>
										{/* Square image */}
										<Box
											onClick={() => !disabled && setShowUploader('square')}
											sx={{
												mt: 2,
												mb: 1,
												width: 300,
												borderRadius: 1,
												borderWidth: 1,
												borderStyle: 'solid',
												borderColor: 'gray',
												padding: 1,
												cursor: disabled ? 'default' : 'pointer',
												':hover': {
													backgroundColor: 'lightgray',
												},
												position: 'relative',
											}}
										>
											{event.imageSquare ? (
												<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
											) : (
												<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
											)}

											{event.imageSquare ? (
												<AsyncImage
													id={event.imageSquare}
													style={{
														width: 250,
														height: 250,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														backgroundColor: 'lightgray',
														marginTop: 2,
													}}
												/>
											) : (
												<Box
													sx={{
														width: 250,
														height: 250,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														backgroundColor: 'lightgray',
														marginTop: 2,
													}}
												/>
											)}
										</Box>
										{/* Vertical image */}
										<Box
											onClick={() => !disabled && setShowUploader('vertical')}
											sx={{
												mt: 2,
												mb: 1,
												width: 300,
												borderRadius: 1,
												borderWidth: 1,
												borderStyle: 'solid',
												borderColor: 'gray',
												padding: 1,
												cursor: disabled ? 'default' : 'pointer',
												':hover': {
													backgroundColor: 'lightgray',
												},
												position: 'relative',
											}}
										>
											{event.imageSquare ? (
												<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
											) : (
												<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
											)}

											{event.imageVertical ? (
												<AsyncImage
													id={event.imageVertical}
													style={{
														width: 200,
														height: 250,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														backgroundColor: 'lightgray',
														marginTop: 2,
													}}
												/>
											) : (
												<Box
													sx={{
														width: 200,
														height: 250,
														borderRadius: 5,
														borderWidth: 1,
														borderStyle: 'solid',
														borderColor: 'gray',
														backgroundColor: 'lightgray',
														marginTop: 2,
													}}
												/>
											)}
										</Box>
									</Box>
								)}
							</Box>
						)}
					</Box>
				)}

				{status === 'publish' && event.status !== 'draft' && event.status !== 'canceled' ? (
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={() => setOpenModalConfirm(true)}
						disabled={!canSave || disabled}
					>
						{t('common:save')}
					</Button>
				) : (
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave || disabled}
					>
						{t('common:save')}
					</Button>
				)}
			</FormGroup>
		</Box>
	);
}

export default EventEditionForm;

function getDefaultEvent(): Omit<AppEventCreation, 'date'> {
	return {
		_id: '',
		description: { es: '' },
		title: { es: '' },
		status: 'draft',
	};
}

function differentLineup(current: Lineup, search: Lineup) {
	return (!search._id || current._id !== search._id) && (!search.code || current.code !== search.code);
}

function extractInstagramPostUrl(instagramHtml: string): string {
	const permalinkRegex = /data-instgrm-permalink="([^"]+)"/;
	const match = instagramHtml.match(permalinkRegex);

	if (match && match[1]) {
		return match[1];
	}

	return instagramHtml;
}

function isLink(data?: string | null) {
	return !!data && (data.includes('http://') || data.includes('https://'));
}

function getDefaultOpening(date: string | Date | Moment) {
	const dateMoment = moment(date);

	if (dateMoment.day() === 4 || dateMoment.day() === 5 || dateMoment.day() === 6) {
		// Thursday, Friday, or Saturday
		return dateMoment.set({ hour: 23, minute: 0, second: 0, millisecond: 0 }).toISOString();
	} else {
		// Other days
		return dateMoment.set({ hour: 20, minute: 0, second: 0, millisecond: 0 }).toISOString();
	}
}

function getDefaultCloses(date: string | Date | Moment) {
	const dateMoment = moment(date);

	if (dateMoment.day() === 4 || dateMoment.day() === 5 || dateMoment.day() === 6) {
		// Thursday, Friday, or Saturday
		return dateMoment.set({ hour: 6, minute: 0, second: 0, millisecond: 0 }).toISOString();
	} else {
		// Other days
		return dateMoment.set({ hour: 2, minute: 0, second: 0, millisecond: 0 }).toISOString();
	}
}
