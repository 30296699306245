import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';

import GenerateCodeForm from 'components/GenerateAccessForm';
import useVehicles from 'hooks/useVehicles';

function GenerateCode() {
	const { t } = useTranslation();

	const { loading, vehicles } = useVehicles();

	return (
		<ScreenContainer title={t('access:generate_code')}>
			<GenerateCodeForm vehicles={vehicles} loading={loading} />
		</ScreenContainer>
	);
}

export default GenerateCode;
