import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LinearProgress } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useUsers from 'hooks/useUsers';
import UserTable from 'components/UserTable';
import useIsAppUsers from 'hooks/useIsAppUsers';

function Users() {
	const { t } = useTranslation();

	const { loading, users } = useUsers();

	const navigate = useNavigate();

	const isAppUsers = useIsAppUsers();

	return (
		<ScreenContainer title={t(isAppUsers ? 'common:app_user_plural' : 'common:panel_user_plural')}>
			{!isAppUsers && <CreateIcon onCreate={() => navigate('/panel/users/new')} />}

			{!!loading && <LinearProgress />}

			<UserTable users={users} loading={loading} />
		</ScreenContainer>
	);
}

export default Users;
