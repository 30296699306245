import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';

import { MenuItem } from 'types/global';

type UsersProps = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
	open?: boolean;
};

function Users({ selected, onSelect, open }: UsersProps) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/me"
			selected={selected === MenuItem.User}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.User)}
		>
			<ListItemIcon>
				<PersonIcon />
			</ListItemIcon>
			{open && <ListItemText primary={t('common:user_me')} />}
		</ListItem>
	);
}

export default Users;
