import { Box } from '@mui/material';
import PlaceElement from './PlaceElement';

type Props = {
	places: Place[];
	loading: boolean;
	refetch: () => any;
	setLoading: (loading: boolean) => any;
	onEdit: (place: Place) => any;
};

function Places({ places, onEdit, refetch }: Props) {
	return (
		<Box sx={{ display: 'inline-block' }}>
			{places.map((place, index) => (
				<PlaceElement key={`${place.code}-${index}`} place={place} onEdit={onEdit} refetch={refetch} />
			))}
		</Box>
	);
}

export default Places;
