import { API_DOMAIN, ARTIST_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';

import { z } from 'zod';

export const zArtist = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	image: z.optional(z.string()),
	likes: z.optional(z.number()),
});

const zArtistArrayResponse = z.object({
	total: z.number(),
	// We only need to validate the first element, if its ok, we assume that the rest are ok
	elements: z.custom<Artist[]>((data) => {
		if (Array.isArray(data) && data.length > 0) {
			const parsed = zArtist.safeParse(data[0]);
			if (!parsed.success) {
				console.error(`${parsed.error}`);
			}
			return parsed.success;
		}
		if (!Array.isArray(data)) {
			console.error(`response should be a valid array`);
			return false;
		}
		return true;
	}),
});

export type FetchArtistSearchProps = {
	search?: string | null;
	place?: string;
};

export type FetchArtistProps = FetchArtistSearchProps & PaginationProps;

export async function fetchArtists(props: FetchArtistProps): Promise<PaginateSource<Artist>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'title']: props.order && props.order === 'asc' ? 1 : -1,
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${ARTIST_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zArtistArrayResponse.parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchArtist(id: string): Promise<Artist | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${ARTIST_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zArtist.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateArtist(artist: Artist): Promise<Artist | null> {
	try {
		const url = artist._id ? `${API_DOMAIN}${ARTIST_URL}/${artist._id}` : `${API_DOMAIN}${ARTIST_URL}`;

		if (!artist._id) {
			delete artist._id;
		}

		const response = await (artist._id ? api.patch(url, artist) : api.post(url, artist));

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zArtist.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return null;
}

export async function updateImage(id: string, image: File): Promise<Artist | null> {
	try {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API_DOMAIN}${ARTIST_URL}/${id}/image`;
		const response = await api.post<Artist>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zArtist.safeParse(response.data);

		if (data.success) {
			return data.data;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
}

export async function deleteArtist(id: string): Promise<boolean> {
	try {
		const response = await api.delete(`${API_DOMAIN}${ARTIST_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data.success;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export function isFetchArtistProps(data: any): data is FetchArtistProps {
	return !!data && !isNaN(data.page) && !isNaN(data.rowsPerPage) && (!data.search || typeof data.search === 'string');
}
