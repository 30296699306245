import { Button } from '@mui/material';
import { AssignPlate, SuccessfulEntry, ManuallyEntry, OutOfDateEntry, OfflineScanning } from 'components/Modals';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { saveEntry, syncScans, verifyCodeDateRange } from 'lib/models/access';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useServerDown from 'hooks/useServerDown';
import QRReader from 'components/QRReader/QRReader';
import useStoreSelector from 'store/useStoreSelector';
import LoopIcon from '@mui/icons-material/Loop';

function EntryScanner() {
	const ModalKeys: AccessModals = {
		AssignPlate: 'AssignPlate',
		SuccessfulEntry: 'SuccessfulEntry',
		SuccessfulExit: 'SuccessfulExit',
		ManuallyEntry: 'ManuallyEntry',
		ManuallyExit: 'ManuallyExit',
		UnassignedPlateOnExit: 'UnassignedPlateOnExit',
		OutOfDateEntry: 'OutOfDateEntry',
		OutOfDateExit: 'OutOfDateExit',
		ScanAndAssignPlate: 'ScanAndAssignPlate',
		OfflineScanning: 'OfflineScanning',
	};
	const { t } = useTranslation();
	const isDown = useServerDown();
	const scanQueue = JSON.parse(localStorage.getItem('scanQueue') || '[]');

	const dailyCodes = useStoreSelector((state) => state.dailyCodes);

	const found = useRef('');

	const [access, setAccess] = useState<Access | undefined>();
	const [openModal, setOpenModal] = useState<string | null>(null);
	const [flash, setFlash] = useState<string | null>(null);
	const [code, setCode] = useState<string>('');
	const [offlineResults, setOfflineResults] = useState([]);

	const closeAll = () => {
		setOpenModal(null);
		setCode('');
		found.current = '';
		setAccess(undefined);
	};

	const handleFlash = (color: string) => {
		setFlash(color);

		setTimeout(() => {
			setFlash(null);
		}, 500);
	};

	const verifyEntry = async (code: string, serverIsDown: boolean) => {
		if (!!code && !found.current) {
			found.current = code;

			if (serverIsDown) {
				setCode(code);

				const exists = dailyCodes.list.find((access) => access.code === code);

				if (!exists) {
					handleFlash('red');
					found.current = '';
					enqueueSnackbarError(t('access:invalidCode'));
					return;
				}

				handleFlash('green');
				return setOpenModal(ModalKeys.OfflineScanning);
			}

			const response = await verifyCodeDateRange(code);

			if (typeof response !== 'string' && !!response.access && typeof response.outOfDate === 'boolean') {
				const { access, outOfDate } = response;
				if (access) setAccess(access);

				if (outOfDate) {
					setOpenModal(ModalKeys.OutOfDateEntry);
					enqueueSnackbarError(t('access:outOfDate'));
					handleFlash('red');
					return;
				}

				let flashColor = 'green';
				const maxVehicles = access.stay === 'tent' ? 2 : 1;

				if (access.vehiclesInside === maxVehicles) flashColor = 'blue';
				if (access.vehiclesInside > maxVehicles) flashColor = 'red';

				if (access.plate.length > 0) {
					handleFlash(flashColor);
					setOpenModal(ModalKeys.SuccessfulEntry);
					return;
				}

				if (access.plate.length === 0) {
					handleFlash(flashColor);
					setOpenModal(ModalKeys.AssignPlate);
					return;
				}
			} else {
				enqueueSnackbarError(t(`${response}`));
				handleFlash('red');
				setTimeout(() => {
					closeAll();
				}, 1000);
			}
			return;
		}
	};

	const onSaveEntry = async ({ code, plate }: { code?: string; plate?: string }) => {
		try {
			if (!code) {
				throw new Error('Missing access code');
			}
			await saveEntry({ code, plate });
			if (!access) {
				handleFlash('green');
			}
			enqueueSnackbarSuccess(t('access:entrySuccess'));
		} catch (error) {
			handleFlash('red');
			enqueueSnackbarError(error);
		}
		closeAll();
	};

	const modalProps = {
		open: openModal,
		handleClose: closeAll,
		access,
	};

	const modals: Record<string, React.ReactNode> = {
		OfflineScanning: (
			<OfflineScanning
				{...modalProps}
				code={code}
				route={'entry'}
				result={offlineResults}
				setResult={setOfflineResults}
			/>
		),
		AssignPlate: <AssignPlate {...modalProps} onSave={onSaveEntry} route={'entry'} />,
		ManuallyEntry: <ManuallyEntry {...modalProps} onVerify={verifyEntry} />,
		SuccessfulEntry: <SuccessfulEntry {...modalProps} onSave={onSaveEntry} />,
		OutOfDateEntry: <OutOfDateEntry {...modalProps} />,
	};

	const syncStoredScan = async () => {
		const response = await syncScans();

		if (!response) return enqueueSnackbarSuccess(t('access:errorOnSync'));

		if (response.length === 0) return enqueueSnackbarSuccess(t('access:noEnqueuedScans'));

		setOfflineResults(response);
		setOpenModal(ModalKeys.OfflineScanning);
	};

	return (
		<>
			{!isDown && scanQueue && scanQueue.length > 0 && (
				<Button
					variant="contained"
					onClick={syncStoredScan}
					color="primary"
					sx={{ position: 'absolute', right: '.5rem', top: '.5rem', zIndex: 50 }}
				>
					<LoopIcon />
					{t('access:sync')}
				</Button>
			)}

			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 20,
					backgroundColor: flash || 'none',
					opacity: flash === null ? 0 : 0.8,
					pointerEvents: 'none',
					transition: 'opacity 0.4s ease-in-out',
					overflow: 'hidden',
				}}
			></div>
			<QRReader
				onScan={verifyEntry}
				setOpenModal={setOpenModal}
				modalName={ModalKeys.ManuallyEntry}
				routeName="scan_entry"
			/>
			{/* MODALS */}
			{openModal && modals[openModal]}
		</>
	);
}

export default EntryScanner;
