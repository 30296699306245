import { fetchMerchSizes } from 'lib/models/merch';
import { useCallback, useEffect, useState } from 'react';

function useMerchSizes() {
	const [loading, setLoading] = useState<boolean>(false);

	const [sizes, setSizes] = useState<string[]>([]);

	const addSize = useCallback((size: string) => {
		setSizes((current) => {
			if (!current.includes(size)) {
				return [...current, size];
			} else {
				return current;
			}
		});
	}, []);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchMerchSizes();
		setSizes(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, sizes, addSize, refetch };
}

export default useMerchSizes;
