import { API_DOMAIN, MERCHANDISING_CATS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { z } from 'zod';
import { zMerchandising } from './merch';

export const zMerchCategory = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	items: z.array(zMerchandising),
});

export async function fetchMerchCategories() {
	try {
		const response = await api.get(`${API_DOMAIN}${MERCHANDISING_CATS_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zMerchCategory).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createMerchCategory(category: AppMerchCategory) {
	const response = await api.post(`${API_DOMAIN}${MERCHANDISING_CATS_URL}`, category);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zMerchCategory.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function updateMerchCategory(category: AppMerchCategory) {
	const response = await api.patch(`${API_DOMAIN}${MERCHANDISING_CATS_URL}/${category._id}`, category);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zMerchCategory.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function deleteMerchCategory(category: AppMerchCategory): Promise<boolean | null> {
	try {
		const response = await api.delete(`${API_DOMAIN}${MERCHANDISING_CATS_URL}/${category._id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (response.data.success) {
			return true;
		}
		throw new Error();
	} catch (error) {
		return null;
	}
}
