import { useCallback, useEffect, useState } from 'react';

import { fetchFaq } from 'lib/models/faq';

function useFaq() {
	const [loading, setLoading] = useState<boolean>(true);

	const [faq, setFaq] = useState<Faq[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchFaq();
		setFaq(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, faq, refetch };
}

export default useFaq;
