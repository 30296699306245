// A custom hook that builds on useLocation to parse

import React from 'react';
import { useLocation } from 'react-router-dom';

function useIsAppUsers() {
	const { pathname } = useLocation();

	return React.useMemo(() => !pathname.includes('panel'), [pathname]);
}

export default useIsAppUsers;
