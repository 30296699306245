import { fetchImage } from 'lib/models/file';
import { useEffect, useState } from 'react';

function useImageURL(id: string = '', defaultURL: string = '') {
	const [imageURL, setImageURL] = useState(defaultURL);

	useEffect(() => {
		async function fetch() {
			const image = await fetchImage(id);
			if (image) {
				setImageURL(`data:image/png;base64,${image}`);
			}
		}

		if (id) {
			fetch();
		}
	}, [id]);

	return imageURL;
}

export default useImageURL;
