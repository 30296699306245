import { FetchMerchProps, fetchMerch } from 'lib/models/merch';
import { useCallback, useEffect, useState } from 'react';

function useMerch(props: FetchMerchProps) {
	const [loading, setLoading] = useState<boolean>(false);

	const [merch, setMerch] = useState<AppMerchandising[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchMerch(props);
		setMerch(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, merch, refetch };
}

export default useMerch;
