import { Button, Typography } from '@mui/material';
import useServerDown from 'hooks/useServerDown';
import { useTranslation } from 'react-i18next';
import { useZxing } from 'react-zxing';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

type Props = {
	setOpenModal: (modal: string) => void;
	modalName: string;
	onScan: (code: string, serverIsDown: boolean) => void;
	routeName: string;
};
export const QRReader = ({ setOpenModal, modalName, onScan, routeName }: Props) => {
	const isDown = useServerDown();
	const { t } = useTranslation();

	const { ref } = useZxing({
		onDecodeResult(result) {
			onScan(result.getText(), isDown);
		},
		constraints: {
			video: { aspectRatio: window.innerWidth / window.innerHeight, facingMode: 'environment' },
			audio: false,
		},
		timeBetweenDecodingAttempts: 1500,
	});

	return (
		<div style={{ padding: 0, width: '100vw', height: '100vh', overflow: 'hidden' }}>
			<Button
				component={Link}
				to="/"
				style={{ position: 'absolute', zIndex: 1400, color: 'white', left: '.5rem', top: '.5rem' }}
			>
				<ArrowBackIcon />
				<Typography sx={{ marginLeft: '0.5rem' }}>{t(`access:${routeName}`)}</Typography>
			</Button>
			<div
				style={{
					zIndex: 10,
					position: 'absolute',
					width: '17em',
					height: '17em',
					boxSizing: 'border-box',
					pointerEvents: 'none',
					top: '40%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					boxShadow: '0px 0px 0px 2000px rgba(0,0,0,0.75)',
				}}
			></div>
			<video ref={ref} style={{ padding: 0, width: '100vw', height: '100vh', overflow: 'hidden' }} />;
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '3rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => setOpenModal(modalName)}
			>
				{t('access:manually_enter')}
			</Button>
		</div>
	);
};

export default QRReader;
