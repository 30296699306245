import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, LinearProgress, Box, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { enqueueSnackbarError } from 'lib/helpers';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { removeFaq } from 'lib/models/faq';
import PaperContainer from 'components/containers/PaperContainer';

type Props = {
	onEdit: (faq: Faq) => void;
	onRefetch: () => void;
	elements: Faq[];
	faqLoading: boolean;
};

function FaqList({ onRefetch, onEdit, elements, faqLoading }: Props) {
	const { t } = useTranslation();

	const successSnackbar = useSuccessSnackbar();

	const [isLoading, setLoading] = useState<boolean>(false);

	const loading = isLoading || faqLoading;

	async function deleteFaq(faq: Faq) {
		try {
			setLoading(true);
			await removeFaq(faq);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}

		onRefetch();
		setLoading(false);
	}

	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<Box>
				{elements.length === 0 && <Typography>{t('common:withoutElements')}</Typography>}
				{elements.map((el) => {
					return (
						<PaperContainer
							key={el._id}
							sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}
						>
							<Box>
								<Typography sx={{ mb: 2 }}>
									<strong>{el.question.es}</strong>
								</Typography>
								<Box sx={{ backgroundColor: 'black', color: 'white', p: 2 }}>
									<Box dangerouslySetInnerHTML={{ __html: el.response.es.replace(/\n/g, '<br>') }} />
								</Box>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'flex-end',
									gap: 1,
									paddingLeft: 2,
								}}
							>
								<Button
									variant="contained"
									fullWidth={true}
									color="primary"
									size="large"
									startIcon={<Edit />}
									onClick={() => onEdit(el)}
								>
									{t('common:edit')}
								</Button>
								<Button
									variant="contained"
									fullWidth={true}
									color="secondary"
									size="large"
									startIcon={<Delete />}
									onClick={() => deleteFaq(el)}
								>
									{t('common:delete')}
								</Button>
							</Box>
						</PaperContainer>
					);
				})}
			</Box>
		</Box>
	);
}

export default FaqList;
