import { useCallback, useEffect, useState } from 'react';

import { fetchPlaces } from 'lib/models/places';

function usePlaces() {
	const [loading, setLoading] = useState<boolean>(true);
	const [places, setPlaces] = useState<Place[]>([]);

	const refetch = useCallback(async () => {
		const response = await fetchPlaces();
		setPlaces(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, places, refetch, setLoading };
}

export default usePlaces;
