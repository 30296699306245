import QRCode from 'react-qr-code';
import style from './AccessTickets.module.css';
import { useTranslation } from 'react-i18next';
import { changeStatus } from 'lib/models/access';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { Button, LinearProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import logoMute from '../../assets/images/mute.png';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { getSponsor } from 'lib/models/sponsor';
import useUser from 'hooks/useUser';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import DownloadPDFIcon from 'components/icons/DownloadPDFIcon';

type Props = {
	accessCodes: Access[];
	date?: { start?: string; end?: string };
	loading?: boolean;
	setLoading?: Dispatch<SetStateAction<boolean>>;
	marginTop?: string;
	refetch?: () => void;
};

function AccessTickets({ accessCodes, date, loading, setLoading, refetch }: Props) {
	const { t } = useTranslation();
	const location = useLocation();

	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const [image, setImage] = useState('');

	const [visibleToggleEnable, setVisibleToggleEnable] = useState(true);

	const route = location.pathname;

	const seeToggleButton = isAdmin && route !== '/generate';

	useEffect(() => {
		if (!image) {
			const fetchSponsor = async () => {
				try {
					const response = await getSponsor();
					if (response) {
						setImage(response);
						return setImage(response);
					} else {
						throw new Error('Sponsor fetch failed');
					}
				} catch (error) {
					console.error(error);
					return null;
				}
			};

			fetchSponsor();
		}
	}, [image]);

	const onChangeStatus = async (id?: string) => {
		try {
			if (!id) throw new Error('Missing ID');
			const response = await changeStatus(id);

			if (!response) {
				throw new Error();
			}

			if (refetch) {
				refetch();
			}

			enqueueSnackbarSuccess(t('access:updatedAccess'));
		} catch (error) {
			enqueueSnackbarError(t('access:errorOnUpdate'));
		}
	};
	const contentRef = useRef<HTMLDivElement>(null);

	const generatePDF = async () => {
		try {
			if (contentRef.current && setLoading) {
				setLoading(true);
				setVisibleToggleEnable(false);
				let pdfNumber = 1;
				const equalDates = date?.end === date?.start;

				const chunkSize = 100;

				const originalElement = contentRef.current;
				const originalHTML = originalElement.outerHTML;

				const clonedHTML = document.createElement('div');
				clonedHTML.innerHTML = originalHTML;

				const childDivs = Array.from(clonedHTML.querySelectorAll(`.${style.ticket}`));

				for (let i = 0; i < childDivs.length; i += chunkSize) {
					const positions = [3, 4, 5];
					let current = 0;

					const chunkContainer = document.createElement('div');
					chunkContainer.classList.add(style.ticketContainer);

					const chunk = childDivs.slice(i, i + chunkSize);

					chunk.forEach((div, i) => {
						if (div instanceof HTMLElement && positions.includes(current) && i < chunk.length - (chunk.length % 6)) {
							div.style.marginBottom = '130px';
						}
						chunkContainer.appendChild(div);
						if (current === 5) current = 0;
						else current++;
					});

					clonedHTML.appendChild(chunkContainer);

					await html2pdf()
						.from(chunkContainer)
						.set({
							margin: 0,
							filename: `Accesos_${equalDates ? date?.start : date?.start + '_' + date?.end}_${pdfNumber}.pdf`,
							html2canvas: { scale: 1.5 },
							jsPDF: {
								unit: 'mm',
								format: [220, 340],
								orientation: 'landscape',
							},
						})
						.save();
					pdfNumber++;
				}
			}
		} catch (error) {
			console.error('Error al generar el PDF:', error);
			enqueueSnackbarError(t('access:errorOnPDF'));
		}
		if (setLoading) setLoading(false);
		setVisibleToggleEnable(true);
	};

	return (
		<>
			{loading && <LinearProgress />}
			<DownloadPDFIcon onClick={generatePDF} />

			<div id="accessToPrint" ref={contentRef} className={style.ticketContainer}>
				{accessCodes.map((a) => {
					return (
						<div className={style.ticket} key={a.code}>
							<div className={style.side} style={a.stay === 'tent' ? { paddingBottom: '30px' } : {}}>
								<span className={style.sideText}>{a.code}</span>
								<span className={style.sideText}>
									{a.stay === 'tent'
										? `${parseDate(a.validDate.from)} - ${parseDate(a.validDate.to)}`
										: `${parseDate(a.validDate.from)}`}
								</span>
							</div>

							<div className={style.mainContent}>
								<span className={style.notValid}>Ticket no válido como factura</span>
								<div className={style.header}>
									{visibleToggleEnable && seeToggleButton && (
										<Button
											variant="contained"
											sx={{
												position: 'absolute',
												right: '0',
												left: '0',
												width: '7rem',
												height: '1rem',
												marginLeft: 'auto',
												marginRight: 'auto',
												fontSize: '.8rem',
											}}
											id="changeAccessStatus"
											onClick={() => onChangeStatus(a._id)}
										>
											{a.isActive ? t('access:disable') : t('access:enable')}
										</Button>
									)}

									<div className={style.logoContainer}>
										<img src={logoMute} style={{ borderRight: '1px solid #000000' }} alt="Logo mute" />
										<div className={style.sponsorContainer}>
											{image && (
												<img
													src={image}
													style={{ width: '100%', height: 'auto', verticalAlign: 'center' }}
													alt="sponsor"
												/>
											)}
										</div>
									</div>
									<div className={style.dateContainer}>
										<span>{`${t(`access:${a.stay}`)} - ${a.type}`}</span>
										<span>
											{a.stay === 'tent'
												? `${parseDate(a.validDate.from)} - ${parseDate(a.validDate.to)}`
												: `${parseDate(a.validDate.from)}`}
										</span>
									</div>
								</div>
								<div className={style.qrContainer}>
									<div
										style={{
											border: '3px solid black',
											padding: '3px',
											display: 'flex',
											justifyContent: 'center',
											alignContent: 'center',
										}}
									>
										<QRCode size={155} value={a.code} />
									</div>
									<span>{a.code}</span>
								</div>
								<div className={style.footer}>
									<span>El establecimiento no se responsabiliza por roturas, faltantes,robos o hurtos.</span>
									<span>La seguridad de los vehículos es responsabilidad de sus titulares.</span>
									<span>La casa se reserva el derecho de admisión y permanencia</span>
									<span>El estacionamiento no incluye el uso de baños</span>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default AccessTickets;

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}
	return '-';
}
