import { fetchVehicles } from 'lib/models/vehicles';
import { useCallback, useEffect, useState } from 'react';

function useVehicles() {
	const [loading, setLoading] = useState<boolean>(true);
	const [vehicles, setVehicles] = useState<Vehicle[]>([]);

	const refetch = useCallback(async () => {
		const response = await fetchVehicles();
		setVehicles(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, vehicles, refetch, setLoading };
}

export default useVehicles;
