import { useTranslation } from 'react-i18next';
import { MenuItem, Select, SxProps, Theme } from '@mui/material';

import { NotificationTopic, allTopics } from 'lib/models/notification';

type Props = {
	selected: NotificationTopic | 'all';
	onSelected: (notification: NotificationTopic | null) => any;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	fullWidth?: boolean;
};

function NotificationTopicSelector({ selected = 'all', sx, disabled, onSelected, fullWidth = true }: Props) {
	const { t } = useTranslation();

	function onChange(notification: NotificationTopic | '') {
		onSelected(notification || null);
	}

	return (
		<Select
			labelId="place-selector-label"
			id="place-selector"
			label={t('common:topic')}
			fullWidth={fullWidth}
			value={selected}
			variant="outlined"
			sx={sx}
			onChange={(ev) => onChange(ev.target.value as NotificationTopic | '')}
			disabled={disabled}
		>
			<MenuItem value="all">{t('common:allValues')}</MenuItem>
			{allTopics.map((topic) => {
				return (
					<MenuItem key={`topic${topic}`} value={topic}>
						{t(`common:${topic}`)}
					</MenuItem>
				);
			})}
		</Select>
	);
}

export default NotificationTopicSelector;
